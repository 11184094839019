import React, { useState, useEffect, useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

import { request } from 'app/common/utils/request';

import { addMore } from 'app/assets/Common/commonImages';
import { ReactTags } from 'react-tag-autocomplete';
import EditableTextarea from 'app/common/components/EditableTextarea';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services';
import Dropdown from 'app/common/components/DropdownNew';
import { deleteIcon } from 'app/assets/Common/commonImages';
import Button from 'app/common/components/Button';
import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import { threeDots } from 'app/assets/Common/commonImages';
import { handleDotsClick } from 'app/common/utils/helperFn';
import '../../RecommendationTable/style.css';

function ActionItemTable(props) {
  const {
    actionItemData,
    tableDisabled,
    actionDisabled,
    setActionDisabled,
    editChildState,
  } = props;

  const dispatch = useDispatch();
  const { actions } = useReviewSlice();
  const { trackId } = useParams();

  const statusOptions = [
    { value: 'open', label: 'Open' },
    { value: 'closed', label: 'Closed' },
  ];
  const [suggestions, setSuggestions] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const categoryData =
      actionItemData?.actionitems?.map(actionItem => ({
        ...actionItem,
        // ownership: actionItem.ownership
        //   ? actionItem.ownership.map(owner => ({
        //       value: owner._id,
        //       label: owner.username,
        //     }))
        //   : [],
        ownership: getOwnershipData(
          actionItem.ownership,
          actionItem.ownershipData,
        ),
      })) || [];

    if (categoryData.length === 0) {
      const newRow = {
        actionItem: '',
        ownership: [],
        stepsTaken: '',
        status: statusOptions[0]?.value,
      };
      setTableData([newRow]);
      setActionDisabled(false);
    } else {
      setTableData(categoryData);
      setActionDisabled(true);
    }
  }, [actionItemData]);

  const getOwnershipData = (ownership, userData) => {
    const ownerData = [];
    ownership.map(data => {
      const user = userData.filter(user => user._id === data);
      if (user.length) {
        ownerData.push({ value: user[0]._id, label: user[0].username });
      } else {
        ownerData.push({ value: data, label: data });
      }
      // return ownerData;
    });
    return ownerData;
  };

  const addMoreRow = () => {
    const newRow = {
      actionItem: '',
      ownership: [],
      stepsTaken: '',
      status: statusOptions[0]?.value,
    };
    setTableData([...tableData, newRow]);
    setActionDisabled(false);
  };

  const searchUsers = searchStr => {
    const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user/search?queryString=${searchStr}`;
    request(requestURL)
      .then(userData => {
        const userDataNew = userData.map(data => ({
          value: data.id,
          label: data.username,
        }));
        setSuggestions(userDataNew); // Update suggestions globally
      })
      .catch(error => {
        console.error(error);
      });
  };
  const debouncedSearch = debounce(searchUsers, 500); // 500 milliseconds debounce delay

  const onInput = searchStr => {
    if (searchStr.trim().length < 4) return;
    debouncedSearch(searchStr);
  };

  const onAdd = useCallback((newTag, rowIndex) => {
    setTableData(prevTableData => {
      const newTableData = [...prevTableData];
      newTableData[rowIndex].ownership = [
        ...newTableData[rowIndex].ownership,
        newTag,
      ];
      return newTableData;
    });
  }, []);

  const onDelete = useCallback((tagIndex, rowIndex) => {
    setTableData(prevTableData => {
      const newTableData = [...prevTableData];
      newTableData[rowIndex].ownership = newTableData[
        rowIndex
      ].ownership.filter((_, i) => i !== tagIndex);
      return newTableData;
    });
  }, []);

  const handleSave = recData => {
    let hasError = false;

    for (const row of tableData) {
      if (
        !row.actionItem.trim() ||
        // row.ownership.length === 0 ||
        // !row.stepsTaken.trim() ||
        !row.status
      ) {
        hasError = true;
        break;
      }
    }

    if (hasError) {
      toast.error('Please fill out all the fields.');
      return;
    }

    const trackRecId = recData._id;

    const data = tableData.map(row => ({
      ...row,
      ownership: row.ownership.map(tag => tag.value),
    }));
    dispatch(
      actions.saveActionItem({
        trackRecId,
        data,
        trackId,
      }),
    );
    setActionDisabled(false);
  };

  const handleDeleteRow = (rowIndex, row, data) => {
    if (row._id) {
      const trackRecId = data._id;
      const actionItemId = row._id;
      dispatch(
        actions.deleteActionRow({
          trackRecId,
          actionItemId,
        }),
      );
    } else {
      setTableData(prevTableData =>
        prevTableData.filter((_, index) => index !== rowIndex),
      );
    }
  };

  const deleteActionItem = actionData => {
    const trackRecId = actionData._id;

    dispatch(actions.deleteActionItem({ trackRecId, actionData }));
  };

  const handleEditableChange = (newValue, rowIdx, field) => {
    setTableData(prevTableData =>
      prevTableData.map((row, index) =>
        index === rowIdx ? { ...row, [field]: newValue } : row,
      ),
    );
  };

  const columns = [
    {
      dataField: 'actionItem',
      text: 'Action Item',
      formatExtraData: actionDisabled,
      formatter: (cell, row, rowIndex) => (
        <EditableTextarea
          type="text"
          name="actionItem"
          label="Action Item"
          placeholder="Enter action item"
          value={cell}
          maxLength={150}
          onInputChange={e =>
            handleEditableChange(
              e.currentTarget.innerHTML,
              rowIndex,
              'actionItem',
            )
          }
          actionDisabled={actionDisabled}
          disabled={actionDisabled}
          calledFrom="actionTable"
        />
      ),
    },
    {
      dataField: 'ownership',
      text: 'Ownership',
      formatExtraData: { suggestions, actionDisabled },
      formatter: (cell, row, rowIndex) => (
        <ReactTags
          selected={row.ownership}
          suggestions={suggestions}
          onAdd={tag => onAdd(tag, rowIndex)}
          onDelete={index => onDelete(index, rowIndex)}
          allowNew
          onInput={onInput}
          noOptionsText="No matching emails"
          minQueryLength={4}
          placeholderText=""
          isDisabled={actionDisabled}
        />
      ),
    },
    {
      dataField: 'stepsTaken',
      text: 'Steps Taken',
      formatExtraData: actionDisabled,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <EditableTextarea
              type="text"
              placeholder="Enter steps taken"
              value={cell}
              maxLength={150}
              onInputChange={e =>
                handleEditableChange(
                  e.currentTarget.innerHTML,
                  rowIndex,
                  'stepsTaken',
                )
              }
              actionDisabled={actionDisabled}
              disabled={actionDisabled}
              calledFrom="actionTable"
            />
          </>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatExtraData: actionDisabled,
      formatter: (cell, row, rowIndex) => (
        <div className="flex items-center justify-center pt-[7px] ">
          {!actionDisabled ? (
            <Dropdown
              options={statusOptions}
              selectedOption={cell || statusOptions[0]?.value}
              onSelect={newValue =>
                handleEditableChange(newValue, rowIndex, 'status')
              }
              className="relative option-dropdown align-middle drp-2 ml-4 h-full"
              optionsClassName="custom-options1 border-none p-0"
              disabled={actionDisabled}
            />
          ) : (
            <Dropdown
              isDisabled={true}
              onSelect={() => {}}
              options={statusOptions}
              selectedOption={cell || statusOptions[0]?.value}
              className={`drp-selected relative disabled-dropdown flex w-[60%] justify-center p-[8px] rounded-xl align-middle drp-2 ml-4 h-full ${
                cell === 'closed' ? 'bg-green-500' : 'bg-[#EFA91D]'
              }`}
            />
          )}
          {tableData.length > 1 && (
            <button
              onClick={() => handleDeleteRow(rowIndex, row, actionItemData)}
              className={actionDisabled ? 'hidden ' : 'cursor-pointer'}
            >
              <img src={deleteIcon} alt="del-btn" />
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10 mt-[40px]">
      <div className="action-items flex justify-between relative mb-[34px]">
        <p className="text-[#151D48] text-4xl font-bold w-[95%] text-center">
          Next Steps / Action Items
        </p>

        <img
          src={threeDots}
          alt="view"
          className={
            actionDisabled
              ? 'hidden cursor-not-allowed'
              : 'cursor-pointer h-[18px] mt-2.5'
          }
          disabled={actionDisabled}
          onClick={event => handleDotsClick(event, actionItemData._id)}
        />

        <div
          id={actionItemData._id}
          className="options-container  three-dots bg-white text-black hidden inline-grid absolute right-[-19px] top-[3.5rem] translate-x-7 -translate-y-5 whitespace-nowrap rounded bg-white text-black"
        >
          <div
            className="border-b border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
            onClick={() => {
              deleteActionItem(actionItemData);
            }}
          >
            Delete
          </div>
        </div>
      </div>
      <div className="rounded-2xl custom-card relative p-10 pb-12 pt-6 shadow-md flex-1 block bg-[#f6f8ff]">
        <BootstrapTable
          keyField="id"
          data={tableData}
          columns={columns}
          bordered={false}
        />
        <Permission accessTo={USER_ROLES.ADMIN}>
          <div className="flex justify-center">
            <button
              className={
                actionDisabled
                  ? 'hidden cursor-not-allowed'
                  : 'add-recommendation flex'
              }
              onClick={addMoreRow}
              disabled={actionDisabled}
            >
              <img src={addMore} alt="addmore-btn" />
            </button>
          </div>
        </Permission>
        <Permission accessTo={USER_ROLES.ADMIN}>
          <div
            className="flex justify-end mb-[10px]"
            data-html2canvas-ignore="true"
          >
            {actionDisabled ? (
              <Button
                handleClick={() => {
                  if (!tableDisabled) {
                    toast.error('Please save the data');
                    return;
                  }
                  editChildState(actionItemData);
                  // setActionDisabled(false);
                }}
                name="Edit"
                className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl mt-8 ml-4"
              />
            ) : (
              <Button
                handleClick={() => handleSave(actionItemData)}
                name="Save"
                className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl mt-8 ml-4"
              />
            )}
          </div>
        </Permission>
      </div>
    </div>
  );
}

export default ActionItemTable;

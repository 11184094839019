import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import StaticActions from './StaticActions';
import { ReactComponent as AccordianMinus } from '../../../assets/Icons/AccordianMinus.svg';
import { ReactComponent as AccordianPlus } from '../../../assets/Icons/AccordianPlus.svg';
import './style.css';

const FormAccordion = props => {
  let {
    formData,
    updateFormData,
    formStatus,
    errorMessages,
    setErrorMessage,
    editState,
  } = props;
  return (
    <AccordionWrapper
      items={formData?.parameters}
      updateFormData={updateFormData}
      reviewAssessments={formData?.parameters}
      formStatus={formStatus}
      errorMessages={errorMessages}
      setErrorMessage={setErrorMessage}
      editState={editState}
    />
  );
};

const AccordionWrapper = ({
  items,
  updateFormData,
  reviewAssessments,
  formStatus,
  errorMessages,
  setErrorMessage,
  editState,
}) => {
  const [openItems, setOpenItems] = useState(0);

  const updateData = (args, id) => {
    const [index, key, value] = args;
    const updatedData = cloneDeep(reviewAssessments);
    if (!updatedData[id].criterias[index].assessment)
      updatedData[id].criterias[index].assessment = {};
    updatedData[id].criterias[index].assessment[key] = value;
    if (key === 'skip') {
      updatedData[id].criterias[index].assessment.rating = 0;
      updatedData[id].criterias[index].assessment.status = '';
      updatedData[id].criterias[index].assessment.comment = '';
    }
    updateFormData(updatedData);
  };

  const handleToggle = index => {
    setOpenItems(prevIndex => (prevIndex === index ? null : index));
  };
  let counter = 0;
  // let args = [];

  return (
    <div className="mt-10 ml-4 border-l">
      {items.map((item, index) => {
        counter++;
        const content = (
          <StaticActions
            question={item.criterias}
            updateData={(...args) => updateData(args, index)}
            // updateFormData={updateFormData}
            id={index}
            formData={reviewAssessments[index]}
            disabledForm={formStatus === 'COMPLETED' && !editState}
            errorMessages={errorMessages}
            setErrorMessage={setErrorMessage}
          />
        );
        return (
          <AccordionItem
            key={index}
            title={item.parameter.name}
            counter={counter}
            isOpen={openItems === index}
            onToggle={() => handleToggle(index)}
          >
            {content}
          </AccordionItem>
        );
      })}
    </div>
  );
};

function AccordionItem({ title, children, isOpen, onToggle, counter }) {
  return (
    <div className="accordion-label border-b ml-12 py-8">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={onToggle}
      >
        <div className="flex justify-between items-center">
          <span
            className={`${
              isOpen
                ? 'bg-[#101d48] text-white'
                : 'bg-white border-[#101d48] text-[#101d48]'
            }  rounded-full ml-[-4.75rem] px-5 py-2 border accordion-counter`}
          >
            {counter}
          </span>
          <span className="text-[#151D48] text-2xl font-bold ml-8">
            {title}
          </span>
        </div>
        <div className="icon-wrap">
          {isOpen ? <AccordianMinus /> : <AccordianPlus />}
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content ml-8 p-8 rounded-2xl">{children}</div>
      )}
    </div>
  );
}

export default FormAccordion;

import React from 'react';
import { useSelector } from 'react-redux';
import {
  PERMISSION_GROUP,
  USER_ROLE_MAPPER,
} from 'app/common/services/permission/permissionConstant';
import { selectUser } from 'app/common/slices/authslice/selectors';

/**
 * This component controls the visibility of its children based on user permissions.
 * @param {ReactNode} props.children - The child nodes of this component.
 * @param {string} props.accessTo - The permission required to view the child nodes.
 * @returns {ReactNode} The child nodes if the user has the necessary permissions, otherwise null.
 */
const Permission = ({ children, accessTo }) => {
  // Get the current user's details from the Redux store & Extract the user's role from the user details
  const userDetails = useSelector(selectUser);
  const USER_PERMISSION = userDetails?.user?.role;
  try {
    if (accessTo && USER_PERMISSION) {
      // Get the index of the user's role in the USER_ROLE_MAPPER
      let accessToIndex = USER_ROLE_MAPPER[USER_PERMISSION];
      if (PERMISSION_GROUP?.[accessToIndex].includes(accessTo)) {
        return <React.Fragment>{children}</React.Fragment>;
      }
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export default Permission;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import moment from 'moment';
import Breadcrumb from 'app/common/components/Breadcrumb';
import Dropdown from 'app/common/components/DropDown';
import { useReviewSlice } from '../../reviewSlice';
import Loader from 'app/common/components/Loader';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import {
  handleDotsClick,
  addDotsEventListener,
  removeDotsEventListener,
} from 'app/common/utils/helperFn';
import DashboardTable from 'app/common/components/Table/table';
import {
  tableview,
  leftArrow,
  threeDots,
  infoImg,
  // addMore,
} from 'app/assets/Common/commonImages';
import Button from 'app/common/components/Button';
import { useProjectReviewSlice } from 'app/pages/Review/components/AssesmentListing/AssessmentPage/assessmentSlice';
import { modalManagerActions } from 'app/common/components/Modals/ModalManager/modalSlice';
import { selectProjectReview } from 'app/pages/Review/components/AssesmentListing/AssessmentPage/assessmentSlice/selectors';
import { LoaderContainer } from '../../../Setup/style';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services/permission/permissionConstant';
import RecommmendationView from '../RecommendationView';
import ReviewNotes from './reviewNotes';
import { Head } from './style';
import './style.css';

const Index = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions } = useReviewSlice();
  const { actions: reviewActions } = useProjectReviewSlice();
  const projectReview = useSelector(selectProjectReview);
  const { loading: projectReviewLoading } = projectReview;
  const categoriesResponse = useSelector(selectReview);
  const { loading, assessment, finalReviewNotes, finalReviewProject } =
    categoriesResponse;
  // const [endDate, setEndDate] = useState('');
  const [date, setDate] = useState({});

  const cardsData = [
    {
      title: 'Upcoming Events',
      content: assessment?.assessmentDetails?.followUps,
      id: 'upcomingEvents',
    },
    ...(assessment &&
    assessment.assessmentDetails &&
    assessment.assessmentDetails.trackClosed &&
    assessment.assessmentDetails.trackClosed.length > 0
      ? [
          {
            title: 'Project Closing Notes',
            content: assessment?.assessmentDetails?.trackClosed,
            id: 'projectClosingNotes',
          },
        ]
      : []),
  ];

  const handleGenerateRecommendation = row => {
    const { assessmentId } = row;
    const { trackId } = params;

    dispatch(actions.generateRecommendation({ trackId, assessmentId }));
  };

  const handleSaveNotes = data => {
    const flattenedDetails = {
      project: assessment?.assessmentDetails?.id,
      ...data,
    };
    delete flattenedDetails['id'];
    dispatch(
      actions.updateAssessment({
        trackId: assessment?.assessmentDetails?.id,
        data: flattenedDetails,
        id: data.id,
      }),
    );
  };

  const getProjectTrackName = () => {
    return (
      assessment?.assessmentDetails?.project?.projectName +
      (assessment?.assessmentDetails?.trackName
        ? ' - ' + assessment?.assessmentDetails?.trackName
        : '')
    );
  };
  const breadcrumbItems = [
    { text: 'Review', link: '/review' },
    {
      text: assessment?.assessmentDetails?.businessUnit?.businessUnitName,
      link: `/review/${assessment?.assessmentDetails?.businessUnit?._id}`,
    },
    {
      text: assessment?.assessmentDetails?.account?.accountName,
      link: `/review/account/${assessment?.assessmentDetails?.account?._id}`,
      state: {
        project: assessment?.assessmentDetails?.project,
      },
    },
    { text: getProjectTrackName() },
  ];

  let statusObj = { IN_PROGRESS: 'In Progress', COMPLETED: 'Completed' };

  useEffect(() => {
    addDotsEventListener();
    return removeDotsEventListener();
  }, []);

  useEffect(() => {
    dispatch(actions.getReviewCategorys());
    dispatch(actions.getTrackAssessmentData(params?.trackId));
    projectReview.assessmentComplete &&
      dispatch(reviewActions.clearProjectReviewData());
  }, [
    actions,
    dispatch,
    projectReview.assessmentComplete,
    reviewActions,
    params?.trackId,
  ]);

  useEffect(() => {
    dispatch(actions.getRecommendations(params?.trackId));
  }, [dispatch, actions, params?.trackId]);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleRedirect = (row, params) => {
    if (row.assessmentId) {
      navigate(`/review/assessment/${row.assessmentId}`);
    } else {
      dispatch(
        reviewActions.createProjectReview({
          categoryId: row.categoryId,
          trackId: params?.trackId,
        }),
      );
    }
  };

  useEffect(() => {
    if (projectReview.assessmentId) {
      navigate(`/review/assessment/${projectReview.assessmentId}`);
    }
  }, [navigate, projectReview.assessmentId]);

  const handleSelect = option => {
    const selectedCategory = categoriesResponse.categories.find(
      category => category.name === option,
    );

    const criteriaLength = selectedCategory.parameters.reduce(
      (total, parameter) => total + parameter.criterias.length,
      0,
    );

    setSelectedOption(prevSelectedOption => ({
      id: selectedCategory?.id,
      option: option,
      criteriaLength: criteriaLength,
    }));
  };

  const assessments =
    categoriesResponse?.assessment?.assessmentDetails?.assessments || [];

  // todo - Revisit this code -Pritesh
  let data = [];
  if (assessments?.length > 0) {
    data = assessments
      .map((assessment, index) => {
        const startDate = assessment?.startDate || assessment?.createdAt;
        const rowData = {
          assessmentId: assessment?.id,
          categoryId: assessment?.categoryId,
          category: assessment?.categoryName || '',
          reviewingFor: assessment?.reviewFor || '-',
          startDate: startDate ? new Date(startDate).toISOString() : '',
          endDate: assessment.endDate
            ? new Date(assessment.endDate).toISOString()
            : '',
          // rating:
          //   assessment?.rating === 0
          //     ? 'N/A'
          //     : assessment?.rating
          //     ? assessment?.rating.toFixed(1)
          //     : '-',
          questions: assessment?.totalQuestionCount || '',
          reviewStatus: statusObj[assessment.status] || 'Yet to Start',
          attendees: assessment.attendees || [],
          _id: index, //_id for unique key
          recommendation: assessment.recommendations,
        };

        if (index === assessments?.length - 1 && selectedOption?.option) {
          return [
            rowData,
            {
              category: selectedOption?.option || '',
              categoryId: selectedOption?.id || '',
              reviewingFor: '-',
              startDate: new Date().toISOString(),
              endDate: '',
              // rating: '-',
              questions: selectedOption?.criteriaLength,
              reviewStatus: '',
              _id: index + 1,
            },
          ];
        }

        return rowData;
      })
      .flat();
  } else if (selectedOption?.option) {
    data = [
      {
        category: selectedOption?.option || '',
        categoryId: selectedOption?.id || '',
        reviewingFor: '-',
        startDate: new Date().toISOString(),
        endDate: '',
        // rating: '-',
        questions: selectedOption?.criteriaLength,
        reviewStatus: '',
        _id: 0,
      },
    ];
  }
  //delete Assessment
  const deleteAssessmentHandler = assessment => {
    if (assessment) {
      const userConfirmed = window.confirm('Are you sure you want to delete?');
      if (userConfirmed) {
        if (assessment?.assessmentId) {
          dispatch(reviewActions.deleteAssessment(assessment?.assessmentId));
        } else {
          setSelectedOption(null);
          toast.success('Assessment deleted succesfully');
        }
      } else {
        return;
      }
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'category',
      text: 'Category',
    },
    {
      dataField: 'reviewingFor',
      text: 'Review Type',
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      formatter: (cell, row, rowIndex) =>
        cell
          ? new Date(cell).toLocaleString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : '-',
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      formatter: (cell, row, rowIndex) =>
        cell
          ? new Date(cell).toLocaleString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : '-',
    },
    // {
    //   dataField: 'rating',
    //   text: 'Rating',
    // },
    {
      dataField: 'attendees',
      text: 'Attendees',
      formatter: (cell, row) =>
        row?.attendees?.length > 0 ? (
          <div>
            <span className="relative group">
              <img
                src={infoImg}
                alt="attendees"
                className="cursor-pointer	h-[22px]"
              />
              <div className="z-10 table-auto border border-solid border-1 border-[#f0f4f7] rounded-[4px] absolute !hidden bottom-2 whitespace-nowrap rounded text-black opacity-0 transition group-hover:opacity-100 top-[22px] translate-x-10 group-hover:!block">
                <table className="bg-white">
                  <thead>
                    <tr className="border">
                      <th className="md:p-1 lg:p-3 xl:p-3">#</th>
                      <th className="md:p-1 lg:p-3 xl:p-3">Name</th>
                      <th className="md:p-1 lg:p-3 xl:p-3">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.attendees.map((attendee, index) => {
                      return (
                        <tr
                          className="border"
                          key={`${attendee?.firstName}-${index}`}
                        >
                          <td className="md:p-1 lg:p-3 xl:p-3">{index + 1}</td>
                          <td className="md:p-1 lg:p-3 xl:p-3">
                            {attendee?.firstName +
                              (attendee?.lastName
                                ? ' ' + attendee?.lastName
                                : '')}
                          </td>
                          <td className="md:p-1 lg:p-3 xl:p-3">
                            {attendee?.username}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </span>
          </div>
        ) : (
          '-'
        ),
    },
    {
      dataField: 'questions',
      text: 'Total Questions',
    },
    {
      dataField: 'reviewStatus',
      text: 'Review Status',
      formatter: cell => (
        <div
          className={`rounded-md border w-fit py-1.5 px-4 text-center font-sans text-sm font-normal ${
            cell === statusObj.COMPLETED
              ? 'border-[#00CE81] text-[#00CE81] bg-[#F0FDF4]'
              : cell === statusObj.IN_PROGRESS
              ? 'border-[#eec700] text-[#ac9000] bg-[#F8F8F8]'
              : 'border-[#F28300] text-[#F28300] bg-[#F8F8F8]'
          }`}
        >
          {cell !== '' ? cell : 'Yet to Start'}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) =>
        row.reviewStatus === statusObj.COMPLETED ? (
          <img
            src={tableview}
            alt="view"
            className="cursor-pointer pl-[12px]	"
            onClick={() => handleRedirect(row, params)}
          />
        ) : (
          <Permission accessTo={USER_ROLES.ADMIN}>
            <Button
              handleClick={() => handleRedirect(row, params)}
              name={
                row.reviewStatus === statusObj.IN_PROGRESS ? 'Proceed' : 'Start'
              }
              value={
                row.reviewStatus === statusObj.IN_PROGRESS ? 'proceed' : 'start'
              }
              className={`save-btn text-lg rounded-[8px] text-white py-[7px] px-[12px] min-w-[70px] ${
                selectedOption || row.category ? '' : 'hidden'
              } ${
                row.reviewStatus === statusObj.IN_PROGRESS
                  ? 'bg-[#151d48]'
                  : 'bg-[#DE1186]'
              }`}
            />
          </Permission>
        ),
    },
    {
      dataField: 'actionOther',
      text: '',
      formatter: (cell, row) => {
        // if (row?.reviewStatus !== statusObj.COMPLETED) {
        return assessment?.assessmentDetails?.reviewStatus !== 'COMPLETED' ? (
          <Permission accessTo={USER_ROLES.ADMIN}>
            <img
              src={threeDots}
              alt="view"
              className="cursor-pointer h-[18px] mt-2.5 relative"
              onClick={event => handleDotsClick(event, row._id)}
            />
            {/* <div> */}
            <div
              id={row._id}
              className="options-container three-dots  bg-white text-black hidden inline-grid absolute right-[7rem] whitespace-nowrap rounded bg-white text-black shadow z-50"
            >
              {row?.reviewStatus === statusObj.COMPLETED ? (
                <>
                  <div
                    className="border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
                    onClick={() => {
                      navigate(`/review/assessment/${row.assessmentId}`, {
                        state: {
                          edit: true,
                        },
                      });
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className="border-b border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
                    onClick={() => {
                      deleteAssessmentHandler(row);
                    }}
                  >
                    Remove
                  </div>
                  {categoriesResponse?.recommendationData.filter(
                    val => val?.assmtId === row?.assessmentId,
                  ).length === 0 ? ( // Check if recommendation has not been generated for this row
                    <div
                      className="border-b border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px] "
                      onClick={() => {
                        handleGenerateRecommendation(row);
                      }}
                    >
                      Generate Recommendation
                    </div>
                  ) : null}
                </>
              ) : (
                <div
                  className="border-b border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
                  onClick={() => {
                    deleteAssessmentHandler(row);
                  }}
                >
                  Remove
                </div>
              )}
            </div>
          </Permission>
        ) : null;
        // }
        // return null;
      },
    },
  ];
  //check if all assesments are completed
  const allReviewsCompleted = data.every(
    row => row.reviewStatus === statusObj.COMPLETED,
  );

  //Submit all assesments of a track
  const handleSubmit = async e => {
    dispatch(
      modalManagerActions.openPopUp({
        modalType: 'FollowUpDates',
        className: `lg:w-4/12`,
        trackId: params?.trackId,
      }),
    );
  };

  const handleBackBtnClick = () => {
    try {
      navigate(
        `/review/account/${assessment?.assessmentDetails?.account?._id}`,
        {
          state: {
            project: assessment?.assessmentDetails?.project,
          },
        },
      );
    } catch (e) {}
  };

  const formattedDate = date => {
    return new Date(date).toLocaleString('en-us', {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    });
  };

  return (
    <>
      {loading || projectReviewLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <div className="p-[20px]">
          <div className="mb-12">
            <Head className="p-0">
              <div className="flex">
                <img
                  src={leftArrow}
                  alt="back"
                  className="cursor-pointer h-8 mt-2 mr-2 -ml-1.5 filter grayscale"
                  onClick={handleBackBtnClick}
                />
                <h2 className="breadcrumb-heading text-[#151D48] text-[28px] mb-4 font-semibold">
                  {/* show Track name aling with Project Name */}
                  {getProjectTrackName()}
                </h2>
              </div>
            </Head>
            <Breadcrumb items={breadcrumbItems} />
            {assessment?.assessmentDetails?.reviewStatus === 'COMPLETED' && (
              <>
                <p className="text-[28px] text-[#34B14A] font-semibold mt-[20px]">
                  {`Your review has been Completed on ${formattedDate(
                    assessment?.assessmentDetails?.endDate,
                  )}`}
                </p>
                <p className="text-[16px] text-[#151D48] font-normal opacity-50">
                  You can’t edit the assessment
                </p>
              </>
            )}
          </div>
          <div className="rounded-2xl custom-card relative p-10 pb-12 pt-6 shadow-md flex-1 block bg-[#fffff]">
            <Permission accessTo={USER_ROLES.ADMIN}>
              {assessment?.assessmentDetails?.reviewStatus !== 'COMPLETED' && (
                <div className="wrapper pb-10">
                  <h3 className="text-[#151D48] text-[28px] font-semibold ">
                    Add Category :
                  </h3>
                  <Dropdown
                    options={categoriesResponse.categories.map(
                      category => category.name,
                    )}
                    onSelect={option => handleSelect(option)}
                    selectedOption={selectedOption?.option}
                    selectedOptionStyle={{ padding: '10px' }}
                    className="drp-1 relative rounded-[4px] border border-solid border-1 border-[#737791] w-full md:w-full lg:w-[400px]"
                    optionsClassName="custom-options1"
                    placeholder="Select Category name"
                  />
                </div>
              )}
            </Permission>
            <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10 ">
              <DashboardTable
                data={data}
                columns={columns}
                classcategory={'tablereviewpage'}
              />
            </div>
          </div>
          {assessment?.assessmentDetails?.reviewStatus === 'COMPLETED' &&
            (assessment?.assessmentDetails?.tagOwner.length > 0 ||
              assessment?.assessmentDetails?.projectOwner.length > 0 ||
              assessment?.assessmentDetails?.agileOwner.length > 0 ||
              assessment?.assessmentDetails?.pgOwner.length > 0) && (
              <div className="bg-[#F1F3FD] p-6 mt-8 mx-[40px] rounded-md shadow-md">
                {assessment?.assessmentDetails?.pgOwner.length > 0 && (
                  <div className="mb-6">
                    <p className="text-xl text-[#151D48] font-semibold mb-2">
                      PG SPOC:
                    </p>
                    <div className="flex space-x-2">
                      {assessment?.assessmentDetails?.pgOwner.map(
                        (owner, index) => (
                          <span key={index} className="text-lg text-[#151D48]">
                            {owner.username}
                            {index !==
                              assessment?.assessmentDetails?.pgOwner.length -
                                1 && ','}
                          </span>
                        ),
                      )}
                    </div>
                  </div>
                )}
                {assessment?.assessmentDetails?.agileOwner.length > 0 && (
                  <div className="mb-6">
                    <p className="text-xl text-[#151D48] font-semibold mb-2">
                      Agile SPOC:
                    </p>
                    <div className="flex space-x-2">
                      {assessment?.assessmentDetails?.agileOwner.map(
                        (owner, index) => (
                          <span key={index} className="text-lg text-[#151D48]">
                            {owner.username}
                            {index !==
                              assessment?.assessmentDetails?.agileOwner.length -
                                1 && ','}
                          </span>
                        ),
                      )}
                    </div>
                  </div>
                )}
                {assessment?.assessmentDetails?.tagOwner.length > 0 && (
                  <div className="mb-6">
                    <p className="text-xl text-[#151D48] font-semibold mb-2">
                      Tag SPOC:
                    </p>
                    <div className="flex space-x-2">
                      {assessment?.assessmentDetails?.tagOwner.map(
                        (owner, index) => (
                          <span key={index} className="text-lg text-[#151D48]">
                            {owner.username}
                            {index !==
                              assessment?.assessmentDetails?.tagOwner?.length -
                                1 && ','}
                          </span>
                        ),
                      )}
                    </div>
                  </div>
                )}

                {assessment?.assessmentDetails?.projectOwner.length > 0 && (
                  <div>
                    <p className="text-xl text-[#151D48] font-semibold mb-2">
                      Project SPOC:
                    </p>
                    <div className="flex space-x-2">
                      {assessment?.assessmentDetails?.projectOwner.map(
                        (owner, index) => (
                          <span key={index} className="text-lg text-[#151D48]">
                            {owner.username}
                            {index !==
                              assessment?.assessmentDetails?.projectOwner
                                .length -
                                1 && ','}
                          </span>
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          <ReviewNotes
            data={assessment?.assessmentDetails || []}
            handleSaveNotes={handleSaveNotes}
            projectLoading={finalReviewProject}
            notesLoading={finalReviewNotes}
            // endDate={endDate}
            // setEndDate={setEndDate}
            date={date}
            setDate={setDate}
            cardsData={cardsData}
            assessment={assessment}
            handleSubmit={handleSubmit}
          />

          {categoriesResponse.recommendationData.length > 0 && (
            <RecommmendationView
              trackRecommendationData={
                categoriesResponse?.tempRecommData.length
                  ? categoriesResponse?.tempRecommData
                  : categoriesResponse?.recommendationData
              }
              assessment={assessment}
              allReviewsCompleted={allReviewsCompleted}
              endDate={date?.endDate}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Index;

import { jwtDecode } from 'jwt-decode';

export const checkValid = () =>
  new Promise((res, rej) => {
    const cookie = document.cookie
      ?.split(';')
      ?.find(row => row.trim().startsWith('phd-backend'))
      ?.split('=')[1];
    if (!cookie) {
      return res({ isExpired: false });
    }
    const decoded = jwtDecode(cookie);

    if (decoded && decoded.exp) {
      const tokenExpiryTime = new Date(decoded.exp * 1000).getTime();
      const currTime = new Date().getTime();
      if (tokenExpiryTime <= currTime) {
        return res({ email: decoded.email, isExpired: true });
      }
    }
    return res({ isExpired: false });
  });

import React from 'react';
import loader from 'app/assets/loader.gif';
import './style.css';
const LoaderComponent = () => {
  return (
    <div className="loader-overlay">
      <img src={loader} alt="Loader" className="loader" />
    </div>
  );
};

export default LoaderComponent;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-label {
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.tooltips::after{
    content: "";
    position: absolute;
    top: 7px;
    margin-left: -9px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #333 transparent transparent ;
}

`, "",{"version":3,"sources":["webpack://./src/app/common/components/FormAccordion/style.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;IAClB;IACA;QACI,iBAAiB;QACjB,gBAAgB;IACpB;AACJ;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,uDAAuD;AAC3D","sourcesContent":[".accordion-label {\n    &:first-child {\n        padding-top: 0;\n    }\n    &:last-child {\n        padding-bottom: 0;\n        border-bottom: 0;\n    }\n}\n\n.tooltips::after{\n    content: \"\";\n    position: absolute;\n    top: 7px;\n    margin-left: -9px;\n    border-width: 5px;\n    border-style: solid;\n    border-color: transparent #333 transparent transparent ;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { categoryActions as actions } from '.';
import { toast } from 'react-toastify';
import { commonActions } from '../../../../../common/slices/errorBoundarySlice/index';

export function* getCategory() {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category`;
  try {
    const categories = yield call(request, requestURL);
    yield put(actions.gotCategory(categories));
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.categoryError('CATEGORIES NOT FOUND'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.categoryError('RATE LIMIT'));
      toast.error('Rate limit or network error');
    } else {
      yield put(actions.categoryError('RESPONSE ERROR'));
    }
  }
}

export function* createCategory(action) {
  const formData = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category`;
  try {
    const categories = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    toast.success('Category Created!');
    yield put(actions.categoryCreated(categories));
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.createCategoryError('CATEGORIES NOT FOUND'));
      toast.error('Category not found!');
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.createCategoryError('RATE LIMIT'));
      toast.error('Rate limit or network error');
    } else {
      yield put(actions.createCategoryError('RESPONSE ERROR'));
      toast.error('Response error');
    }
  }
}

export function* updateCategory(action) {
  const { categoryId, data } = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category/${categoryId}`;
  try {
    const categories = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    toast.success('Category Updated!');
    yield put(actions.categoryUpdated(categories));
  } catch (err) {
    // in case we get 403 error, i.e category already mapped to any assessment, we show option to clone the category through ErrorPopup
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
        categoryId,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.updateCategoryError('CATEGORY UPDATE NOT FOUND'));
    //   toast.error('Category update api not found!');
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.updateCategoryError('RATE LIMIT'));
    //   toast.error('Rate limit or network error');
    // } else {
    //   yield put(actions.updateCategoryError('RESPONSE ERROR'));
    //   toast.error('Response error');
    // }
  }
}

export function* getCategoryById(action) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category/${action.payload}`;
  try {
    const category = yield call(request, requestURL);
    yield put(actions.gotCategoryById(category));
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.categoryByIdError('CATEGORY DETAILS NOT FOUND'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.categoryByIdError('RATE LIMIT'));
      toast.error('Rate limit or network error');
    } else {
      yield put(actions.categoryByIdError('RESPONSE ERROR'));
      toast.error('Response error');
    }
  }
}
export function* deleteCategoryById(action) {
  const categoryId = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category/${categoryId}?actionOn=CATEGORY`;
  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });
    yield put(actions.categoryDeleted(categoryId));
    toast.success('Category Deleted!');
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* deleteCriteria(action) {
  const { criteriaId, categoryId } = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category/${categoryId}?actionOn=CRITERIA&criteriaId=${criteriaId}`;
  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });
    yield put(actions.criteriaDeleted(categoryId));
    toast.success('Criteria Deleted!');
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* deleteCategoryParamter(action) {
  const { parameterId, categoryId } = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category/${categoryId}?actionOn=CATEGORY_PARAMETER&parameterId=${parameterId}`;
  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });
    yield put(actions.categoryParameterDeleted(categoryId));
    toast.success('Category Parameter Deleted!');
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
      }),
    );
    yield put(actions.removeLoader());
  }
}
export function* updateCategoryData(action) {
  yield put(actions.updateCategoryData(action.payload));
}

export function* cloneCategoryById(action) {
  const categoryId = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/clone/category/${categoryId}`;
  try {
    const categories = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    toast.success('Category Cloned successfully! You can now make changes.');
    yield put(actions.categoryCloned(categories));
  } catch (err) {}
}

export function* categoryFormSaga() {
  yield takeLatest(actions.getCategory, getCategory);
  yield takeLatest(actions.getCategoryById, getCategoryById);
  yield takeLatest(actions.createCategory, createCategory);
  yield takeLatest(actions.updateCategory, updateCategory);
  yield takeLatest(actions.deleteCategoryById, deleteCategoryById);
  yield takeLatest(actions.deleteCriteria, deleteCriteria);
  yield takeLatest(actions.deleteCategoryParamter, deleteCategoryParamter);
  yield takeLatest(actions.updateCategoryData, updateCategoryData);
  yield takeLatest(actions.cloneCategoryById, cloneCategoryById);
}

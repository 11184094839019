import React from 'react';
import {
  multiview,
  chartview,
  gridview,
  exporticon,
  multiviewactive,
  gridviewactive,
  chartviewactive,
} from '../../../assets/Common/commonImages';

import { Title, RightIcons } from './style';

const CardsNavbar = ({
  onNavbarIconClick,
  navbaricons,
  heading,
  subheading,
  activeIcon,
}) => {
  const dashboardLayoutIcons = [
    {
      id: 'exporticon',
      src: exporticon,
      alt: 'layout',
    },
    {
      id: 'chartview',
      src: activeIcon === 'chartview' ? chartviewactive : chartview,
      alt: 'layout',
    },
    {
      id: 'gridview',
      src: activeIcon === 'gridview' ? multiviewactive : gridview,
      alt: 'layout',
    },
    {
      id: 'multiview',
      src: activeIcon === 'multiview' ? gridviewactive : multiview,
      alt: 'layout',
    },
  ];
  return (
    <div className="flex justify-between mb-8 flex-col">
      <Title>
        <h1> {heading}</h1>
        <span>{subheading}</span>
      </Title>
      {navbaricons && (
        <RightIcons>
          {dashboardLayoutIcons.map(item => (
            <img
              key={item.id}
              id={item.id}
              src={item.src}
              alt={item.alt}
              onClick={() => onNavbarIconClick(item.id)}
            />
          ))}
        </RightIcons>
      )}
    </div>
  );
};

export default CardsNavbar;

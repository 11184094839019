import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';

export const initialState = {
  showModal: false,
  modalType: '',
};

const slice = createSlice({
  name: 'modalManager',
  initialState,
  reducers: {
    openPopUp(state, action) {
      state.showModal = true;
      state.modalType = action.payload?.modalType;
      state.heading = action.payload?.heading;
      state.data = action.payload?.data;
      state.modalClassName = action.payload?.modalClassName;
    },
    closePopUp(state) {
      state.showModal = false;
      state.modalType = '';
    },
  },
});

export const { actions: modalManagerActions } = slice;

export const useModalManagerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

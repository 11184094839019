import React from 'react';
import Radio from '../Radio';
import { infoSmallImage } from '../../../assets/Common/commonImages';
import { suggestionsStatusCriteria } from 'app/common/constants/constants';

const StaticActions = props => {
  let {
    question,
    id,
    updateData,
    disabledForm,
    errorMessages,
    setErrorMessage,
  } = props;
  const radioButtons = ['N/A', '1', '2', '3', '4', '5'];

  return (
    <>
      {question.map((questionItem, index) => {
        const statusValues = ['N/A', ...questionItem.status];
        return (
          <div
            className={`my-4 py-8 px-8 rounded-2xl bg-[#F1F3FD] relative`}
            key={index}
          >
            {questionItem?.assessment?.skip === true && (
              <div
                className="absolute inset-0 bg-gray-400 opacity-30 rounded-2xl"
                style={{ zIndex: '1' }}
              ></div>
            )}

            <div
              className="flex justify-end items-center relative"
              style={{
                zIndex: '2',
              }}
            >
              <input
                type="checkbox"
                id={`skip${index}`}
                checked={questionItem?.assessment?.skip === true}
                onChange={e => updateData(index, 'skip', e.target.checked)}
                disabled={disabledForm}
                className={`mb-2.5 mr-2`}
              />
              <label
                htmlFor={`skip${index}`}
                className={``}
                style={{ zIndex: '2', color: '#f6007e', margin: '2px 0 0 4px' }}
              >
                {'Skip'}
              </label>
            </div>
            {questionItem?.measurementCriteria && (
              <h2 className="text-[#151D48] text-2xl font-bold">
                {questionItem?.measurementCriteria}
              </h2>
            )}
            {questionItem?.ratings !== 0 && (
              <>
                <span className="text-lg text-[#151D48] opacity-50">
                  Rating
                </span>
                <span className="flex items-center">
                  {radioButtons.map((radioItem, radioIndex) => {
                    const value =
                      questionItem?.assessment?.rating !== '' &&
                      questionItem?.assessment?.rating !== undefined
                        ? questionItem?.assessment?.rating === 0
                          ? 'N/A'
                          : questionItem?.assessment?.rating
                        : 'N/A'; //by default N/A should be selected
                    return (
                      <Radio
                        key={radioIndex}
                        label={radioItem}
                        id={`ratings${index}`}
                        name={`ratings${index}`}
                        classes={{
                          inputBoxContainer: 'mr-16',
                          inputField: '!mt-0',
                          label:
                            'mb-0 mt-1 text-[#151D48] text-xl font-sans font-medium',
                        }}
                        checked={value == radioItem}
                        onChange={e =>
                          updateData(
                            index,
                            'rating',
                            e.target.value === 'N/A' ? 0 : e.target.value,
                          )
                        }
                        disabled={
                          disabledForm || questionItem?.assessment?.skip
                        }
                      />
                    );
                  })}
                </span>
              </>
            )}
            {questionItem?.ratings !== 0 && (
              <div className="py-5">
                <span className="text-lg text-[#151D48] opacity-50">
                  Status
                </span>
                <span className="flex flex-wrap">
                  {statusValues.map((statusItem, statusIndex) => {
                    const mappedStatus = suggestionsStatusCriteria.find(
                      item => item?.value === statusItem,
                    );
                    const label = mappedStatus
                      ? mappedStatus?.label
                      : statusItem;
                    const value =
                      questionItem?.assessment?.status !== '' &&
                      questionItem?.assessment?.status !== undefined
                        ? questionItem?.assessment?.status
                        : 'N/A'; //by default N/A should be selected
                    return (
                      <Radio
                        key={statusIndex}
                        label={label}
                        id={`status${index}`}
                        name={`status${index}`}
                        classes={{
                          inputBoxContainer: 'mr-16',
                          inputField: '!mt-0',
                          label:
                            'mb-0 mt-1 text-[#151D48] text-xl font-sans font-medium',
                        }}
                        checked={value === label}
                        onChange={e => {
                          updateData(index, 'status', e.target.value);
                        }}
                        disabled={
                          disabledForm || questionItem?.assessment?.skip
                        }
                      />
                    );
                  })}
                </span>
              </div>
            )}

            <div className="py-5 relative">
              <div className="flex">
                <span className="text-lg text-[#151D48] opacity-50">
                  Comment *
                </span>
                {questionItem?.helpText ? (
                  <span class="group ml-3">
                    <img
                      src={infoSmallImage}
                      alt="attendees"
                      className="cursor-pointer	h-[16px]"
                    />
                    <div className="tooltips z-10 absolute ml-[-7px] rounded text-black opacity-0 group-hover:opacity-100 top-[8px] translate-x-12 transition-opacity duration-700 group-hover:!block">
                      <p className="text-[10px] bg-[#333] px-[10px] py-[5px] rounded-lg font-normal text-[#fff] ">
                        {questionItem?.helpText}
                      </p>
                    </div>
                  </span>
                ) : null}
              </div>
              <textarea
                className={`border w-full h-32 py-2 px-3 resize-none rounded-2xl outline-none ${
                  !questionItem?.assessment?.skip &&
                  errorMessages[`comment${id}_${index}`]
                    ? 'border-red-500'
                    : ''
                }`}
                rows="4"
                cols="50"
                name={`comment${index}`}
                value={questionItem?.assessment?.comment}
                onChange={e => {
                  e.target.value?.length < 5000 &&
                    updateData(index, 'comment', e.target.value);
                  const errors = { ...errorMessages };
                  errors[`comment${id}_${index}`] = '';
                  setErrorMessage(errors);
                }}
                disabled={disabledForm || questionItem?.assessment?.skip}
              />
              {!questionItem?.assessment?.skip &&
                errorMessages[`comment${id}_${index}`] && (
                  <span className="text-red-500 text-sm">
                    {errorMessages[`comment${id}_${index}`]}
                  </span>
                )}
              {/* <span className="absolute bottom-9 right-10 text-[#a2a2a2] text-lg">
              {1000 - (comment?.length || 0)} char
            </span> */}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default StaticActions;

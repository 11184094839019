import * as React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as HealthProjectLogo } from '../../../assets/health-project-logo.svg';
// import { ReactComponent as Notifications } from '../../../assets/notifications.svg';
import Dropdown from '../DropDown';
// import Button from 'app/components/common/Button';
import { useDispatch } from 'react-redux';
import { useUserAuthSlice } from '../../slices/authslice';
import { useNavigate } from 'react-router-dom';
import {
  HeaderContainer,
  RightSide,
  // NotificationsContainer,
  UserImage,
  UserDetails,
  UserName,
  UserId,
} from './style';
// import SearchBar from '../SearchBar';
import { selectUser } from '../../slices/authslice/selectors';
import './style.css';

const Header = props => {
  const dispatch = useDispatch();
  const { actions } = useUserAuthSlice();
  const userResp = useSelector(selectUser);
  const navigate = useNavigate();
  const user = userResp?.user;
  const handleLogout = () => {
    dispatch(actions.getLogoutUser());
    navigate('/login', {
      replace: true,
    });
  };

  return (
    <>
      <HeaderContainer>
        <div className="menu_mobile">
          <div className="mobile_menu_icon">
            <div className="menu_lines">
              <span className="menu_line" />
              <span className="menu_line" />
              <span className="menu_line" />
            </div>
          </div>
        </div>
        <HealthProjectLogo />
        <RightSide>
          {Object.keys(user)?.length && user?.name && (
            <>
              {user?.profilePicURL && (
                <UserImage alt="userImage" src={user?.profilePicURL} />
              )}
              <UserDetails>
                <Dropdown
                  options={['Logout']}
                  onSelect={() => handleLogout()}
                  placeholder=""
                  className="logout relative left-[14px] top-[12px] h-[20px]"
                  optionsClassName="your-custom-options-class absolute left-[9px] top-[59px] p-[5px] z-10 bg-white  w-52"
                />
                <UserName>{user?.name}</UserName>
                <UserId>{user?.newerId}</UserId>
              </UserDetails>
            </>
          )}
        </RightSide>
      </HeaderContainer>
    </>
  );
};

export default Header;

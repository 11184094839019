import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { permissionSaga } from './saga';

export const initialState = {
  view: 'Permission',
  error: null,
  loading: false,
  rolesData: [],
  rolesDeleted: false,
};

const slice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    getPermission(state) {
      state.loading = true;
    },
    gotPermission(state, action) {
      const permission = action.payload;
      state.rolesData = permission;
      state.loading = false;
    },
    createRoles(state) {
      state.loading = true;
      state.error = null;
    },
    rolesCreated(state, action) {
      state.loading = false;
    },
    resetState(state) {
      state.rolesData = initialState.rolesData;
    },
    rolesError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    removeLoader(state) {
      state.loading = false;
    },
  },
});

export const { actions: permissionActions } = slice;

export const usePermissionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: permissionSaga });
  return { actions: slice.actions };
};

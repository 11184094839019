import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { selectCalendar } from 'app/pages/DashboardCalendar/calendarslice/selectors';
import {
  // TRACK_STATUS,
  TRACK_STATUS_NEW,
  CALENDAR_HEIGHT,
} from 'app/common/constants/constants';

import './calender.scss';

const localizer = momentLocalizer(moment);

function DashboardCalendar({
  currentCalendarDate,
  setCurrentCalendarDate,
  selectedStatus,
}) {
  const [trackList, setTrackList] = useState([]);
  // const [calendarHeight, setCalendarHeight] = useState(CALENDAR_HEIGHT);
  const navigate = useNavigate();
  const timelineReview = useSelector(selectCalendar);

  useEffect(() => {
    let updatedEventResult = [];
    const statusIndices = {};
    let trackData = timelineReview?.trackData?.calendar || [];
    // remove YET_TO_START tracks from calendar
    trackData = timelineReview?.trackData?.calendar.filter(data => {
      return data.reviewStatus !== 'YET_TO_START';
    });

    if (selectedStatus !== 'ALL') {
      trackData = trackData.filter(data => {
        return selectedStatus === 'followUps'
          ? data.followUps?.length > 0
          : data.reviewStatus === selectedStatus;
      });
    }
    if (selectedStatus !== 'followUps') {
      updatedEventResult = trackData?.map(item => {
        if (!item?.endDate) {
          item = {
            ...item,
            status: item?.reviewStatus,
            // endDate: item?.createdAt,
            endDate: new Date(),
          };
        }

        if (
          !(item?.reviewStatus in statusIndices) ||
          statusIndices[item?.reviewStatus] > 4
        ) {
          statusIndices[item?.reviewStatus] = 0;
        }
        const statusIndex = statusIndices[item?.reviewStatus];
        statusIndices[item?.reviewStatus] += 1;

        return {
          title: `${item?.project?.projectName} - ${item?.trackName} - ${
            TRACK_STATUS_NEW.find(status => status.value === item?.reviewStatus)
              .label
          }`,
          start: new Date(item.createdAt),
          end: new Date(item.endDate),
          description: `${item.reviewStatus}-${statusIndex}`,
          trackId: item.id,
        };
      });
    }
    if (selectedStatus === 'ALL' || selectedStatus === 'followUps') {
      trackData.forEach(item => {
        if (
          !(item?.reviewStatus in statusIndices) ||
          statusIndices[item?.reviewStatus] > 4
        ) {
          statusIndices[item?.reviewStatus] = 0;
        }
        const statusIndex = statusIndices[item?.reviewStatus];
        statusIndices[item?.reviewStatus] += 1;

        if (item.followUps) {
          let newDataOBj = item.followUps.map(_item => {
            return {
              // title: `${item?.project?.projectName} - ${item?.trackName} - ${
              //   TRACK_STATUS_NEW.find(
              //     status => status.value === item?.reviewStatus,
              //   ).label
              // }`,
              title: `Follow-up - ${item?.project?.projectName} - ${item?.trackName}`,
              start: new Date(new Date(_item.nextFollowUp).toDateString()),
              end: new Date(new Date(_item.nextFollowUp).toDateString()),
              description: `follow-up-${statusIndex}`,
              trackId: item.id,
            };
          });
          updatedEventResult.push(...newDataOBj);
        }
      });
    }

    setTrackList(updatedEventResult);
  }, [timelineReview?.trackData?.calendar, selectedStatus]);

  const event = ({ event }) => {
    return (
      <div
        className={event.description.toLowerCase()}
        onClick={() => handleRedirect(event)}
      >
        {' '}
        {event.title}
      </div>
    );
  };

  function handleRedirect(row) {
    try {
      navigate(`/review/track/${row.trackId}`);
    } catch (e) {}
  }

  function handleNavigate(dateStr, view) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    if (date.getFullYear() !== currentCalendarDate?.year) {
      setCurrentCalendarDate({ year, date });
    }
  }

  const handleViewChange = newView => {
    console.log('New View:', newView); // Log the new view
    // Perform any additional actions based on the new view
  };
  // const customTimeSlotWrapper = ({ children }) => {
  //   return <div style={{ display: 'none' }}>{children}</div>;
  // };

  return (
    <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10">
      <Calendar
        localizer={localizer}
        defaultDate={new Date(currentCalendarDate?.date)}
        defaultView="month"
        events={trackList}
        style={{ height: CALENDAR_HEIGHT }}
        components={{ event /*timeSlotWrapper: customTimeSlotWrapper*/ }}
        onNavigate={handleNavigate}
        views={['month', 'week', 'day']}
        onView={handleViewChange}
      />
    </div>
  );
}

export default DashboardCalendar;

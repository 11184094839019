import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'app/common/components/Button';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';

interface dataProps {
  data: {
    name?: string;
    heading: string;
    confirmFunc?: any;
  };
}

const ConfirmationPopup: React.FC<dataProps> = (props: dataProps) => {
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();

  const handleAddClick = () => {
    props?.data?.confirmFunc();
    dispatch(modalManagerActions.closePopUp());
  };

  const handleCancel = () => {
    dispatch(modalManagerActions.closePopUp());
  };

  return (
    <>
      <div className="p-[20px]">
        <p className="text-3xl font-medium text-[#151D48] text-center">
          {props?.data?.heading}
        </p>
        <div className="pt-[26px] pl-[90px]">
          <Button
            handleClick={handleAddClick}
            className="py-4 px-12 ml-[15px] rounded-[10px] text-[#DE1186] px-[20px] text-2xl font-medium bg-white border border-solid border-1 border-[#DE1186]"
            name="YES"
          />
          <Button
            handleClick={handleCancel}
            className="py-4 px-12 ml-[15px] rounded-[10px] text-white px-[20px] text-2xl font-medium bg-[#DE1186]"
            name="NO"
          />
        </div>
      </div>
    </>
  );
};

export default ConfirmationPopup;

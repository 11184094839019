import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { deleteIcon, addMore } from '../../../assets/Common/commonImages';
import 'react-datepicker/dist/react-datepicker.css';
import './Accordion.css';

const DateAccordion = props => {
  const {
    setFollowUpDates,
    followUpDates,
    handleFollowUpdates,
    handleDateChange,
  } = props;
  // const [panels, setPanels] = useState([]);

  const addPanel = () => {
    const lastPanel = followUpDates[followUpDates?.length - 1];
    if (lastPanel?.selectedDate) {
      const _minDate = new Date(moment(lastPanel?.selectedDate).add(1, 'day'));
      handleFollowUpdates({
        id: lastPanel?.id + 1,
        selectedDate: null,
        minDate: _minDate,
      });
    }
  };

  const deletePanel = id => {
    // setPanels(prevPannel => prevPannel.filter(item => item.id != id));
    setFollowUpDates(prevPannel => prevPannel.filter(item => item?.id !== id));
  };

  const dateChangeHandler = (date, panelId) => {
    let _followUpDates = followUpDates.map(panel =>
      panel?.id === panelId
        ? {
            ...panel,
            selectedDate: date,
            minDate: new Date().getTime() + 1 * 24 * 60 * 60 * 1000,
          }
        : panel,
    );
    handleDateChange(_followUpDates);
  };

  return (
    <>
      <div className="accordionContainer mx-2">
        <label className="mt-10 mb-0">Follow Up Date:</label>
        <div className="accordion mt-4 pb-10">
          {followUpDates &&
            followUpDates.map((panel, index) => {
              let _date;
              if (followUpDates[index - 1]?.selectedDate) {
                _date = new Date(
                  new Date(followUpDates[index - 1]?.selectedDate)?.getTime() +
                    1 * 24 * 60 * 60 * 1000,
                );
                if (_date) {
                  panel.minDate = _date;
                }
              }

              return (
                <>
                  <div className="panel">
                    {panel?.id !== 1 ? (
                      <span className={'panelNumber selected '}>
                        {panel?.id}
                      </span>
                    ) : (
                      <span
                        className={'panelNumber selected '}
                        style={{ top: '0px' }}
                      >
                        {panel?.id}
                      </span>
                    )}

                    <div className="panel-content">
                      <DatePicker
                        dateFormat="dd-MMM-yyyy"
                        selected={panel?.selectedDate}
                        onChange={date => dateChangeHandler(date, panel?.id)}
                        minDate={panel?.minDate}
                        placeholderText="DD - MM - YYYY"
                      />
                    </div>
                    {panel?.id !== 1 && (
                      <div className="delete-panel">
                        <button onClick={() => deletePanel(panel.id)}>
                          <img src={deleteIcon} alt="del-btn" />
                        </button>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
        </div>
        <button className="add-panel" onClick={addPanel}>
          <img src={addMore} alt="addmore-btn" /> <p>Add next Follow-up Date</p>
        </button>
      </div>
    </>
  );
};

export default DateAccordion;

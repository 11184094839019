import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { calendarFormSaga } from './saga';

export const initialState = {
  view: 'CALENDAR',
  loading: false,
  trackData: { calendar: [], list: [] },
  reviews: {},
  followUpEvents: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setView(state, action) {
      const view = action.payload;
      state.view = view;
      // state.error = null;
    },
    getTracks(state) {
      state.loading = true;
    },
    gotTracks(state, action) {
      const { data, view, year, selectedYear } = action.payload;
      if (year === selectedYear) {
        state.trackData.calendar = data;
        state.trackData.list = data;
      } else {
        if (view === 'CALENDAR') {
          state.trackData.calendar = data;
        } else {
          state.trackData.list = data;
        }
      }
      state.loading = false;
    },
    // gotListData(state, action) {
    //   const tracksData = action.payload;
    //   state.trackData.list = tracksData;
    //   state.loading = false;
    // },
    // gotTracksError(state, action) {
    //   state.error = action.payload;
    //   state.loading = false;
    // },
    getReviewData(state) {
      state.loading = true;
    },
    gotReviewData(state, action) {
      const { id, assessment } = action.payload;
      // state.reviews[id] = { ...state.reviews[id], assessment };
      // state.loading = false;
      Object.keys(state.reviews).forEach(reviewId => {
        if (reviewId !== id) {
          state.reviews[reviewId] = { assessment: [] };
        }
      });
      state.reviews[id] = { assessment };
      state.loading = false;
    },
    // gotReviewDataError(state, action) {
    //   state.error = action.payload;
    //   state.loading = false;
    // },
    getFollowUpTracks(state) {
      state.loading = true;
    },
    gotFollowUpTracks(state, action) {
      const upcomingEvents = action.payload;
      state.followUpEvents = upcomingEvents;
      state.loading = false;
    },
    removeLoader(state) {
      state.loading = false;
    },
  },
});

export const { actions: calendarActions } = slice;

export const useCalendarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: calendarFormSaga });
  return { actions: slice.actions };
};

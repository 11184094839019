import { createSlice } from '@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { categoryFormSaga } from './saga';

export const initialState = {
  formdata: {
    categoryName: '',
    parameters: [
      {
        name: '',
        criterias: [
          {
            measurementCriteria: '',
            ratings: null,
            commentsCharacterCount: null,
            helpText: '',
            status: [],
          },
        ],
      },
    ],
  },
  currentCategoryDetails: {
    id: '',
    categoryName: '',
    parameters: [
      {
        id: '',
        name: '',
        criterias: [
          {
            id: '',
            measurementCriteria: '',
            ratings: null,
            commentsCharacterCount: null,
            helpText: '',
            status: [],
          },
        ],
      },
    ],
  },
  category: [],
  loading: false,
  error: null,
  categoryCreatedOrUpdated: false,
  cloned: false,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getCategory(state) {
      state.loading = true;
      state.error = null;
    },
    gotCategory(state, action) {
      const categories = action.payload;
      state.category = categories;
      state.loading = false;
    },
    categoryError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getCategoryById(state) {
      state.loading = true;
      state.error = null;
    },
    gotCategoryById(state, action) {
      const category = action.payload;
      state.currentCategoryDetails = category;
      state.loading = false;
    },
    categoryByIdError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    createCategory(state) {
      state.loading = true;
      state.error = null;
    },
    categoryCreated(state, action) {
      const newCategory = action.payload;
      state.category.push(newCategory);
      state.loading = false;
      state.categoryCreatedOrUpdated = true;
    },
    createCategoryError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    updateCategory(state) {
      state.loading = true;
      state.error = null;
    },
    categoryUpdated(state) {
      state.loading = false;
      state.categoryCreatedOrUpdated = true;
    },
    updateCategoryError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    resetCategoryCreatedOrUpdatedState(state) {
      state.categoryCreatedOrUpdated = false;
    },
    deleteCategoryById(state) {
      state.loading = true;
      state.error = null;
    },
    categoryDeleted(state) {
      state.loading = false;
      state.categoryCreatedOrUpdated = true;
    },
    // deleteCategoryError(state, action) {
    //   state.error = action.payload;
    //   state.loading = false;
    // },
    deleteCriteria(state) {
      state.loading = true;
      state.error = null;
    },
    criteriaDeleted(state) {
      state.loading = false;
      state.categoryCreatedOrUpdated = true;
    },
    deleteCategoryParamter(state) {
      state.loading = true;
      state.error = null;
    },
    categoryParameterDeleted(state) {
      state.loading = false;
      state.categoryCreatedOrUpdated = true;
    },
    setLoader(state) {
      state.loading = true;
    },
    removeLoader(state) {
      state.loading = false;
    },
    updateCategoryData(state, action) {
      const category = action.payload;
      state.currentCategoryDetails = category;
      state.loading = false;
    },
    cloneCategoryById(state, action) {
      state.loading = true;
      state.error = null;
    },
    categoryCloned(state, action) {
      state.loading = false;
      state.cloned = true;
    },
    resetCategoryClonedState(state) {
      state.cloned = false;
    },
  },
});

export const { actions: categoryActions } = slice;

export const useCategorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: categoryFormSaga });
  return { actions: slice.actions };
};

import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { trackFormSaga } from './saga';
export const initialState = {
  tracks: [],
  loading: false,
};

const slice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    createTrack(state) {
      state.createTrack = true;
      state.error = null;
    },
    trackCreated(state, action) {
      state.tracks = [...state.tracks, action.payload];
    },
    createTrackError(state, action) {
      state.error = action.payload;
    },
    updateTrack(state) {
      // state.loading = true;
      state.error = null;
    },
    trackUpdated(state) {
      state.loading = false;
      state.trackUpdated = true;
    },
    updateTrackError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: addReviewActions } = slice;

export const useReviewSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: trackFormSaga });
  return { actions: slice.actions };
};

import React from 'react';
import { frame, uparrow, trash } from '../../../assets/Common/commonImages';
import Button from '../Button';

function AccordionItem({
  title,
  children,
  isOpen,
  onToggle,
  onDelete,
  showTrashIcon,
  accordionIndex,
  criteriaValidationResults,
}) {
  const isAccordionValid = Object.keys(criteriaValidationResults)
    .filter(key => key.startsWith(`${accordionIndex}-`))
    .every(key => criteriaValidationResults[key]);
  return (
    <div
      className={`accordion-item ${isOpen ? 'open' : ''} ${
        !isAccordionValid ? 'border-red-500' : ''
      }`}
    >
      <div className="accordion-header" onClick={onToggle}>
        <div className="accordion-title flex justify-between text-[18px] font-semibold leading-11">
          {title}
          <div className="icon-wrap flex">
            {showTrashIcon && (
              <Button
                name=""
                handleClick={onDelete}
                className="trash"
                image={trash}
              />
            )}
            {isOpen ? (
              <img
                src={uparrow}
                alt="Collapse"
                className="arw rounded-full bg-white cursor-pointer p-2"
              />
            ) : (
              <img
                src={frame}
                alt="expand"
                className="arw rounded-full bg-white cursor-pointer p-2 "
              />
            )}
          </div>
        </div>
        <div className="accordion-icon"></div>
      </div>
      <div className={`accordion-content pt-12 ${isOpen ? 'open' : ''}`}>
        {children}
      </div>
    </div>
  );
}

export default AccordionItem;

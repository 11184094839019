import { React, useState, useEffect } from 'react';
import { cloneDeep, findKey } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import RecommendationTable from './RecommendationTable';
import { selectReview } from '../../reviewSlice/selectors';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import Button from 'app/common/components/Button';
import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services';

function RecommmendationView(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const { actions } = useModalManagerSlice();
  const { actions: reviewActions } = useReviewSlice();
  const userResp = useSelector(selectReview);
  const { dataSaved } = userResp;
  const { trackRecommendationData, allReviewsCompleted, endDate } = props;
  const [recommendationData, setRecommendationData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [recommDisabled, setRecommDisabled] = useState({});
  const [generate, setGenerate] = useState(false);

  // Create Reccomendation data Object and disabled Table Object
  useEffect(() => {
    if (
      (recommendationData.length === 0 && trackRecommendationData.length > 0) ||
      dataSaved
    ) {
      let data = [...trackRecommendationData];
      let disabledTableObj = {};
      data = trackRecommendationData?.map(value => {
        let params = cloneDeep(value?.parameters);
        disabledTableObj[value?._id] = true;
        return {
          name: value?.name,
          _id: value?._id,
          parameters: params,
          actionitems: value?.actionitems || [],
        };
      });
      setRecommendationData(data);
      setRecommDisabled(disabledTableObj);
    }
  }, [recommendationData.length, trackRecommendationData, dataSaved]);

  useEffect(() => {
    if (dataSaved === true) dispatch(reviewActions.reverseDataSaved());
  }, [dataSaved, dispatch, reviewActions]);

  const findRowKey = (arrayTofind, rowId, rowIndex) => {
    let key = rowId
      ? findKey(arrayTofind, v => {
          return v._id === rowId;
        })
      : rowIndex;
    return key;
  };

  const deleteInput = (idx, name, rowId, rowIndex, inputRowIdx) => {
    const updatedData = cloneDeep(recommendationData);
    let key = findRowKey(updatedData[idx].parameters, rowId, rowIndex);
    updatedData[idx].parameters[key][name].splice(inputRowIdx, 1);
    setRecommendationData(updatedData);
  };

  //Handle add more on Keyfinding and Recommendation
  const addInput = (idx, name, rowId, rowIndex) => {
    const updatedData = cloneDeep(recommendationData);
    let key = findRowKey(updatedData[idx].parameters, rowId, rowIndex);
    if (name === 'recommendations') {
      updatedData[idx].parameters[key][name].push({
        impact: 'high',
        recommendation: '',
      });
    } else if (name === 'keyfindings') {
      updatedData[idx].parameters[key][name].push({
        finding: '',
        status: 'yes',
      });
    }
    setRecommendationData(updatedData);
  };

  //Add more row in the table
  const addMoreRow = idx => {
    const updatedData = [...recommendationData];
    updatedData[idx].parameters.push({
      parameter: '',
      keyfindings: [],
      recommendations: [],
      remarks: '',
      id: new Date().toString(),
    });
    setRecommendationData(updatedData);
  };

  const sendReport = async () => {
    // setIsDownloading(true);
    if (!endDate) {
      toast.error('End Date cannot be empty!');
      setIsDownloading(false);
      return;
    }

    dispatch(
      actions.openPopUp({
        modalType: 'ReviewCompletion',
        className: `lg:w-4/12`,
        heading: 'Send Report',
        data: { trackId: params?.trackId },
      }),
    );
  };

  //Delete a row or a table
  const deleteRowandTable = (name, idx, rowId, rowIndex) => {
    const updatedData = [...recommendationData];
    if (name === 'row') {
      let key = findRowKey(updatedData[idx].parameters, rowId, rowIndex);
      updatedData[idx].parameters.splice(key, 1);
    } else {
      updatedData.splice(idx, 1);
    }
    setRecommendationData(updatedData);
  };

  //Handle Input change
  const handleInputChange = (
    name,
    tableIdx,
    rowId,
    rowIndex,
    value,
    inputRowIdx,
  ) => {
    const updatedData = [...recommendationData];
    let key = findRowKey(updatedData[tableIdx].parameters, rowId, rowIndex);

    if (name === 'parameter' || name === 'remarks') {
      updatedData[tableIdx].parameters[key][name] = value;
    } else if (name === 'keyfindings') {
      updatedData[tableIdx].parameters[key][name][inputRowIdx]['finding'] =
        value;
    } else {
      updatedData[tableIdx].parameters[key][name][inputRowIdx][
        'recommendation'
      ] = value;
    }
    setRecommendationData(updatedData);
  };

  //Handle Dropdown data Change
  const onDropdownChange = (
    name,
    tableIdx,
    rowId,
    rowIndex,
    inputRowIdx,
    value,
  ) => {
    const updatedData = cloneDeep(recommendationData);
    let key = findRowKey(updatedData[tableIdx].parameters, rowId, rowIndex);
    if (name === 'recommendations') {
      updatedData[tableIdx].parameters[key][name][inputRowIdx].impact = value;
    } else {
      updatedData[tableIdx].parameters[key][name][inputRowIdx].status = value;
    }
    setRecommendationData(updatedData);
  };

  const editHeading = (tableIdx, value) => {
    const updatedData = [...recommendationData];
    updatedData[tableIdx].name = value;
    setRecommendationData(updatedData);
  };

  const generatePdfBlob = async () => {
    const additionalContent = document.getElementById('projectDetails');
    const pdfContent = document.getElementById('pdf-content');

    // Create a temporary div to combine content
    const combinedContent = document.createElement('div');
    combinedContent.style.position = 'absolute';
    combinedContent.style.top = '0';
    combinedContent.style.left = '0';
    combinedContent.style.width = '100%';
    combinedContent.style.zIndex = '-1'; // Ensure it's not visible or affects layout

    // Clone nodes to preserve the original content structure
    combinedContent.appendChild(additionalContent.cloneNode(true));
    combinedContent.appendChild(pdfContent.cloneNode(true));
    document.body.appendChild(combinedContent);

    const canvas = await html2canvas(combinedContent, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    let position = 5;
    let imgHeight = (canvas.height * pdfWidth) / canvas.width;
    let leftHeight = imgHeight;

    while (leftHeight > 0) {
      pdf.addImage(
        imgData,
        'PNG',
        5,
        position,
        pdfWidth - 10,
        0,
        undefined,
        'FAST',
      );

      leftHeight -= pdfHeight;
      position -= pdfHeight;

      if (leftHeight > 0) {
        pdf.addPage();
      }
    }

    document.body.removeChild(combinedContent); // Clean up temporary div

    const pdfBlob = pdf.output('blob');
    return pdfBlob;
  };

  const downloadPdf = async () => {
    setIsDownloading(true);
    setGenerate(true);
    const pdfBlob = await generatePdfBlob();
    setIsDownloading(false);
    setGenerate(false);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = 'report.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const editrecommendationtable = tableId => {
    const updatedDisabledObj = { ...recommDisabled };
    updatedDisabledObj[tableId] = !updatedDisabledObj[tableId];
    setRecommDisabled(updatedDisabledObj);
  };

  return (
    <div className="mt-8">
      {recommendationData.length ? (
        <>
          <div id="pdf-content">
            {recommendationData.map((assmntRecommendationData, idx) => {
              return (
                <div key={assmntRecommendationData?._id || idx}>
                  <RecommendationTable
                    assmntRecommendationData={assmntRecommendationData}
                    recommendationIdx={idx}
                    addMoreInput={addInput}
                    deleteInput={deleteInput}
                    addMoreRow={addMoreRow}
                    deleteRowandTable={deleteRowandTable}
                    handleInputChange={handleInputChange}
                    onDropdownChange={onDropdownChange}
                    editHeading={editHeading}
                    trackId={params?.trackId}
                    setRecommDisabled={setRecommDisabled}
                    recommDisabled={recommDisabled}
                    editrecommendationtable={editrecommendationtable}
                  />
                </div>
              );
            })}
          </div>
          <Permission accessTo={USER_ROLES.ADMIN}>
            <div className="w-full flex justify-end border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10 mt-[12rem]">
              <Button
                handleClick={() => {
                  sendReport('DRAFT');
                }}
                name="Send Report"
                className={`text-[#de1186] py-4 px-12 font-medium text-xl rounded-xl mt-8 mr-4 ${
                  allReviewsCompleted
                    ? 'border border-solid border-[#de1186] '
                    : 'bg-gray-300 text-gray-900 opacity-70'
                } mt-12 py-[13px] px-[30px]`}
                disabled={!allReviewsCompleted ? 'disabled' : ''}
              />
              <button
                className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl mt-8 ml-4"
                onClick={downloadPdf}
                disabled={isDownloading}
              >
                Generate Report
              </button>
              {generate && (
                <div className="ml-4 mt-10 ">
                  <RotatingLines
                    height="30"
                    width="30"
                    color="#0000FF"
                    strokeWidth="5"
                    strokeColor="#f6007e"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                  />
                  <span className="ml-2">Generating...</span>
                </div>
              )}
            </div>
          </Permission>
        </>
      ) : null}
    </div>
  );
}

export default RecommmendationView;

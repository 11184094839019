import styled from 'styled-components';

export const CreatenewCard = styled.div`
  /* width: calc(25% - 16px); */
  /* min-width: 260px;
  max-width: 320px; */
  /* height: 220px; */
  border-radius: 16px;
  border: 1px dashed #de1186;
  /* margin-right: 32px;
  margin-bottom: 32px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  img {
    width: 53.333px;
    /* margin-top: 60px; */
    margin-left: 43px;
    margin-bottom: 11px;
    height: 52.174px;
    flex-shrink: 0;
    cursor: pointer;
  }

  span {
    flex-grow: 1;
    color: #de1186;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    cursor: pointer;
  }
`;

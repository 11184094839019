import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { commonSaga } from './saga';

export const initialState = {
  error: {},
  loading: false,
  data: {},
};

const slice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setLoader(state) {
      state.loading = true;
    },
    removeLoader(state) {
      state.loading = false;
    },
    setCommonData(state, action) {
      const data = { ...action.payload };
      state.data = { ...state.data, ...data };
    },
    setError() {},
    setErrorData(state, action) {
      state.error = { ...action.payload };
      // state.loading = false;
    },
  },
});

export const { actions: commonActions } = slice;

export const useCommonSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: commonSaga });
  return { actions: slice.actions };
};

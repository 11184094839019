import { React, useRef, useEffect } from 'react';

function EditableTextarea(props) {
  const {
    placeholder,
    text,
    heading,
    value,
    maxLength,
    label,
    name,
    onInputChange,
    calledFrom,
    actionDisabled,
    // required = false,
    disabled,
  } = props;
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current.value) {
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    }
  }, []);

  return (
    <div className="w-[100%]  contenteditable-container">
      <div
        ref={textareaRef}
        contentEditable={!disabled}
        placeholder={placeholder}
        type={text}
        name={heading}
        id={name}
        // defaultValue={value}
        label={label}
        maxLength={maxLength}
        disabled={calledFrom === 'actionTable' ? actionDisabled : disabled}
        className={
          disabled
            ? 'resize-none py-4 px-5 w-full min-h-[5rem] border border-[#b1b2b5] rounded-2xl bg-[#f9fafb] content contentEditable-div-disabled'
            : `resize-none py-4 px-5 w-full min-h-[5rem] border border-[#b1b2b5] ${
                name !== 'notes_textarea' && name !== 'learnnings_textarea'
                  ? 'border-dashed '
                  : ''
              } rounded-2xl outline-none bg-white contentEditable-div text-left cursor-text`
        }
        onBlur={event => {
          onInputChange(event);
        }}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  );
}

export default EditableTextarea;

import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ReactTags } from 'react-tag-autocomplete';
import { toast } from 'react-toastify';
import Input from 'app/common/components/Input/input';
import Dropdown from 'app/common/components/DropDown';
import Button from 'app/common/components/Button';
import { trash, error } from 'app/assets/Common/commonImages';
import { suggestionsStatusCriteria } from 'app/common/constants/constants';
import 'app/common/components/ReactTagInput/style.css';
import '../Criteria/style.css';

// import MultiSelectDropdown from '../common/multiselect/multislect';

const criteriaValidationSchema = Yup.object().shape({
  measurementCriteria: Yup.string().required(
    'Measurement Criteria is required',
  ),
  ratings: Yup.string().required('Select Weightage'),
});

function Criteria({
  accordionIndex,
  deleteCriteria,
  criteriaIndex,
  onCriteriaChange,
  onRatingsChange,
  onStatusChange,
  ratings,
  onValidationComplete,
  isSubmitting,
  criteria,
  categoryId,
}) {
  const {
    measurementCriteria,
    ratings: selectedRating,
    status,
    helpText,
  } = criteria;
  const counts = criteriaIndex + 1;
  // const [statusTags, setStatusTags] = useState([]);
  const [errors, setErrors] = useState({
    measurementCriteria: '',
    ratings: '',
  });
  const [selectedOptions, setSelectedOption] = useState(selectedRating);
  const [statusTags, setStatusTags] = useState([]);

  /*Clearing validation error */
  const clearError = field => {
    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: '',
    }));
  };
  const validateCriteria = async () => {
    if (isSubmitting) {
      try {
        await criteriaValidationSchema.validate(
          {
            measurementCriteria,
            ratings: selectedRating,
            status,
          },
          { abortEarly: false },
        );
        setErrors({
          measurementCriteria: '',
          ratings: '',
        });
        onValidationComplete(true);
      } catch (validationErrors) {
        const errorsMap = {
          measurementCriteria: '',
          ratings: '',
        };
        validationErrors.inner.forEach(error => {
          errorsMap[error.path] = error.message;
        });
        setErrors(errorsMap);
        onValidationComplete(false);
      }
    }
  };

  useEffect(() => {
    if (status) {
      setStatusTags(status);
    }
  }, [status]);

  /* Checking vaidation on submit */
  useEffect(() => {
    validateCriteria();
  }, [isSubmitting, measurementCriteria, selectedRating, status]);

  const handleStatusTagDelete = index => {
    const updatedStatusValues = [...statusTags];
    updatedStatusValues.splice(index, 1);
    setStatusTags(updatedStatusValues);

    onStatusChange(updatedStatusValues, accordionIndex, criteriaIndex);
  };

  const handleStatusTagAddition = tag => {
    if (statusTags.length < 3) {
      const updatedStatusValues = [...statusTags, tag];
      setStatusTags(updatedStatusValues);

      onStatusChange(updatedStatusValues, accordionIndex, criteriaIndex);
    } else {
      toast.error('Only three status allowed');
    }
  };
  /*Function handling rating dropdown selection */
  const handleSelects = option => {
    setSelectedOption(option);
    onRatingsChange(
      option === 'N/A' ? 0 : option,
      accordionIndex,
      criteriaIndex,
    );
    clearError('ratings');
  };

  // const handleStatusChange = updatedStatusValues => {
  //   onStatusChange(
  //     updatedStatusValues.map(option => option.value),
  //     accordionIndex,
  //     criteriaIndex,
  //   );
  // };

  useEffect(() => {
    setSelectedOption(selectedRating);
  }, [selectedRating]);

  return (
    <>
      <div className="accordion-data mx-6 pr-5 pb-6 pl-14 pt-8 relative border-l border-solid border-1 border-[#73779157]">
        <div className="mb-8">
          <div className="label-wrap flex relative justify-between">
            <label className="label-category"> Measurement Criteria</label>
            {counts > 1 && (
              <Button
                name=""
                handleClick={
                  () =>
                    deleteCriteria(
                      accordionIndex,
                      criteriaIndex,
                      criteria?.id,
                      categoryId,
                    )
                  // deleteCriteria(criteria?.id)
                }
                className="trash-criteria"
                image={trash}
              />
            )}
          </div>
          <Input
            label=""
            type="text"
            placeHolder="Enter Here"
            value={measurementCriteria}
            onChange={value => {
              onCriteriaChange(
                value,
                accordionIndex,
                criteriaIndex,
                'measurementCriteria',
              );
              clearError('measurementCriteria');
            }}
            className={`input-criteria rounded-[4px] w-full outline-none py-[10px] pl-4 text-[#151D48] border border-solid border-1 border-[#737791] ${
              errors.measurementCriteria ? 'border-red-500' : ''
            }`}
          ></Input>
          {errors.measurementCriteria && (
            <div className="error-message flex text-red-500">
              <img className="pr-[10px]" src={error} alt="error-img" />
              {errors.measurementCriteria}
            </div>
          )}
          <span className="custom absolute left-[-13px] top-8 text-center rounded-full p-3 leading-[0.6] bg-[#F2F1FB] border border-solid border-1 border-[#151D48]">
            {counts}
          </span>
        </div>
        <div className="mb-8">
          <label className="label-category">Help Text</label>
          {/* <div className="label-wrap flex relative justify-between">
            <label className="label-category"> Measurement Criteria</label>
            
          </div> */}
          <Input
            label=""
            type="text"
            placeHolder="Enter Here"
            value={helpText}
            onChange={value => {
              onCriteriaChange(
                value,
                accordionIndex,
                criteriaIndex,
                'helpText',
              );
            }}
            className={`input-criteria rounded-[4px] w-full outline-none py-[10px] pl-4 text-[#151D48] border border-solid border-1 border-[#737791]`}
          ></Input>
        </div>

        <div className="lg:flex sm:block ">
          <div>
            <p className="label-category"> Weightage</p>
            <Dropdown
              options={ratings}
              onSelect={handleSelects}
              selectedOption={
                selectedOptions === 0
                  ? 'N/A'
                  : selectedOptions === null
                  ? ''
                  : String(selectedOptions)
              }
              selectedOptionStyle={{ padding: '10px' }}
              className={`drp-2 w-[83px] border border-solid border-1 border-[#737791] rounded-[4px] ${
                errors.ratings ? 'border-red-500' : ''
              }`}
              optionsClassName="custom-options2"
              placeholder="Select"
            />
            {errors.ratings && (
              <div className="error-message flex text-red-500">
                <img className="pr-[10px]" src={error} alt="error-img" />
                {errors.ratings}
              </div>
            )}
          </div>
          <div className="w-full ml-24">
            <p className="label-category"> Status</p>
            {/* <MultiSelectDropdown
              options={suggestionsStatusCriteria}
              className="drp-1 relative py-[5px] pl-5 pr-6 rounded-[4px] border border-solid border-1 border-[#737791] w-full md:w-full"
              optionsClassName="custom-options1 max-h-[250px]"
              value={status}
              defaultSelected={[
                { value: 'COMPLIANT', label: 'Compliant' },
                { value: 'PARTIALLY_COMPLIANT', label: 'Partially Compliant' },
                { value: 'NOT_COMPLIANT', label: 'Not Compliant' },
              ]}
              onSelect={handleStatusChange}
              placeholder="Select Status"
            /> */}
            {/* <div>
              {Array.from({ length: 3 }, (_, statusIndex) => (
                <Input
                  label=""
                  key={statusIndex}
                  type="text"
                  placeHolder="Enter Here"
                  value={status[statusIndex]}
                  onChange={e => {
                    onStatusChange(
                      e.target.value,
                      statusIndex,
                      accordionIndex,
                      criteriaIndex,
                    );
                  }}
                  className="input-caracter py-[10px] pl-4 rounded-[4px] w-full lg:w-[158px] outline-none text-[#151D48] border border-solid border-1 border-[#737791] mr-2"
                />
              ))}
            </div> */}
            <ReactTags
              selected={statusTags}
              suggestions={suggestionsStatusCriteria}
              onDelete={index => handleStatusTagDelete(index)}
              onAdd={tag => handleStatusTagAddition(tag)}
              placeholderText=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Criteria;

import { createSlice } from '@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { projectReviewFormSaga } from './saga';

export const initialState = {
  loading: false,
  error: null,
  assessment: {},
};

const slice = createSlice({
  name: 'projectReview',
  initialState,
  reducers: {
    getProjectReview(state) {
      state.loading = true;
      state.error = null;
    },
    gotProjectReview(state, action) {
      const assessment = action.payload;
      state.assessment = assessment;
      state.loading = false;
    },
    getProjectReviewError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    createProjectReview(state) {
      state.loading = true;
      state.error = null;
    },
    projectReviewCreated(state, action) {
      const assessment = action.payload;
      state.assessment = assessment;
      state.assessmentId = assessment.id;
      state.loading = false;
    },
    createProjectReviewError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    updateProjectReview(state) {
      state.loading = true;
      state.error = null;
    },
    projectReviewUpdated(state, action) {
      const assessment = action.payload;
      state.assessment = assessment;
      state.loading = false;
      if (assessment.status === 'COMPLETED') {
        state.assessmentComplete = true;
      }
    },
    projectReviewError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    clearProjectReviewData(state) {
      state.assessmentComplete = false;
      state.error = null;
    },
    clearAssessmentId(state) {
      state.assessmentId = '';
      // state.assessment = {};
    },
    deleteAssessment() {},
  },
});

export const { actions: projectReviewActions } = slice;

export const useProjectReviewSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: projectReviewFormSaga });
  return { actions: slice.actions };
};

import * as React from 'react';
import PropTypes from 'prop-types';

function Button(props) {
  const {
    name,
    value,
    className = '',
    handleClick,
    image,
    altText,
    disabled = '',
    title,
    id,
  } = props;
  return (
    <button
      type="button"
      onClick={event => handleClick(event)}
      value={value}
      name={name}
      className={className}
      disabled={disabled}
      title={title || name}
      id={id}
    >
      {image && <img src={image} alt={altText || 'Button'} />}
      {name}
    </button>
  );
}

export default Button;

Button.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  image: PropTypes.string,
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { commonActions } from 'app/common/slices/errorBoundarySlice/selectors';
import { useCommonSlice } from 'app/common/slices/errorBoundarySlice';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useCategorySlice } from 'app/pages/Setup/components/SetupForm/categoryslice';
import { API_CONSTANTS, API_ERROR_CODES } from 'app/common/constants/constants';
import { errorImg, infoImg } from 'app/assets/Common/commonImages';
const ErrorPopup = props => {
  const dispatch = useDispatch();
  const { actions } = useModalManagerSlice();
  const { actions: categoryActions } = useCategorySlice();
  const { actions: commonAction } = useCommonSlice();
  const commonAuth = useSelector(commonActions);
  const {
    error: { responseBody, categoryId },
  } = commonAuth;
  const url = `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.LOGIN_AUTH}`;

  const cloneCategory = () => {
    try {
      dispatch(categoryActions.cloneCategoryById(categoryId));
      dispatch(actions.closePopUp());
      dispatch(commonAction.setErrorData({}));
    } catch (e) {}
  };

  const handleLogin = () => {
    window.location.href = '/login';
  };

  const closePopup = () => {
    try {
      dispatch(actions.closePopUp());
      dispatch(commonAction.setErrorData({}));
    } catch (e) {}
  };

  return (
    <>
      <div className="flex flex-col w-[550px]">
        <div className="flex border-b pb-7">
          {responseBody?.status === 403 &&
          (responseBody?.name === API_ERROR_CODES.CategoryUpdateNotAllowed ||
            responseBody?.name === API_ERROR_CODES.CategoryDeleteNotAllowed ||
            responseBody?.name === API_ERROR_CODES.TrackDeleteNotAllowed) ? (
            <>
              <img src={infoImg} alt="info" className="h-10 mr-2" />
              <h2 className="font-bold text-3xl ml-1">
                Category already aligned to an assessment
              </h2>
            </>
          ) : (
            <>
              <img src={errorImg} alt="info" className="h-10 mr-2" />
              <h2 className="font-bold text-3xl ml-1">Info</h2>
            </>
          )}
        </div>
        <div className="pt-6">
          {responseBody?.message ? (
            <div>{responseBody?.message}</div>
          ) : (
            <div>You are unauthorized. Please login</div>
          )}
          <div className="w-full flex justify-end mt-12">
            {responseBody?.status === 401 ? (
              <button
                className="py-4 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] w-4/12"
                onClick={handleLogin}
              >
                Login
              </button>
            ) : (
              <>
                {responseBody?.name ===
                API_ERROR_CODES.CategoryUpdateNotAllowed ? (
                  <button
                    className="mr-4 py-4 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] w-4/8"
                    onClick={cloneCategory}
                  >
                    Create Copy
                  </button>
                ) : null}
                <button
                  className="py-4 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] w-4/16"
                  onClick={closePopup}
                >
                  Ok
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPopup;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
    margin: 0;
    padding: 0;
  }
  html,
  body,
  .app {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .popup_inner {
    margin: auto;
    background: white;
  }

`, "",{"version":3,"sources":["webpack://./src/app/common/components/Modals/ModalManager/styles.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;EACZ;EACA;;;IAGE,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,WAAW;IACX,aAAa;EACf;EACA;IACE,eAAe;IACf,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;EACA;IACE,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":["h1 {\n    margin: 0;\n    padding: 0;\n  }\n  html,\n  body,\n  .app {\n    margin: 0;\n    padding: 0;\n    position: relative;\n    width: 100%;\n    height: 100vh;\n  }\n  .popup {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    margin: auto;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 999;\n  }\n  .popup_inner {\n    margin: auto;\n    background: white;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

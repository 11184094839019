import React from 'react';
import { createNew } from 'app/assets/Common/commonImages';
import { Link } from 'react-router-dom';
import { CreatenewCard } from './style';

const CreateNewCard = () => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <CreatenewCard>
      <Link
        to="/setup/create"
        className="no-underline"
        onClick={handleLinkClick}
      >
        <img src={createNew} alt="Create New" />
        <span>CREATE NEW</span>
      </Link>
    </CreatenewCard>
  );
};

export default CreateNewCard;

import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { request } from 'app/common/utils/request';
import { projectReviewActions as actions } from '.';
import { reviewActions } from '../../../../reviewSlice';

export function* getProjectReview(action) {
  yield delay(500);
  const { assessmentId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/assessment/${assessmentId}`;
  try {
    const projectReview = yield call(request, requestURL);
    yield put(actions.gotProjectReview(projectReview));
    yield put(actions.clearAssessmentId());
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.getProjectReviewError('PROJECTREVIEW NOT FOUND'));
      toast.error('Something went wrong');
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.getProjectReviewError('RATE LIMIT'));
      toast.error('Something went wrong');
    } else {
      yield put(actions.getProjectReviewError('RESPONSE ERROR'));
      toast.error('Something went wrong');
    }
  }
}

export function* createProjectReview(action) {
  yield delay(500);
  const { categoryId, trackId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/assessment/create/${trackId}/${categoryId}`;
  try {
    const projectReview = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(actions.projectReviewCreated(projectReview));
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.createProjectReviewError('PROJECTREVIEW NOT FOUND'));
      toast.error('Something went wrong');
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.createProjectReviewError('RATE LIMIT'));
      toast.error('Something went wrong');
    } else {
      yield put(actions.createProjectReviewError('RESPONSE ERROR'));
      toast.error('Something went wrong');
    }
  }
}

export function* updateProjectReview(action) {
  yield delay(500);
  const assessmentData = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/assessment/update/${assessmentData.id}`;
  try {
    const projectReview = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(assessmentData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(actions.projectReviewUpdated(projectReview));
    assessmentData.status === 'IN_PROGRESS'
      ? toast.success('Assessment drafted succesfully')
      : toast.success('Assessment submitted succesfully');
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.projectReviewError('PROJECTREVIEW NOT FOUND'));
      toast.error('Assessment data not found');
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.projectReviewError('RATE LIMIT'));
      toast.error('Something went wrong');
    } else {
      yield put(actions.projectReviewError('RESPONSE ERROR'));
      toast.error('Something went wrong');
    }
  }
}

export function* clearProjectReviewData() {
  yield delay(300);
}

export function* clearAssessmentId() {
  yield delay(300);
}

export function* deleteAssessment(action) {
  yield delay(500);
  const assessmentId = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/assessment/${assessmentId}`;
  try {
    yield put(reviewActions.setLoader());
    const assessmentData = yield call(request, requestURL, {
      method: 'DELETE',
    });
    yield put(reviewActions.updateAssessmentList(assessmentData));
    toast.success('Assessment deleted succesfully');
  } catch (err) {
    yield put(reviewActions.removeLoader());
    if (err.response?.status === 404) {
      toast.error('Assessment delete api not found');
    } else if (err.message === 'Failed to fetch') {
      toast.error('Rate limit');
    } else {
      toast.error('Response error');
    }
  }
}

export function* projectReviewFormSaga() {
  yield takeLatest(actions.getProjectReview, getProjectReview);
  yield takeLatest(actions.createProjectReview, createProjectReview);
  yield takeLatest(actions.updateProjectReview, updateProjectReview);
  yield takeLatest(actions.clearProjectReviewData, clearProjectReviewData);
  yield takeLatest(actions.deleteAssessment, deleteAssessment);
  yield takeLatest(actions.clearAssessmentId, clearAssessmentId);
}

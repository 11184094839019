import React, { useState } from 'react';
import AccordionItem from './accordionItem';
import Button from '../Button';
import { Sign } from '../../../assets/Common/commonImages';

function Accordion({
  label,
  items,
  onAddAccordion,
  onDeleteAccordion,
  isAccordionValid,
  criteriaValidationResults,
}) {
  const [openItems, setOpenItems] = useState(0);

  const handleToggle = index => {
    setOpenItems(prevIndex => (prevIndex === index ? -1 : index));
  };
  return (
    <div className="accordion mt-16">
      <div className="label-name">{label}</div>
      {items?.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          isOpen={openItems === index}
          onToggle={() => handleToggle(index)}
          onDelete={() => onDeleteAccordion(index, item?.id, item?.categoryId)}
          showTrashIcon={items.length > 1}
          isAccordionValid={isAccordionValid}
          criteriaValidationResults={criteriaValidationResults}
          accordionIndex={index}
        >
          {item.children}
        </AccordionItem>
      ))}
      <Button
        handleClick={onAddAccordion}
        name="Add New Parameter"
        value="parameter"
        className="add-btn flex justify-center gap-3 rounded-[8px] font-medium text-[16px] text-[#DE1186] tracking-wide mt-16 border border-solid border-1 border-[#DE1186] py-5 px-9"
        image={Sign}
      />
    </div>
  );
}

export default Accordion;

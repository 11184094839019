import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
// import { useCalendarSlice } from 'app/calendarslice';
import { selectCalendar } from 'app/pages/DashboardCalendar/calendarslice/selectors';

import Table from 'app/common/components/Table/table';
import { tableview } from 'app/assets/Common/commonImages';
import { TRACK_STATUS_NEW } from 'app/common/constants/constants';

function CalendarTrackList({ selectedStatus }) {
  const [listData, setListData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const timelineReview = useSelector(selectCalendar);

  useEffect(() => {
    let listData = timelineReview?.trackData?.list || [];
    if (selectedStatus !== 'ALL') {
      listData = timelineReview?.trackData?.list.filter(data => {
        return data.reviewStatus === selectedStatus;
      });
    }
    const data = listData
      .map((item, index) => ({
        id: index + 1,
        accountName: item?.businessUnit?.businessUnitName || '',
        projectName: item?.project?.projectName || '',
        trackName: item?.trackName || '-',
        totalDays: item?.totalDays > 5 ? item?.totalDays : '-',
        startDate: item?.startDate || item?.createdAt,
        endDate:
          item?.reviewStatus === 'COMPLETED' && item?.endDate
            ? new Date(item?.endDate).toLocaleString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
            : item?.reviewStatus || '-',
        // view: '-',
        trackId: item?.id,
        _id: index,
      }))
      .sort(
        (recentDate, oldDate) =>
          new Date(oldDate.startDate) - new Date(recentDate.startDate),
      );
    setListData(data);
  }, [timelineReview?.trackData?.list, selectedStatus]);

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'accountName',
      text: 'Business Unit',
    },
    {
      dataField: 'projectName',
      text: 'Project',
    },
    {
      dataField: 'trackName',
      text: 'Track',
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      formatter: (cell, row, rowIndex) =>
        cell
          ? new Date(cell).toLocaleString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : '-',
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      formatter: (cell, row, rowIndex) => {
        const status = row.status || row.endDate;

        if (status === 'COMPLETED' && row.endDate) {
          return new Date(row.endDate).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          });
        } else if (status === 'IN_PROGRESS') {
          return (
            <div
              className={`rounded-md border w-fit py-1.5 px-4 text-center font-sans text-sm font-normal border-[#eec700] text-[#ac9000] bg-[#F8F8F8]`}
            >
              {
                TRACK_STATUS_NEW.find(statusObj => statusObj.value === status)
                  ?.label
              }
            </div>
          );
        } else {
          return (
            <div>
              <span
                className={`${
                  TRACK_STATUS_NEW.find(statusObj => statusObj.value === status)
                    ?.label
                    ? 'rounded-md border w-fit py-1.5 px-4 text-center font-sans text-sm font-normal border-[#F28300] text-[#F28300] bg-[#F8F8F8]'
                    : ''
                }`}
              >
                {TRACK_STATUS_NEW.find(statusObj => statusObj.value === status)
                  ?.label ||
                  row.endDate ||
                  '-'}
              </span>
            </div>
          );
        }
      },
    },
    {
      dataField: 'totalDays',
      text: 'Total Days',
    },
    {
      dataField: 'categories',
      text: 'Review Type',
      formatter: (cell, row) => {
        return row.endDate === 'YET_TO_START' ? (
          '-'
        ) : (
          <button
            className="cursor-pointer	text-blue-500 hover:underline"
            onClick={() => handleViewAssessments(row)}
          >
            View
          </button>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => (
        <img
          src={tableview}
          alt="view"
          className="cursor-pointer pl-[12px]	"
          onClick={() => handleRedirect(row)}
        />
      ),
    },
  ];

  // const dataObject = {
  //   trackId: ,
  //   timelineData: ,
  // };

  //   let data = [
  //     {
  //       bu: '',
  //       project: '',
  //       track: '-',
  //       startDate: new Date().toISOString(),
  //       endDate: '',
  //       _id: 0,
  //     },
  //   ];

  function handleViewAssessments(row) {
    try {
      // dispatch(actions.getReviewData({ id: row.trackId }));
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'ShowAssessments',
          modalClassName: `w-2/4`,
          data: {
            trackId: row?.trackId,
            projectName: row?.projectName,
            trackName: row?.trackName,
          },
        }),
      );
    } catch (e) {}
  }

  function handleRedirect(row) {
    try {
      navigate(`/review/track/${row.trackId}`);
    } catch (e) {}
  }

  return (
    <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10">
      <Table
        data={listData}
        columns={columns}
        classcategory={'tablereviewpage'}
      />
    </div>
  );
}

export default CalendarTrackList;

import React from 'react';
import { useDispatch } from 'react-redux';
import { usePermissionSlice } from '../permissionSlice';
import Button from 'app/common/components/Button';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { USER_ROLES } from 'app/common/services';
import { useReviewSlice } from 'app/pages/Review/reviewSlice';

function ConfirmationPopup(props) {
  const { actions } = usePermissionSlice();
  const { actions: reviewActions } = useReviewSlice();
  // const { actions } = useReviewSlice();
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();

  const userIds = props.data.roleId;
  const trackId = props.data.trackId;
  const id = props.data.id;

  const handleAddClick = () => {
    if (userIds) {
      dispatch(actions.createRoles({ userIds, role: USER_ROLES.NO_ACCESS }));
    } else {
      dispatch(
        reviewActions.deleteRecommendation({
          trackId,
          id,
        }),
      );
    }

    dispatch(modalManagerActions.closePopUp());
  };

  const handleCancel = () => {
    dispatch(modalManagerActions.closePopUp());
  };

  return (
    <>
      <div className="p-[20px]">
        <p className="text-3xl font-medium text-[#151D48] text-center">
          Are you really want to remove <br />"{props.data.name}"{''} ?
        </p>
        <div className="pt-[26px] pl-[90px]">
          <Button
            handleClick={handleAddClick}
            className="py-4 px-12 ml-[15px] rounded-[10px] text-[#DE1186] px-[20px] text-2xl font-medium bg-white border border-solid border-1 border-[#DE1186]"
            name="YES"
          />
          <Button
            handleClick={handleCancel}
            className="py-4 px-12 ml-[15px] rounded-[10px] text-white px-[20px] text-2xl font-medium bg-[#DE1186]"
            name="NO"
          />
        </div>
      </div>
    </>
  );
}

export default ConfirmationPopup;

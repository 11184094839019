import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ActiveName, NavLinkContainer, PageIcon } from './style';

function NavLink(props) {
  const { pageIcon, activePageIcon, pageName, pageLink, sidebarOpen, title } =
    props;
  const navigate = useNavigate();
  const location = useLocation();

  const activePage =
    (pageLink !== '/' && location.pathname.includes(pageLink)) ||
    (pageLink === '/' && location.pathname === pageLink);
  return (
    <>
      <NavLinkContainer
        active={activePage}
        disabled={pageName === 'Settings'}
        onClick={() => navigate(pageLink)}
      >
        <PageIcon
          style={{ marginRight: sidebarOpen ? '0' : '20px' }}
          title={title}
        >
          {activePage && activePageIcon ? activePageIcon : pageIcon}
        </PageIcon>
        <ActiveName active={activePage}>{pageName}</ActiveName>
      </NavLinkContainer>
    </>
  );
}

export default NavLink;

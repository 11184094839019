import { createSlice } from '@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { reviewSaga } from './saga';

export const initialState = {
  businessUnits: [],
  businessUnitDetails: {},
  projectAccountDetails: {},
  projectReviewDetails: {},
  projectCategoryDetails: [],
  loading: false,
  error: null,
  projectNameList: {},
  selectedProject: {},
  categories: [],
  assessment: [],
  recommendationData: [],
  tabelDisabled: true,
  loaderObj: {},
  actionLoaderObj: {},
  finalReviewProject: false,
  finalReviewNotes: false,
  editMode: {},
  tempRecommData: [],
  dataSaved: false,
};

const slice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    getReviewCategory(state) {
      state.loading = true;
    },
    gotReviewCategory(state, action) {
      const businessUnits = action.payload;
      state.businessUnits = businessUnits;
      state.loading = false;
    },
    getBusinessUnitDetails(state) {
      state.loading = true;
    },
    gotBusinessUnitDetails(state, action) {
      const { details } = action.payload;
      state.businessUnitDetails = details;
      state.loading = false;
    },
    getProjectAccountDetails(state) {
      state.loading = true;
      state.error = null;
    },
    gotProjectAccountDetails(state, action) {
      const { details } = action.payload;
      state.projectAccountDetails = details;
      state.loading = false;
    },
    getProjectReviewDetails(state) {
      state.loading = true;
    },
    gotProjectReviewDetails(state, action) {
      const { details, selectedProject } = action.payload;
      state.projectReviewDetails[selectedProject.id] = details;
      state.selectedProject = selectedProject;
      state.loading = false;
    },
    appendProjectReviewTrack(state, action) {
      state.projectReviewDetails[state.selectedProject.id].tracks = [
        action.payload,
        ...state.projectReviewDetails[state.selectedProject.id].tracks,
      ];
      state.loading = false;
    },
    getProjectCategoryDetails(state) {
      state.loading = true;
    },
    gotProjectCategoryDetails(state, action) {
      const { details } = action.payload;
      state.projectCategoryDetails = details;
      state.loading = false;
    },
    deleteReviewsTrack() {
      // state.loading = true;
    },

    reviewTrackDeleted(state, action) {
      const { projectId, trackId } = action.payload;
      state.projectReviewDetails[projectId].tracks = state.projectReviewDetails[
        projectId
      ].tracks.filter(review => review.id !== trackId);
      state.loading = false;
    },
    getReviewCategorys(state) {
      state.loading = true;
    },
    gotReviewCategorys(state, action) {
      const category = action.payload;
      state.categories = category;
      state.loading = false;
    },
    setLoader(state) {
      state.loading = true;
    },
    removeLoader(state) {
      state.loading = false;
    },
    updateTrackData(state, action) {
      const { project, trackId, trackData } = action.payload;
      const idx = Object.values(
        state.projectReviewDetails[project._id].tracks,
      ).findIndex(rev => rev?.id === trackId);
      state.projectReviewDetails[project._id]['tracks'][idx] = trackData;
      state.loading = false;
    },
    getTrackAssessmentData(state) {
      state.loading = true;
    },
    gotAssessmentData(state, action) {
      const { id, assessmentDetails } = action.payload;
      state.assessment = { ...state.assessment[id], assessmentDetails };
      state.loading = false;
    },
    updateAssessment(state, action) {
      state.error = action.payload;
      if (action.payload.id === 'notes') {
        state.finalReviewNotes = true;
      } else if (action.payload.id === 'project') {
        state.finalReviewProject = true;
      } else state.loading = true;
    },
    updateAssessmentList(state, action) {
      state.assessment = {
        ...state.assessment,
        assessmentDetails: action.payload,
      };
      state.loading = false;
      state.finalReviewNotes = false;
      state.finalReviewProject = false;
    },
    clearProjectAccountDetails(state) {
      state.projectAccountDetails = {};
    },
    generateRecommendation(state) {
      state.loading = true;
      state.error = null;
    },

    recommendationGenerated(state, action) {
      state.recommendationData.push(action.payload);
      state.loading = false;
    },

    recommendationError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getRecommendations(state) {
      state.loading = true;
    },
    gotRecommendations(state, action) {
      state.recommendationData = action.payload;
      state.loading = false;
    },

    getRecommendationData(state, action) {
      const { recommendationId } = action.payload;
      const loaderObj = { ...state.loaderObj };
      loaderObj[recommendationId] = true;
      state.loaderObj = loaderObj;
      state.error = null;
    },

    gotRecommendationData(state, action) {
      const updatedRecommendationIndex = state.recommendationData.findIndex(
        recommendation => recommendation._id === action.payload._id,
      );
      if (updatedRecommendationIndex !== -1) {
        state.recommendationData[updatedRecommendationIndex] = action.payload;
      }
      state.loaderObj = {};
      state.editMode = {};
      state.tempRecommData = [];
      state.dataSaved = true;
    },

    reverseDataSaved(state) {
      state.dataSaved = false;
    },

    deleteRecommendation(state) {
      state.loading = true;
      state.error = null;
    },

    recommendationDeleted(state, action) {
      const { id } = action.payload;
      state.recommendationData = state.recommendationData.filter(
        recommendation => recommendation._id !== id,
      );
      state.loading = false;
      state.error = null;
    },
    deleteRecommendationData(state, action) {
      const { recommTableId } = action.payload;
      const loaderObj = { ...state.loaderObj };
      loaderObj[recommTableId] = true;
      state.loaderObj = loaderObj;
      // state.loading = true;
      state.error = null;
      state.editMode[recommTableId] = true;
      // state.tempRecommData = assmntRecommendationData;
    },

    keyfindingsDeleted(state, action) {
      const {
        // trackId,
        idToDelete: keyFindingId,
        assmntRecommendationData,
        recommTableId,
      } = action.payload;
      const recommData = [...state.recommendationData];
      state.tempRecommData = recommData;
      const data = { ...assmntRecommendationData };
      const recommendationIndex = state.recommendationData.findIndex(
        recommendation => recommendation._id === recommTableId,
      );
      if (
        recommendationIndex !== -1 &&
        state.recommendationData[recommendationIndex]._id ===
          assmntRecommendationData._id
      ) {
        data.parameters.forEach(parameter => {
          parameter.keyfindings = parameter.keyfindings.filter(
            keyfinding => keyfinding._id !== keyFindingId,
          );
        });
      }
      state.tempRecommData[recommendationIndex] = data;
      state.dataSaved = true;
      state.loaderObj = {};
      state.error = null;
    },

    deleteRecomendationParameter(state) {
      state.loaderObj = {};
      state.error = null;
    },

    parameterRecomendationDeleted(state, action) {
      const {
        // trackId,
        idToDelete,
        assmntRecommendationData,
        recommTableId,
        type,
      } = action.payload;
      const recommData = [...state.recommendationData];
      state.tempRecommData = recommData;
      const data = { ...assmntRecommendationData };
      const recommendationIndex = state.recommendationData.findIndex(
        recommendation => recommendation._id === recommTableId,
      );
      if (
        recommendationIndex !== -1 &&
        state.recommendationData[recommendationIndex]._id ===
          assmntRecommendationData._id &&
        type === 'recommendations'
      ) {
        data.parameters.forEach(parameter => {
          parameter.recommendations = parameter.recommendations.filter(
            recommendations => recommendations._id !== idToDelete,
          );
        });
        state.tempRecommData[recommendationIndex] = data;
      }
      if (
        recommendationIndex !== -1 &&
        state.recommendationData[recommendationIndex]._id ===
          assmntRecommendationData._id &&
        type === 'parameter'
      ) {
        let parameters = [...data.parameters];
        parameters = parameters.filter(value => value._id !== idToDelete);
        state.tempRecommData[recommendationIndex].parameters = parameters;
      }
      state.loaderObj = {};
      state.dataSaved = true;
      state.error = null;
    },

    saveActionItem(state, action) {
      const { trackRecId } = action.payload;
      const actionLoaderObj = { ...state.actionLoaderObj };
      actionLoaderObj[trackRecId] = true;
      state.actionLoaderObj = actionLoaderObj;
      state.error = null;
    },

    actionItemSaved(state, action) {
      const { trackRecId, response } = action.payload;
      const recommendation = state.recommendationData.find(
        recommendation => recommendation._id === trackRecId,
      );
      if (recommendation) {
        recommendation.actionitems = response;
      }
      state.actionLoaderObj = {};
      state.dataSaved = true;
    },

    deleteActionItem(state) {
      state.loading = true;
      state.error = null;
    },
    actionItemDeleted(state, action) {
      const { trackRecId } = action.payload;

      const recommendation = state.recommendationData.find(
        recommendation => recommendation._id === trackRecId,
      );
      if (recommendation) {
        recommendation.actionitems = [];
      }
      state.loading = false;
    },

    deleteActionRow(state, action) {
      // state.loading = true;
      const { trackRecId } = action.payload;
      const actionLoaderObj = { ...state.actionLoaderObj };
      actionLoaderObj[trackRecId] = true;
      state.actionLoaderObj = actionLoaderObj;
      state.error = null;
    },
    actionItemRowDeleted(state, action) {
      const { trackRecId, actionItemId } = action.payload;

      const recommendation = state.recommendationData.find(
        recommendation => recommendation._id === trackRecId,
      );
      if (recommendation) {
        recommendation.actionitems = recommendation.actionitems.filter(
          item => item._id !== actionItemId,
        );
      }
      // state.loading = false;
      state.actionLoaderObj = {};
      state.dataSaved = true;
      state.error = null;
    },

    sendReport(state) {
      state.loading = true;
      state.error = null;
    },
    Reportsent(state, action) {
      const { id, assessmentDetails } = action.payload;
      state.assessment = { ...state.assessment[id], assessmentDetails };

      state.loading = false;
    },
  },
});

export const { actions: reviewActions } = slice;

export const useReviewSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: reviewSaga });
  return { actions: slice.actions };
};

import styled from 'styled-components/macro';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 32px 0px 50px;
`;
export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

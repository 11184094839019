import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactTags } from 'react-tag-autocomplete';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { request } from 'app/common/utils/request';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import 'react-datepicker/dist/react-datepicker.css';

const ReviewCompletionPopup = props => {
  const { data } = props;

  const dispatch = useDispatch();
  const { actions } = useReviewSlice();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const { actions: reviewActions } = useReviewSlice();
  const categoriesResponse = useSelector(selectReview);
  const { assessment } = categoriesResponse;

  const [rolePermission, setRolePermission] = useState({
    users: [],
    ccUsers: [],
    bcc: [],
    suggestions: [],
  });

  const searchUsers = searchStr => {
    const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user/search?queryString=${searchStr}`;
    request(requestURL)
      .then(userData => {
        let userDataNew = userData.map(data => {
          return { value: data.id, label: data.username };
        });
        setRolePermission({
          ...rolePermission,
          suggestions: userDataNew,
        });
      })
      .catch(error => {});
  };

  const debouncedSearch = debounce(searchUsers, 500); // 500 milliseconds debounce delay

  const onInput = searchStr => {
    if (searchStr.length === 0 && searchStr.trim().length === 0) {
      return false;
    } else if (searchStr.length < 4 && searchStr.trim().length < 4) {
      return false;
    }
    debouncedSearch(searchStr);
  };

  const onAdd = useCallback(
    (newTag, type) => {
      setRolePermission(prevState => ({
        ...prevState,
        [type]: [...prevState[type], newTag],
        suggestions: [],
      }));
    },
    [rolePermission],
  );
  const onDelete = useCallback(
    (tagIndex, type) => {
      setRolePermission(prevState => ({
        ...prevState,
        [type]: prevState[type].filter((_, i) => i !== tagIndex),
      }));
    },
    [rolePermission],
  );

  const handleAddReview = async () => {
    // const to =
    //   rolePermission.users.length &&
    //   rolePermission.users.map(user => user.value);
    // const cc =
    //   rolePermission.ccUsers.length &&
    //   rolePermission.ccUsers.map(user => user.value);
    // const bcc =
    //   rolePermission.bcc.length && rolePermission.bcc.map(user => user.value);
    // if (to.length === 0) {
    //   toast.error('Please enter user email in To Section');
    //   return;
    // }

    const generatePdfBlob = async () => {
      const additionalContent = document.getElementById('projectDetails');
      const pdfContent = document.getElementById('pdf-content');

      const combinedContent = document.createElement('div');
      combinedContent.style.position = 'absolute';
      combinedContent.style.top = '0';
      combinedContent.style.left = '0';
      combinedContent.style.width = '100%';
      combinedContent.style.zIndex = '-1';

      combinedContent.appendChild(additionalContent.cloneNode(true));
      combinedContent.appendChild(pdfContent.cloneNode(true));
      document.body.appendChild(combinedContent);

      const canvas = await html2canvas(combinedContent, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      let position = 5;
      let imgHeight = (canvas.height * pdfWidth) / canvas.width;
      let leftHeight = imgHeight;

      while (leftHeight > 0) {
        pdf.addImage(
          imgData,
          'PNG',
          5,
          position,
          pdfWidth - 10,
          0,
          undefined,
          'FAST',
        );

        leftHeight -= pdfHeight;
        position -= pdfHeight;

        if (leftHeight > 0) {
          pdf.addPage();
        }
      }

      document.body.removeChild(combinedContent);

      const pdfBlob = pdf.output('blob');
      return new File([pdfBlob], 'recommendation_report.pdf', {
        type: 'application/pdf',
      });
    };

    const pdfFile = await generatePdfBlob();

    const to = rolePermission.users.map(user => user.value);
    const cc = rolePermission.ccUsers.map(user => user.value);
    const bcc = rolePermission.bcc.map(user => user.value);
    const trackId = data.trackId;
    if (to.length === 0) {
      toast.error('Please enter user email in To Section');
      return;
    }

    dispatch(
      reviewActions.sendReport({
        to,
        cc,
        bcc,
        trackId,
        file: pdfFile,
      }),
    );

    const flattenedDetails = {
      reviewStatus: 'COMPLETED',
    };
    dispatch(
      reviewActions.updateAssessment({
        trackId: assessment?.assessmentDetails?.id,
        data: flattenedDetails,
      }),
    );
    dispatch(modalManagerActions.closePopUp());
  };

  return (
    <div className="flex flex-col w-[500px]">
      <div>
        <label className="mb-[0.2rem] ml-2 mb-0">To</label>

        <ReactTags
          selected={rolePermission.users}
          suggestions={rolePermission.suggestions}
          onAdd={data => onAdd(data, 'users')}
          onDelete={data => onDelete(data, 'users')}
          onInput={onInput}
          noOptionsText="No matching emails"
          minQueryLength={4}
          placeholderText={
            rolePermission.users?.length > 0 ? '' : 'Enter User Email'
          }
        />
      </div>
      <div>
        <label className="mb-[0.2rem] ml-2 mt-[2rem] mb-0">CC</label>
        <ReactTags
          selected={rolePermission.ccUsers}
          suggestions={rolePermission.suggestions}
          onAdd={data => onAdd(data, 'ccUsers')}
          onDelete={data => onDelete(data, 'ccUsers')}
          onInput={onInput}
          noOptionsText="No matching emails"
          minQueryLength={4}
          placeholderText={
            rolePermission.ccUsers?.length > 0 ? '' : 'Enter User Email'
          }
        />
      </div>
      <div>
        <label className="mb-[0.2rem] ml-2 mt-[2rem] mb-0">Bcc</label>
        <ReactTags
          selected={rolePermission.bcc}
          suggestions={rolePermission.suggestions}
          onAdd={data => onAdd(data, 'bcc')}
          onDelete={data => onDelete(data, 'bcc')}
          onInput={onInput}
          noOptionsText="No matching emails"
          minQueryLength={4}
          placeholderText={
            rolePermission.ccUsers?.length > 0 ? '' : 'Enter User Email'
          }
        />
      </div>

      <div className="w-full flex justify-end mt-12 mb-12">
        <button
          className="py-4 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] w-4/12"
          onClick={handleAddReview}
        >
          Send Report
        </button>
      </div>
    </div>
  );
};

export default ReviewCompletionPopup;

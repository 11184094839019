import {
  PERMISSION_GROUP,
  USER_ROLES,
  USER_ROLE_MAPPER,
} from './permissionConstant';
import { partition } from 'lodash';

/**
 * This function filters a list of items based on a user's role. Each item has an 'accessTo' property
 * that specifies the permission required to access the item. The function checks if the user's role
 * has the necessary permission and returns a new list that includes only the items the user has access to.
 * This function also calculate that if user has higher role then he can access the lower role permission.
 * @param {Array} data - The list of items to filter.
 * @param {string} userRole - The role of the user. Defaults to USER_ROLES.ADMIN.
 * @returns {Array} A new list that includes only the items the user has access to. If permission is not required, the item is included in the returned list.
 */
export const ValidatePermission = (
  data,
  userRole = USER_ROLES.ADMIN,
  skillRole = '',
) => {
  const filteredRoutes = partition(data, function (item) {
    // if ((userRole = USER_ROLES.SUPER_ADMIN)) {
    //   return true;
    // } else {
    //   if (item.accessTo && module !== 'skill') {
    //     const roleMapperIndex = USER_ROLE_MAPPER[userRole];
    //     return (
    //       PERMISSION_GROUP[roleMapperIndex] &&
    //       PERMISSION_GROUP[roleMapperIndex].includes(item.accessTo)
    //     );
    //   } else if (item.accessTo && module === 'skill') {
    //     console.log('okokookok');
    //   }
    //   return true;
    // }

    const roleMapperIndex =
      item?.module === 'skill'
        ? USER_ROLE_MAPPER[skillRole]
        : USER_ROLE_MAPPER[userRole];
    if (item.accessTo && item?.module !== 'skill') {
      return (
        (PERMISSION_GROUP[roleMapperIndex] &&
          PERMISSION_GROUP[roleMapperIndex].includes(item.accessTo)) ||
        userRole === USER_ROLES.SUPER_ADMIN
      );
    } else if (item.accessTo && item?.module === 'skill') {
      return (
        (PERMISSION_GROUP[roleMapperIndex] &&
          PERMISSION_GROUP[roleMapperIndex].includes(item.accessTo)) ||
        skillRole === USER_ROLES.SUPER_ADMIN
      );
    } else if (!item.accessTo && item?.module === 'skill') {
      return skillRole !== '' && skillRole !== USER_ROLES.NO_ACCESS;
    }
    return true;
  });
  return filteredRoutes;
};

import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createParamActions } from './saga';

export const initialState = {
  showModal: false,
  error: null,
  loading: true,
  list: null,
  dataFetched: false,
};

const slice = createSlice({
  name: 'createParameters',
  initialState,
  reducers: {
    openPop(state, action) {
      state.showModal = true;
    },
    closePopUp(state) {
      state.showModal = false;
    },
    getParamList(state) {
      state.error = null;
    },
    gotParamList(state, action) {
      state.list = action.payload;
      state.error = null;
      state.loading = false;
      state.dataFetched = true;
    },
    updateParamList(state, action) {
      state.list = state.list.filter(param => param.id !== action.payload);
      state.error = null;
      state.loading = false;
    },
    deleteParam() {},
    addParam() {},
    setLoader(state) {
      state.loading = true;
    },
    removeLoader(state) {
      state.loading = false;
    },
    setCategoryError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { actions: paramActions } = slice;

export const useParamSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: createParamActions });
  return { actions: slice.actions };
};

import React, { useEffect, useState, useCallback } from 'react';
import { ReactTags } from 'react-tag-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import DashboardTable from 'app/common/components/Table/table';

import { usePermissionSlice } from './permissionSlice';
import { permission } from './permissionSlice/selector';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { threeDots } from '../../assets/Common/commonImages';
import { request } from 'app/common/utils/request';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from '../Setup/style';
import Button from 'app/common/components/Button';
import Dropdown from 'app/common/components/DropdownNew';
import { ROLES } from 'app/common/constants/constants';
import { USER_ROLES } from 'app/common/services';
import {
  handleDotsClick,
  addDotsEventListener,
  removeDotsEventListener,
} from 'app/common/utils/helperFn';
import './style.css';

function Permission() {
  const { actions } = usePermissionSlice();
  const permissions = useSelector(permission);
  const { loading } = permissions;
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();

  const [searchEmp, setSearchEmp] = useState('');

  let rolesArr = [...ROLES];
  useEffect(() => {
    dispatch(actions.getPermission());
  }, [actions, dispatch]);

  const [rolePermission, setRolePermission] = useState({
    users: [],
    suggestions: [],
  });

  const [selectedRoles, setSelectedRoles] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const onSearchEmp = search => {
    setSearchEmp(search);
  };

  const handleAddClick = () => {
    const userIds = rolePermission.users.map(user => user.value);
    dispatch(actions.createRoles({ role: USER_ROLES[selectedRoles], userIds }));
    setRolePermission({
      users: [],
      suggestions: [],
    });
    setSelectedRoles([]);
  };

  const deleteRoleHandler = row => {
    try {
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'ConfirmPopup',
          modalClassName: `w-[500px] rounded-xl`,
          data: { role: row.role, roleId: row.id, name: row.employName },
        }),
      );
    } catch (e) {}
  };

  const handleRoleAction = (row, role) => {
    const normalizedRole =
      role === 'Super Admin' ? 'SUPER_ADMIN' : role.toUpperCase();

    dispatch(
      actions.createRoles({
        userIds: row.id,
        role: USER_ROLES[normalizedRole],
      }),
    );
  };

  const searchUsers = searchStr => {
    const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user/search?queryString=${searchStr}`;
    request(requestURL)
      .then(userData => {
        let userDataNew = userData.map(data => {
          return { value: data.id, label: data.username };
        });
        setRolePermission({
          ...rolePermission,
          suggestions: userDataNew,
        });
      })
      .catch(error => {});
  };

  const debouncedSearch = debounce(searchUsers, 500); // 500 milliseconds debounce delay

  const onInput = searchStr => {
    if (searchStr.length === 0 && searchStr.trim().length === 0) {
      return false;
    } else if (searchStr.length < 4 && searchStr.trim().length < 4) {
      return false;
    }
    debouncedSearch(searchStr);
  };

  const onAdd = useCallback(
    (newTag, type) => {
      setRolePermission({
        ...rolePermission,
        users: [...rolePermission.users, newTag],
        suggestions: [],
      });
    },
    [rolePermission],
  );

  const onDelete = useCallback(
    (tagIndex, type) => {
      setRolePermission({
        ...rolePermission,
        users: rolePermission.users.filter((_, i) => i !== tagIndex),
      });
    },
    [rolePermission],
  );

  function handleRolesChange(value) {
    try {
      setSelectedRoles(value);
    } catch (e) {}
  }

  useEffect(() => {
    addDotsEventListener();
    return removeDotsEventListener();
  }, []);

  // const mapDesignationLabel = designation => {
  //   const foundRole = rolesArr.find(role => role.value === designation);
  //   return foundRole ? foundRole.label : '';
  // };

  // Filter the table data based on the employee name
  const filteredTableData = permissions?.rolesData?.filter(role =>
    role.name.toLowerCase().includes(searchEmp.toLowerCase()),
  );

  const tableData = filteredTableData.map(role => {
    let userRole = '';
    for (let key in USER_ROLES) {
      if (USER_ROLES[key] === role.role) {
        const foundRole = ROLES.find(role => role.value === key);
        userRole = foundRole ? foundRole.label : '';
      }
    }
    return {
      id: role._id,
      profilePic: role.profilePicURL,
      employName: role.name,
      employId: role.newerId,
      designation: role.designation,
      role: userRole,
    };
  });

  useEffect(() => {
    let isDisabled =
      rolePermission.users?.length !== 0 && selectedRoles !== '' ? false : true;
    setIsButtonDisabled(isDisabled);
  }, [rolePermission, selectedRoles]);

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'profilePic',
      text: '',
      formatter: (cell, row) => {
        return (
          <img
            src={row.profilePic}
            alt="profile-pic"
            className="cursor-pointer h-[24px] rounded-lg"
          />
        );
      },
    },
    {
      dataField: 'employName',
      text: 'Emp Name',
    },
    {
      dataField: 'employId',
      text: 'Emp ID',
    },
    {
      dataField: 'designation',
      text: 'Designation',
    },
    {
      dataField: 'role',
      text: 'Role',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => {
        return (
          <>
            <img
              src={threeDots}
              alt="view"
              className="cursor-pointer h-[18px] mt-2.5"
              onClick={event => {
                handleDotsClick(event, row.id);
              }}
            />
            <div>
              <div
                id={row.id}
                className="options-container three-dots bg-white text-black p-4 hidden inline-grid absolute translate-x-7 -translate-y-5 whitespace-nowrap rounded bg-white px-2 py-1 text-black shadow-xl"
              >
                {['Super Admin', 'Admin', 'Viewer'].map(role => (
                  <div
                    key={role?.newerId}
                    className="border-b border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
                    onClick={() => handleRoleAction(row, role)}
                  >
                    {role}
                  </div>
                ))}
                <div
                  className="border border-solid border-1 border-[#de1186] rounded-[4px] text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                  onClick={() => {
                    deleteRoleHandler(row);
                  }}
                >
                  Remove
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <>
      {loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px] ">
          <div className="flex justify-between">
            <div className="heading">
              <h3 className="text-[#151D48] text-[28px] font-semibold ">
                Permission
              </h3>
              <span className="text-[#151D48] text-[16px] font-normal opacity-50 ">
                Give permission to user
              </span>
            </div>
            <div className="flex w-3/5 items-baseline">
              <div className="wrap-search rounded-[6px] w-full relative pr-[160px] bg-[#F9FAFB]">
                <ReactTags
                  selected={rolePermission.users}
                  suggestions={rolePermission.suggestions}
                  onAdd={data => onAdd(data, 'users')}
                  onDelete={data => onDelete(data, 'users')}
                  onInput={onInput}
                  noOptionsText="No matching emails"
                  minQueryLength={4}
                  placeholderText={
                    rolePermission.users?.length > 0 ? '' : 'Enter User Email'
                  }
                />
                <div className="dropdown absolute w-[150px] top-0 right-0 h-full">
                  <Dropdown
                    options={rolesArr}
                    onSelect={handleRolesChange}
                    selectedOption={selectedRoles}
                    className={`align-middle drp-2 w-60 mr-4  p-2 h-full`}
                    optionsClassName="custom-options1 top-[87%] border-none"
                    placeholder="Select"
                  />
                </div>
              </div>
              <Button
                handleClick={handleAddClick}
                className={`py-4 px-12 ml-[15px] rounded-[10px] text-white px-[20px] text-2xl font-medium bg-[#DE1186]  ${
                  isButtonDisabled ? 'disabled' : ''
                }`}
                name="ADD"
              />
            </div>
          </div>
          <div className="pb-9 flex justify-end">
            <input
              type="text"
              value={searchEmp}
              onChange={e => onSearchEmp(e.target.value)}
              placeholder="Search by employee name"
              className="border border-solid border-gray-300 rounded-md py-2 px-3 mt-2 outline-none w-[280px]"
            />
          </div>
          <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10">
            <DashboardTable
              data={tableData}
              columns={columns}
              classcategory={'tablereviewpage'}
              defaultArray={[{ dataField: 'role', order: 'asc' }]}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Permission;

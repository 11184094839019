import styled from 'styled-components/macro';

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
`;

export const Title = styled.div`
  /* margin-top: 32px;
  margin-left: 32px; */
  flex-shrink: 0;
  gap: 10px;

  h1 {
    color: #151d48;
    font-family: system-ui;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 10px 0;
  }

  span {
    color: #151d48;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    opacity: 0.6;
  }
`;

export const RightIcons = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin: 0 32px; /* Adjust the margin as needed */
  }
`;

const USER_ROLES = {
  SUPER_ADMIN: '100100',
  ADMIN: '011011',
  MANAGER: '200200',
  VIEWER: '022022',
  APPROVER: '010010',
  CREATOR: '202202',
  NO_ACCESS: '001001',
};

const USER_ROLE_MAPPER = {
  [USER_ROLES.SUPER_ADMIN]: 1,
  [USER_ROLES.ADMIN]: 2,
  [USER_ROLES.MANAGER]: 3,
  [USER_ROLES.VIEWER]: 4,
  [USER_ROLES.NO_ACCESS]: 5,
  [USER_ROLES.APPROVER]: 6,
  [USER_ROLES.CREATOR]: 7,
};

const PERMISSION_GROUP = {
  1: [
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.ADMIN,
    USER_ROLES.MANAGER,
    USER_ROLES.VIEWER,
  ],
  2: [USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.VIEWER],
  3: [USER_ROLES.MANAGER, USER_ROLES.VIEWER],
  4: [USER_ROLES.VIEWER],
  5: [USER_ROLES.NO_ACCESS],
  6: [USER_ROLES.APPROVER, USER_ROLES.CREATOR, USER_ROLES.VIEWER],
  7: [USER_ROLES.CREATOR, USER_ROLES.VIEWER],
};

export { USER_ROLES, USER_ROLE_MAPPER, PERMISSION_GROUP };

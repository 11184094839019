// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upcoming-events-container{
    border-radius: 8px;
}

.upcoming-events-listing{
    overflow: auto;
}

.upcoming-event-box{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DADCE0;
}

.upcoming-event-box:last-child{
    border-bottom: unset;
}

.upcoming-event-date{
    display: flex;
    flex-direction: column;
    width: 8%;
    padding: 10px 0px ;
    margin-left: 15px;
    align-items: center;
    justify-content: center;
}

/* .upcomingEventDate p:first-child{
    font-size: 26px;
    font-weight: 500;
}

.upcomingEventDate p:last-child{
    font-size: 10px;
    font-weight: 100;
    line-height: 0.8px;
    color: #5E5E5E;
} */

.upcoming-event-discription{
    display: flex;
    flex-direction: column;
    padding: 30px 30px 30px ;

}
/* .upcomingEventDiscription p:first-child{
    font-size: 16px;
    font-weight: 400;
}

.upcomingEventDiscription p:last-child{
    color: #5E5E5E;
    line-height: 5px;
    font-size: 13px;
} */`, "",{"version":3,"sources":["webpack://./src/app/pages/DashboardCalendar/components/UpcomingEvents/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;;;;;;;;;;GAUG;;AAEH;IACI,aAAa;IACb,sBAAsB;IACtB,wBAAwB;;AAE5B;AACA;;;;;;;;;GASG","sourcesContent":[".upcoming-events-container{\n    border-radius: 8px;\n}\n\n.upcoming-events-listing{\n    overflow: auto;\n}\n\n.upcoming-event-box{\n    display: flex;\n    align-items: center;\n    border-bottom: 1px solid #DADCE0;\n}\n\n.upcoming-event-box:last-child{\n    border-bottom: unset;\n}\n\n.upcoming-event-date{\n    display: flex;\n    flex-direction: column;\n    width: 8%;\n    padding: 10px 0px ;\n    margin-left: 15px;\n    align-items: center;\n    justify-content: center;\n}\n\n/* .upcomingEventDate p:first-child{\n    font-size: 26px;\n    font-weight: 500;\n}\n\n.upcomingEventDate p:last-child{\n    font-size: 10px;\n    font-weight: 100;\n    line-height: 0.8px;\n    color: #5E5E5E;\n} */\n\n.upcoming-event-discription{\n    display: flex;\n    flex-direction: column;\n    padding: 30px 30px 30px ;\n\n}\n/* .upcomingEventDiscription p:first-child{\n    font-size: 16px;\n    font-weight: 400;\n}\n\n.upcomingEventDiscription p:last-child{\n    color: #5E5E5E;\n    line-height: 5px;\n    font-size: 13px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCalendarSlice } from './calendarslice';
import { selectCalendar } from './calendarslice/selectors';

import Breadcrumb from '../../common/components/Breadcrumb';
import DashboardCalendar from '../../common/components/DashboardCalendar';
import CalendarTrackList from './components/CalendarTrackList';
import Dropdown from '../../common/components/DropdownNew';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from '../Setup/style';
import {
  PROJECT_START_YEAR,
  TRACK_STATUS_NEW,
} from '../../common/constants/constants';

import {
  calendarIcon,
  calendarIconD,
  listIcon,
  listIconD,
  event,
  eventd,
} from '../../assets/Common/commonImages';
import UpcomingEvents from 'app/pages/DashboardCalendar/components/UpcomingEvents';

function DashboardPage() {
  const { actions } = useCalendarSlice();
  const calendar = useSelector(selectCalendar);
  const dispatch = useDispatch();

  const { view, loading } = calendar;
  let statusArr = [{ value: 'ALL', label: 'All' }];
  statusArr = statusArr.concat(TRACK_STATUS_NEW);
  if (view === 'CALENDAR') {
    statusArr.push({ value: 'followUps', label: 'Follow-up Events' });
  }
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [eventSelectedYear, setEventSelectedYear] = useState(getCurrentYear());
  const [selectedStatus, setSelectedStatus] = useState('ALL');
  const [currentCalendarDate, setCurrentCalendarDate] = useState({
    date: new Date(),
    year: getCurrentYear(),
  });

  const breadcrumbItems =
    view === 'CALENDAR'
      ? [{ text: 'Calendar View' }]
      : view === 'EVENT'
      ? [{ text: 'Event View' }]
      : [{ text: 'List View' }];
  if (view === 'CALENDAR') {
    statusArr = statusArr.filter(status => status.value !== 'YET_TO_START');
  }
  // useEffect(() => {
  //   dispatch(actions.getTracks({ view }));
  // }, [actions, dispatch]);

  function handleImgClick(viewType) {
    dispatch(actions.setView(viewType));
    if (viewType === 'EVENT') {
      dispatch(
        actions.getFollowUpTracks({
          year: currentCalendarDate?.year,
        }),
      );
    }
    if (viewType === 'LIST') {
      setSelectedStatus('ALL');
    }
  }

  function handleYearChange(year) {
    try {
      if (view === 'EVENT') {
        setEventSelectedYear(year);
        dispatch(actions.getFollowUpTracks({ year, eventSelectedYear }));
      } else {
        setSelectedYear(year);
        dispatch(actions.getTracks({ view, year }));
      }
    } catch (e) {}
  }

  function handleStatusChange(value) {
    try {
      setSelectedStatus(value);
    } catch (e) {}
  }

  function getCurrentYear() {
    return new Date().getFullYear();
  }

  const generateYearList = () => {
    let year = PROJECT_START_YEAR;
    let currentYear = getCurrentYear();
    const yearList = [];
    if (view !== 'EVENT') {
      while (year <= currentYear) {
        yearList.push({ value: year, label: year });
        year++;
      }
    } else {
      yearList.push(
        { value: currentYear, label: currentYear },
        { value: currentYear + 1, label: currentYear + 1 },
      );
    }
    return yearList;
  };

  const yearValues = generateYearList();

  useEffect(() => {
    if (currentCalendarDate) {
      dispatch(
        actions.getTracks({
          view,
          year: currentCalendarDate?.year,
          selectedYear,
        }),
      );
    }
  }, [currentCalendarDate]);

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="review-listing">
          <div className="pb-9 flex justify-between">
            <div className="">
              <Breadcrumb
                heading={
                  view === 'EVENT'
                    ? 'Upcoming Events'
                    : 'Projects Review Timeline'
                }
                items={breadcrumbItems}
              />
            </div>
            <div className="flex">
              {view !== 'EVENT' ? (
                <div className="relative">
                  <Dropdown
                    options={statusArr}
                    onSelect={handleStatusChange}
                    selectedOption={selectedStatus}
                    className={`align-middle drp-2 w-60 mr-4 mt-8 p-2 h-11 border border-solid border-1 border-[#737791] rounded-[4px]`}
                    optionsClassName="custom-options1 top-3/4"
                    placeholder="Select"
                  />
                </div>
              ) : null}
              {view !== 'CALENDAR' ? (
                <div className="relative">
                  <Dropdown
                    options={yearValues}
                    onSelect={handleYearChange}
                    selectedOption={
                      view === 'EVENT' ? eventSelectedYear : selectedYear
                    }
                    className={`drp-2 w-36 mr-4 mt-8 p-2 h-11 border border-solid border-1 border-[#737791] rounded-[4px]`}
                    optionsClassName="custom-options1 top-3/4"
                    placeholder="Select"
                  />
                </div>
              ) : null}
              <ul className="flex justify-center items-center">
                <li>
                  <img
                    src={view === 'CALENDAR' ? calendarIcon : calendarIconD}
                    alt="calendar"
                    className="mr-4 cursor-pointer"
                    onClick={() => handleImgClick('CALENDAR')}
                  />
                </li>
                <li>
                  <img
                    src={view === 'LIST' ? listIcon : listIconD}
                    alt="list"
                    className="mr-4 cursor-pointer"
                    onClick={() => handleImgClick('LIST')}
                  />
                </li>
                <li>
                  <img
                    src={view === 'EVENT' ? eventd : event}
                    alt="event"
                    className="cursor-pointer"
                    onClick={() => handleImgClick('EVENT')}
                  />
                </li>
              </ul>
            </div>
          </div>
          {view === 'CALENDAR' ? (
            <DashboardCalendar
              currentCalendarDate={currentCalendarDate}
              setCurrentCalendarDate={setCurrentCalendarDate}
              selectedStatus={selectedStatus}
            />
          ) : view === 'EVENT' ? (
            <>
              <UpcomingEvents
                currentCalendarDate={currentCalendarDate}
                // setCurrentCalendarDate={setCurrentCalendarDate}
              />
            </>
          ) : (
            <CalendarTrackList selectedStatus={selectedStatus} />
          )}
        </div>
      )}
    </>
  );
}

export default DashboardPage;

import styled from 'styled-components/macro';

export const Box = styled.div`
  /* height: 896px; */
  margin: 0px 32px 32px 32px;
  border-radius: ${props => (props.borderRadius ? '16px' : '0')};
  border: ${props => (props.borderRadius ? '1px solid #f8f9fa' : 'none')};
  background: #fff;
  /* box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5); */
  box-shadow: ${props =>
    props.borderRadius ? '0px 4px 20px 0px rgba(238, 238, 238, 0.5)' : 'none'};
`;
export const Wrapper = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  /* margin-left: 32px; */
`;
export const Card = styled.div`
  position: relative;
  padding: 26px 0px 90px 27px;
  /* display: flex;
  flex-direction: column; */
  /* margin: 0px 32px 32px 0px; */
  /* width: calc(25% - 16px);
  width: 320px; */
  /* flex-shrink: 0; */
  border-radius: 16px;
  background: #f1f3fd;
  img {
    width: 53.333px;
    height: 52.174px;
  }
  span {
    margin-top: 21px;
    color: var(--greys-blue-grey-900, #151d48);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  h1 {
    margin: 5px 0px 10px;
    color: #425166;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    height: 23px;
    padding: 3px 7px;
    gap: 10px;
    margin: 0px 24px 0px 0px;
    color: #fff;

    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.1px;
    border-radius: 6px;
    background: #f28300;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px 0px 0px;

  span {
    margin: 0px 5px 0px 5px;
  }
  img {
    height: 24px;
    width: 24px;
  }
`;

export const DigitBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
  right: 0;
  margin-right: 8px;

  .question-span {
    margin-top: 15px;
    color: #151d48;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  img {
    width: 24px;
    height: 24px;
    margin-top: 19px;
  }
  .viewbox {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    .button-title-span {
      margin-top: 20px;
      color: #de1186;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;
export const Heading = styled.div`
  margin: 16px 0px 16px 26px;
  color: #151d48;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const ViewButton = styled.div`
  margin-right: 0px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  color: #de1186;
`;

export const OtherButton = styled.div`
  margin-right: 0px;
  margin-left: -16px;
  display: flex;
  align-items: center;
  color: #de1186;
`;
export const Buttontitlespan = styled.div`
  margin-top: 20px;
  color: #de1186;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
export const Questionspan = styled.div`
  /* margin: 15px 112px 0px 0px;
  font-size: 16px;
  font-style: normal; */
  opacity: 0.6;
  color: #425166;
  font-weight: 500;
  color: #151d48;
  .SingleQuestionSpan {
    margin-right: 6px;
  }
  .MultipleQuestionsSpan {
    margin-right: 0px;
  }
`;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Radio = props => {
  const {
    label,
    name,
    defaultChecked,
    checked,
    id,
    onChange,
    value,
    classes = {},
    disabled = false,
  } = props;

  return (
    <span
      className={`flex items-center min-h-[1.25rem] my-1 ${classes?.inputBoxContainer}`}
    >
      <input
        className={`custom-radio w-[1.125rem] h-[1.125rem] rounded-[50%] grid place-content-center before:rounded-[50%] ${classes?.inputField} cursor-pointer`}
        id={id || label}
        type="radio"
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        value={value || label}
        disabled={disabled}
      />
      <label
        htmlFor={id || label}
        className={`
        pointer-events-none	radio-label mx-4 whitespace-nowrap overflow-hidden text-ellipsis radio-label ${classes?.label}`}
        title={label}
      >
        {label}
      </label>
    </span>
  );
};

Radio.propTypes = {
  lable: PropTypes.string,
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  classes: PropTypes.shape({
    lable: PropTypes.string,
    inputField: PropTypes.string,
    inputBoxContainer: PropTypes.string,
  }),
};

export default memo(Radio);

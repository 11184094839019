import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { addReviewActions as actions } from '.';
import { reviewActions } from 'app/pages/Review/reviewSlice';
import { commonActions } from 'app/common/slices/errorBoundarySlice/index';
import { toast } from 'react-toastify';

export function* createTrack(action) {
  const trackData = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/track`;
  try {
    yield put(reviewActions.setLoader());
    const tracks = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(trackData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(actions.trackCreated(tracks));
    yield put(reviewActions.appendProjectReviewTrack(tracks));
    toast.success('Track Created');
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
      }),
    );
    yield put(reviewActions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.createTrackError('TRACK NOT FOUND'));
    //   toast.error('Track api not found');
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.createTrackError('RATE LIMIT'));
    //   toast.error('Rate limit or network error');
    // } else {
    //   yield put(actions.createTrackError('RESPONSE ERROR'));
    //   toast.error('Response error');
    // }
  }
}

export function* updateTrack(action) {
  const { trackId, data } = action.payload;
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/track/${trackId}`;
  try {
    yield put(reviewActions.setLoader());
    const updatedTrackData = yield call(request, requestURL, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(
      reviewActions.updateTrackData({
        project: data.project,
        trackId: trackId,
        trackData: updatedTrackData,
      }),
    );
    toast.success('Track Updated');
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
      }),
    );
    yield put(reviewActions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.updateTrackError('TRACK UPDATE NOT FOUND'));
    //   toast.error('Track api not found');
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.updateTrackError('RATE LIMIT'));
    //   toast.error('Rate limit or network error');
    // } else {
    //   yield put(actions.updateTrackError('RESPONSE ERROR'));
    //   toast.error('Response error');
    // }
  }
}

export function* trackFormSaga() {
  yield takeLatest(actions.createTrack, createTrack);
  yield takeLatest(actions.updateTrack, updateTrack);
}

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { dropdown } from '../../../assets/Common/commonImages';

function Dropdown({
  options,
  onSelect,
  selectedOption,
  selectedOptionStyle,
  placeholder,
  className,
  optionsClassName,
  isDisabled,
  appendedText,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = option => {
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={className}>
      <div
        className={`selected-option flex justify-between items-center cursor-pointer ${
          isDisabled ? 'disabled' : ''
        }`}
        style={selectedOptionStyle}
        onClick={handleToggle}
      >
        <span
          className={`selected-text ${!selectedOption ? 'opacity-50' : ''}`}
        >
          {selectedOption || placeholder}
        </span>
        <span className={`caret ${isOpen ? 'rotate' : ''}`}>
          <img src={dropdown} alt="Collapse" />
        </span>
      </div>
      {!isDisabled && isOpen && (
        <ul className={`options ${optionsClassName}`}>
          {options.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)}>
              {option}
              {appendedText && appendedText[index] && (
                <span className="text-[#151D48] opacity-50">
                  {' '}
                  ({appendedText[index]})
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  placeholder: PropTypes.string,
};

import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { error } from '../../../../assets/Common/commonImages';
import Button from 'app/common/components/Button';
import Dropdown from 'app/common/components/DropDown';
import Input from 'app/common/components/Input/input';
import Breadcrumb from 'app/common/components/Breadcrumb';
import Accordion from 'app/common/components/Accordion';
import Criteria from '../Criteria/Criteria';
import { useCategorySlice } from './categoryslice';
import { isEmpty } from 'app/common/utils/helperFn';
import { selectCategory } from './categoryslice/selectors';
import { validationSchema } from './setupvalidation';
import { useParamSlice } from '../CreateParameters/slice';
import { paramList } from '../CreateParameters/selectors';
import LoaderComponent from 'app/common/components/Loader';
import { suggestionsStatusCriteria } from 'app/common/constants/constants';
import { LoaderContainer } from '../../style';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services/permission/permissionConstant';
import './style.css';

function SetupForm(props) {
  const params = useParams();
  const ratings = ['N/A', '1', '2', '3', '4', '5', `6`, `7`, `8`, `9`, `10`];
  const [errors, setErrors] = useState({});
  const parameters = useSelector(paramList, shallowEqual || []);
  const currentCategoryResponse = useSelector(selectCategory);

  const dispatch = useDispatch();
  const { actions } = useCategorySlice();
  const { actions: paramActions } = useParamSlice();

  //added here instead of in pages\Setup\SetupForm
  useEffect(() => {
    dispatch(actions.getCategory());
  }, [actions, dispatch]);

  // const decodedHeading = revertSlug(params?.name);
  const navigate = useNavigate();
  const breadcrumbItems = [
    { text: 'Setup', link: '/setup' },
    {
      text: params?.categoryId
        ? currentCategoryResponse?.currentCategoryDetails?.name
        : 'Create New',
    },
  ];

  const [criteriaValidationResults, setCriteriaValidationResults] = useState(
    {},
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const editableStatus = ['NOT_COMPLIANT', 'PARTIALLY_COMPLIANT', 'COMPLIANT'];
  const defaultStatus = [
    {
      value: 'COMPLIANT',
      label: 'Compliant',
    },
    {
      value: 'NOT_COMPLIANT',
      label: 'Not Compliant',
    },
    {
      value: 'PARTIALLY_COMPLIANT',
      label: 'Partially Compliant',
    },
  ];

  const [formData, setFormData] = useState({
    name: '',
    parameters: [
      {
        parameter: {
          id: '',
          name: '',
        },
        criterias: [
          {
            measurementCriteria: '',
            ratings: null,
            commentsCharacterCount: null,
            helpText: '',
            status: [...defaultStatus],
          },
        ],
      },
    ],
  });
  useEffect(() => {
    if (!parameters?.dataFetched) dispatch(paramActions.getParamList());
  }, [dispatch, paramActions, parameters?.dataFetched]);

  useEffect(() => {
    if (params?.categoryId) {
      dispatch(actions.getCategoryById(params.categoryId));
    }
  }, [actions, dispatch, params.categoryId]);

  useEffect(() => {
    if (
      params?.categoryId &&
      !isEmpty(currentCategoryResponse) &&
      isEmpty(currentCategoryResponse?.error) &&
      currentCategoryResponse.currentCategoryDetails
    ) {
      const data = JSON.parse(
        JSON.stringify(currentCategoryResponse.currentCategoryDetails),
      );

      data?.parameters?.map(paramData => {
        return paramData?.criterias?.map(criteria => {
          criteria.status = suggestionsStatusCriteria.filter(obj => {
            if (criteria.status.indexOf(obj.value) > -1) {
              return true;
            }
            return false;
          });
          return criteria;
        });
      });
      setFormData(data);
    }
  }, [currentCategoryResponse, params?.categoryId]);

  const { categoryCreatedOrUpdated, cloned } = useSelector(
    state => state.category,
  );

  useEffect(() => {
    if (categoryCreatedOrUpdated || cloned) {
      navigate('/setup');
    }
  }, [categoryCreatedOrUpdated, cloned, navigate]);

  const handleInputChange = value => {
    setFormData(prevData => ({
      ...prevData,
      name: value,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      name: undefined,
    }));
  };

  const handleCriteriaValidationComplete = async (
    isValid,
    index,
    criteriaIndex,
  ) => {
    setCriteriaValidationResults(prevResults => ({
      ...prevResults,
      [`${index}-${criteriaIndex}`]: isValid,
    }));
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const errorsMap = {};
      validationErrors.inner.forEach(error => {
        errorsMap[error.path] = error.message;
      });
      setErrors(errorsMap);
      return false;
    }
  };

  useEffect(() => {
    const submit = async () => {
      if (isSubmitting) {
        const criteriaValid =
          Object.keys(criteriaValidationResults).length > 0 &&
          !Object.values(criteriaValidationResults).includes(false);
        const isValid = await validateForm();
        if (isValid && criteriaValid) {
          const updatedFormData = { ...formData };
          updatedFormData.parameters.forEach(param => {
            param.criterias.forEach(criteria => {
              criteria.status = criteria.status.map(status => status.value);
            });
          });
          if (params?.categoryId) {
            dispatch(
              actions.updateCategory({
                categoryId: params.categoryId,
                data: formData,
              }),
            );
          } else {
            dispatch(actions.createCategory(formData));
          }
        } else {
          toast.error('Please fill all input fields');
        }
        setIsSubmitting(false);
      }
    };
    submit();
  }, [criteriaValidationResults]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
  };

  const handleSelect = (option, accordionIndex) => {
    const optionId = parameters.list?.find(
      parameter => parameter.name === option,
    )?.id;
    setFormData(prevData => {
      const newParameters = [...prevData.parameters];
      newParameters[accordionIndex].parameter.id = optionId;
      newParameters[accordionIndex].parameter.name = option;
      return { ...prevData, parameters: newParameters };
    });
  };

  const handleCriteriaChange = (event, accordionIndex, criteriaIndex, key) => {
    setFormData(prevData => {
      const newParameters = [...prevData.parameters];
      newParameters[accordionIndex].criterias[criteriaIndex][key] =
        event.target.value;
      return { ...prevData, parameters: newParameters };
    });
  };

  const handleCommentChange = (value, accordionIndex, criteriaIndex) => {
    setFormData(prevData => {
      const newParameters = [...prevData.parameters];
      newParameters[accordionIndex].criterias[
        criteriaIndex
      ].commentsCharacterCount = value;
      return {
        id: prevData?.id,
        name: prevData.name,
        parameters: newParameters,
      };
    });
  };

  const handleRatingsChange = (value, accordionIndex, criteriaIndex) => {
    setFormData(prevData => {
      // const updatedData = { ...prevData };
      const newParameters = [...prevData.parameters];
      // updatedData.parameters[accordionIndex].criterias[criteriaIndex].ratings =
      // value;
      newParameters[accordionIndex].criterias[criteriaIndex].ratings = value;
      return {
        id: prevData?.id,
        name: prevData.name,
        parameters: newParameters,
      };
      // return updatedData;
    });
  };

  const handleStatusChange = (value, accordionIndex, criteriaIndex) => {
    setFormData(prevData => {
      const newParameters = [...prevData.parameters];
      const criteria =
        newParameters[accordionIndex]?.criterias?.[criteriaIndex];

      if (criteria && criteria.status) {
        criteria.status = value;
      }

      return {
        id: prevData.id,
        name: prevData.name,
        parameters: newParameters,
      };
    });
  };

  const addCriteriaToParameters = (formData, accordionIndex) => {
    return formData.parameters.map((param, index) => {
      if (index === accordionIndex) {
        return {
          ...param,
          criterias: [
            ...param.criterias,
            {
              measurementCriteria: '',
              ratings: null,
              commentsCharacterCount: null,
              helpText: '',
              status: [...defaultStatus],
            },
          ],
        };
      }
      return param;
    });
  };

  /* Add Paramter(accordion) criteria*/
  const handleAddCriteria = accordionIndex => {
    setFormData(prevData => {
      const newParameters = addCriteriaToParameters(prevData, accordionIndex);
      const updatedData = { ...prevData };
      updatedData.parameters = newParameters;
      // return { name: prevData.name, parameters: newParameters };
      return updatedData;
    });
  };

  // const deleteCriteriaFromParameters = (
  //   formData,
  //   accordionIndex,
  //   criteriaIndex,
  // ) => {
  //   return formData.parameters.map((param, index) => {
  //     if (index === accordionIndex) {
  //       const newCriteria = [...param.criterias];
  //       newCriteria.splice(criteriaIndex, 1);
  //       return {
  //         ...param,
  //         criterias: newCriteria,
  //       };
  //     }
  //     return param;
  //   });
  // };

  // const handleDeleteCriteria = (accordionIndex, criteriaIndex) => {
  //   setFormData(prevData => {
  //     const newParameters = deleteCriteriaFromParameters(
  //       prevData,
  //       accordionIndex,
  //       criteriaIndex,
  //     );
  //     return {
  //       id: prevData?.id,
  //       name: prevData.name,
  //       parameters: newParameters,
  //     };
  //   });
  // };
  /* Delete Paramter(accordion) criteria*/
  const handleDeleteCriteria = (
    accordionIndex,
    criteriaIndex,
    criteriaId,
    categoryId,
  ) => {
    if (criteriaId && categoryId) {
      const userConfirmed = window.confirm('Are you sure you want to delete?');
      if (userConfirmed) {
        dispatch(actions.deleteCriteria({ criteriaId, categoryId }));
      } else {
        return;
      }
    } else {
      setFormData(prevData => {
        const updatedData = { ...prevData };
        updatedData.parameters[accordionIndex].criterias.splice(
          criteriaIndex,
          1,
        );
        return updatedData;
      });
    }
  };

  /* Delete Paramter(accordion) */
  const handleDeleteAccordion = (accordionIndex, parameterId, categoryId) => {
    if (parameterId && categoryId) {
      const userConfirmed = window.confirm('Are you sure you want to delete?');
      if (userConfirmed) {
        dispatch(actions.deleteCategoryParamter({ parameterId, categoryId }));
      } else {
        return;
      }
    } else {
      setFormData(prevData => {
        const newParameters = [...prevData.parameters];
        newParameters.splice(accordionIndex, 1);
        return { ...prevData, parameters: newParameters };
      });
    }
  };

  /* Creates jsx for accordion */
  const generateAccordionChildren = index => (
    <>
      <label className="label-category"> Select Parameter</label>
      <Dropdown
        options={parameters.list?.map(option => option.name)}
        onSelect={option => handleSelect(option, index)}
        selectedOption={formData.parameters[index]?.parameter?.name}
        selectedOptionStyle={{ padding: '10px' }}
        className="drp-1 relative rounded-[4px] border border-solid border-1 border-[#737791] w-full md:w-full lg:w-[400px]"
        optionsClassName="custom-options1"
        placeholder="Select"
        appendedText={parameters.list?.map(option => option.tag)}
      />
      {formData?.parameters[index]?.criterias?.map(
        (criteria, criteriaIndex) => (
          <div key={criteriaIndex}>
            <Criteria
              // key={criteriaIndex}
              // counts={criteriaIndex + 1}
              accordionIndex={index}
              deleteCriteria={handleDeleteCriteria}
              criteriaIndex={criteriaIndex}
              // measurementCriteria={criteria.measurementCriteria}
              ratings={ratings}
              // selectedRating={criteria.ratings}
              // commentsCharacterCount={criteria.commentsCharacterCount}
              // status={criteria.status}
              onCriteriaChange={handleCriteriaChange}
              onCommentsChange={handleCommentChange}
              onRatingsChange={handleRatingsChange}
              onStatusChange={value =>
                handleStatusChange(value, index, criteriaIndex)
              }
              errors={
                errors?.parameters?.[index]?.criterias?.[criteriaIndex] || {}
              }
              isSubmitting={isSubmitting}
              onValidationComplete={isValid =>
                handleCriteriaValidationComplete(isValid, index, criteriaIndex)
              }
              criteria={criteria}
              categoryId={formData?.id}
            />
          </div>
        ),
      )}

      <div className="criteria-section relative">
        <span
          className="add-criteria-icon"
          onClick={() => handleAddCriteria(index)}
        >
          +
        </span>
        <Button
          handleClick={() => handleAddCriteria(index)}
          name="Add New Criteria"
          value="criteria"
          className="add-criteria pl-[48px] font-medium text-14 text-[#DE1186] leading-10"
        />
      </div>
    </>
  );

  /* Add new parameter(accordion) */
  const handleAddAccordion = () => {
    setFormData(prevData => ({
      ...prevData,
      parameters: [
        ...prevData.parameters,
        {
          parameter: {
            id: '',
            name: '',
          },
          criterias: [
            {
              measurementCriteria: '',
              ratings: null,
              helpText: '',
              commentsCharacterCount: null,
              status: [...defaultStatus],
            },
          ],
        },
      ],
    }));
  };

  /* Function deleting category by Id */
  const deleteCategory = () => {
    if (params?.categoryId) {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this category?',
      );
      if (confirmDelete) {
        dispatch(actions.deleteCategoryById(params.categoryId));
      }
    }
  };

  return (
    <>
      {parameters?.loading || currentCategoryResponse?.loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px]">
          <div className="flex justify-between ">
            <Breadcrumb
              heading={
                params?.categoryId
                  ? currentCategoryResponse?.currentCategoryDetails?.name
                  : 'Create New'
              }
              items={breadcrumbItems}
            />
            <Permission accessTo={USER_ROLES.ADMIN}>
              {params?.categoryId && (
                <Button
                  handleClick={() => deleteCategory()}
                  className="save-btn rounded-[8px] bg-red-400 text-white mt-6 py-[13px] px-[40px]"
                  name="Delete"
                />
              )}
            </Permission>
          </div>
          <form
            className="setup-fields min-h-[560px] relative pt-16"
            onSubmit={handleSubmit}
          >
            <label className="label-name"> Category Name </label>
            <Input
              type="text"
              label="Category Name"
              placeHolder="Enter a name for the Assessment Category"
              name="category-name"
              className={`category-name flex w-full lg:w-2/4 rounded-[8px] bg-[#F9FAFB] py-8 pr-8 pl-4 h-10 outline-none border ${
                errors.name ? 'border-red-500' : 'border-[#737791]'
              } text-[#151D48]`}
              onChange={event => handleInputChange(event.target.value)}
              value={formData?.name}
            />
            {errors.name && (
              <div className="error-message flex text-red-500">
                <img className="pr-[10px]" src={error} alt="error-img" />
                {errors.name}
              </div>
            )}
            <Accordion
              label="Set Parameters"
              items={formData?.parameters?.map((param, index) => ({
                title: `Parameter ${
                  param?.parameter?.name ? `- ${param.parameter.name}` : ''
                }`,
                id: param?.id,
                children: generateAccordionChildren(index),
                categoryId: formData?.id,
              }))}
              onAddAccordion={handleAddAccordion}
              onDeleteAccordion={handleDeleteAccordion}
              isSubmitting={isSubmitting}
              criteriaValidationResults={criteriaValidationResults}
            />
          </form>
          <div className="save-btn-section opacity-10 mt-16 border-t border-solid border-1 border-[#151D48]"></div>
          <Button
            handleClick={handleSubmit}
            type="submit"
            name="Save"
            value={params?.categoryId ? 'Update' : 'Save'}
            className="save-btn rounded-[8px] bg-[#DE1186] text-white mt-12 py-[13px] px-[40px]"
          />
        </div>
      )}
    </>
  );
}

export default SetupForm;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';

const CloseProjectPopup = () => {
  const dispatch = useDispatch();
  const [closingNotes, setClosingNotes] = useState('');
  const categoriesResponse = useSelector(selectReview);
  const { assessment } = categoriesResponse;
  const { actions: reviewActions } = useReviewSlice();
  const { actions: modalManagerActions } = useModalManagerSlice();

  const handleCloseProject = () => {
    const flattenedDetails = {
      project: assessment?.assessmentDetails?.id,
      trackStatus: 'CLOSED',
      trackClosed: [
        {
          closingDate: moment().format('YYYY-MM-DD'),
          closingNotes: closingNotes,
        },
      ],
    };
    dispatch(
      reviewActions.updateAssessment({
        trackId: assessment?.assessmentDetails?.id,
        data: flattenedDetails,
      }),
    );
    dispatch(modalManagerActions.closePopUp());
  };

  const handleInputChange = value => {
    setClosingNotes(value);
  };

  return (
    <>
      <div className="flex flex-col w-[500px] closeProjectPopupContainer">
        <div className="flex flex-col">
          <label className="mt-7 mb-4 font-bold text-2xl">
            Project Closing Notes*
          </label>
          <textarea
            className="drp-1 mt-3 w-full font-medium relative px-6 py-5 rounded-md border border-solid border-1 border-[#737791] bg-slate-50"
            rows={5}
            onChange={e => handleInputChange(e.target.value)}
          />
        </div>
        <div className="w-full flex justify-end mt-12 mb-7">
          <button
            className="py-4 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] w-4/12"
            onClick={() => handleCloseProject()}
            disabled={closingNotes.length <= 0}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default CloseProjectPopup;

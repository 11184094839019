// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio[type="radio"] {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border: 1px solid #d9d9d9;
    transform: translateY(1px);

    &::before {
        content: "";
        transform: scale(1);
        transition: 120ms transform ease-in-out;
        border: 1px solid #fdfdfd !important;
    }

    &:checked::before {
        transform: scale(1);
        min-width: 2.15rem;
        min-height: 2.15rem;
        max-width: 0.75rem;
        max-height: 0.75rem;
        background-color: #f6007e;
        border: 1px solid #f6007e;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/Radio/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,0BAA0B;;IAE1B;QACI,WAAW;QACX,mBAAmB;QACnB,uCAAuC;QACvC,oCAAoC;IACxC;;IAEA;QACI,mBAAmB;QACnB,kBAAkB;QAClB,mBAAmB;QACnB,kBAAkB;QAClB,mBAAmB;QACnB,yBAAyB;QACzB,yBAAyB;IAC7B;AACJ","sourcesContent":[".custom-radio[type=\"radio\"] {\n    min-width: 24px;\n    min-height: 24px;\n    max-width: 24px;\n    max-height: 24px;\n    border: 1px solid #d9d9d9;\n    transform: translateY(1px);\n\n    &::before {\n        content: \"\";\n        transform: scale(1);\n        transition: 120ms transform ease-in-out;\n        border: 1px solid #fdfdfd !important;\n    }\n\n    &:checked::before {\n        transform: scale(1);\n        min-width: 2.15rem;\n        min-height: 2.15rem;\n        max-width: 0.75rem;\n        max-height: 0.75rem;\n        background-color: #f6007e;\n        border: 1px solid #f6007e;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

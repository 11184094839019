import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { commonActions } from 'app/common/slices/errorBoundarySlice/selectors';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useCommonSlice } from 'app/common/slices/errorBoundarySlice';
import { API_ERROR_CODES } from 'app/common/constants/constants';

const ErrorBoundary = props => {
  const { children } = props;
  const commonData = useSelector(commonActions);
  const { actions: modalManagerActions } = useModalManagerSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error } = commonData;
  const { actions } = useCommonSlice(); //Don't remove this line as it is needed to call setError in project

  useEffect(() => {
    const res = error?.responseBody || {};
    if (error?.errMsg === 'Failed to fetch') {
      toast.error(
        'Rate limit or network error. Please check your internet connection',
      );
    } else if (res?.status === 401 && res?.name === 'UnauthorizedException') {
      navigate('/login', {
        replace: true,
      });
    } else if (
      res?.status === 403 &&
      (res?.name === API_ERROR_CODES.CategoryUpdateNotAllowed ||
        res?.name === API_ERROR_CODES.CategoryDeleteNotAllowed ||
        res?.name === API_ERROR_CODES.TrackDeleteNotAllowed ||
        res?.name === API_ERROR_CODES.ForbiddenException)
    ) {
      // show option to clone category to make any changes
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'ErrorPopup',
          className: `lg:w-4/12`,
        }),
      );
    } else if (res?.status === 400 && res?.name === 'BadRequestException') {
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'ErrorPopup',
          className: `lg:w-4/12`,
        }),
      );
    }
  }, [error, dispatch, modalManagerActions]);

  return <>{children}</>;
};

export default ErrorBoundary;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tags {
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 6px;
  font-size: 1em;
  line-height: 1.2;
  cursor: text;
  background: white;
  border: 1px solid #0000008c;
}
.react-tags.is-active {
  border-color: #0000008c;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/Setup/components/Criteria/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".react-tags {\n  position: relative;\n  padding-top: 7px;\n  padding-bottom: 7px;\n  border-radius: 6px;\n  font-size: 1em;\n  line-height: 1.2;\n  cursor: text;\n  background: white;\n  border: 1px solid #0000008c;\n}\n.react-tags.is-active {\n  border-color: #0000008c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useSelector } from 'react-redux';
import NavLink from '../NavLink';
import {
  DashboardIcon,
  DashboardIconActive,
  ReviewIcon,
  ReviewIconActive,
  SetupIcon,
  SetupIconActive,
  PermissionIcon,
  PermissionIconActive,
  leftArrowIcon,
  rightArrowIcon,
} from 'app/assets/Common/commonImages';
import { ValidatePermission, USER_ROLES } from 'app/common/services';
import { selectUser } from 'app/common/slices/authslice/selectors';
import './style.css';

function Sidebar(props) {
  const { children, setSidebarOpen, sidebarOpen } = props;
  const userDetails = useSelector(selectUser);
  const pageList = ValidatePermission(
    [
      // {
      //   pageIcon: <DashboardIcon />,
      //   activePageIcon: <DashboardIconActive />,
      //   pageName: 'Dashboard',
      //   // pageLink: '/',
      // },
      {
        pageIcon: <DashboardIcon />,
        activePageIcon: <DashboardIconActive />,
        pageName: 'Dashboard',
        pageLink: '/dashboard-calendar',
        title: 'Dashboard',
      },
      {
        pageIcon: <ReviewIcon />,
        activePageIcon: <ReviewIconActive />,
        pageName: 'Review',
        pageLink: '/review',
        title: 'Review',
      },
      {
        pageIcon: <SetupIcon />,
        activePageIcon: <SetupIconActive />,
        pageName: 'Setup',
        pageLink: '/setup',
        accessTo: USER_ROLES.ADMIN,
        title: 'Setup',
      },
      {
        pageIcon: <PermissionIcon />,
        activePageIcon: <PermissionIconActive />,
        pageName: 'Permission',
        pageLink: '/permission',
        accessTo: USER_ROLES.SUPER_ADMIN,
        title: 'Permission',
      },
      {
        pageIcon: <ReviewIcon />,
        activePageIcon: <ReviewIconActive />,
        pageName: 'Skills',
        pageLink: '/skills',
        title: 'Skills',
        module: 'skill',
      },
      {
        pageIcon: <PermissionIcon />,
        activePageIcon: <PermissionIconActive />,
        pageName: 'Skills Permission',
        pageLink: '/skill-permission',
        accessTo: USER_ROLES.SUPER_ADMIN,
        title: 'Skills Permission',
        module: 'skill',
      },
      // {
      //   pageIcon: <SettingsIcon />,
      //   pageName: 'Settings',
      // pageLink: '/settings',
      // },
    ],
    userDetails?.user?.role,
    userDetails?.user?.skillRole,
  );

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div
        className={`toggle-container  ${
          sidebarOpen ? 'sidebar-open' : 'sidebar-closed'
        }`}
      >
        <button
          className="collapse-Sidebar"
          data-title={sidebarOpen ? 'Collapse Sidebar' : 'Expand Sidebar'}
        >
          {sidebarOpen ? (
            <img src={leftArrowIcon} onClick={toggleSidebar} alt="left" />
          ) : (
            <img src={rightArrowIcon} onClick={toggleSidebar} alt="right" />
          )}
        </button>
      </div>
      <div
        className={
          sidebarOpen ? 'Sidebar-container' : 'sidebar-closed-container'
        }
      >
        {' '}
        {pageList[0].map((page, index) => (
          <NavLink
            key={index}
            pageIcon={page.pageIcon}
            activePageIcon={page.activePageIcon}
            pageName={page.pageName}
            pageLink={page.pageLink}
            sidebarOpen={sidebarOpen}
            title={page.title}
          />
        ))}{' '}
      </div>{' '}
      {children}{' '}
    </>
  );
}

export default Sidebar;

import { put, takeLatest } from 'redux-saga/effects';
// import { request } from 'utils/request';
import { commonActions as actions } from '.';

export function* setLoader() {}

export function* removeLoader() {}

export function* setError(action) {
  yield put(actions.setErrorData(action.payload));
}

// export function* clearErrorData(action) {
//   yield put(actions.setErrorData({}));
// }

export function* setCommonData(action) {}

/**
 * Root saga manages watcher lifecycle
 */
export function* commonSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.setLoader, setLoader);
  yield takeLatest(actions.removeLoader, removeLoader);
  yield takeLatest(actions.setCommonData, setCommonData);
  yield takeLatest(actions.setError, setError);
  // yield takeLatest(actions.clearErrorData, clearErrorData);
}

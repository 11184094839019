import styled from 'styled-components/macro';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  padding: 0 44px;
  box-shadow: 0px 0px 10px 5px #3c609708;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  background: #fff;
  @media (max-width: 770px) {
    flex-direction: row;
    margin-left: auto;
    align-items: center;
    height: auto;
    width: 100%;
  }
`;

export const RightSide = styled.div`
  margin-left: auto;
  display: flex;
  @media (max-width: 768px) {
    margin: 15px 0px 0px 0px;
  }
`;

export const NotificationsContainer = styled.div`
  /* margin-left: 20px; */
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const UserImage = styled.img`
  height: 48px;
  width: 48px;
  margin-left: 20px;
  border-radius: 10px;
`;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
`;

export const UserDetails = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
  height: 48px;
`;

export const UserName = styled.p`
  margin: 0;
  color: var(--greys-blue-grey-900, #151d48);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const UserId = styled.p`
  margin: 0;
  color: #151d48;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
`;

export const DownArrowContainer = styled.div`
  margin-left: 10px;
  width: 16px;
  height: 16px;
`;

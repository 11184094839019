import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Category Name is required'),
  //   parameters: Yup.array().of(
  //     Yup.object().shape({
  //       parameterName: Yup.string().required('Select Parameter is required'),
  //       criteria: Yup.array().of(
  //         Yup.object().shape({
  //           measurementCriteria: Yup.string().required(
  //             'Measurement Criteria is required',
  //           ),
  //           commentsCharacterCount: Yup.number().required(
  //             'Comments Character Count is required',
  //           ),
  //           ratings: Yup.string().required('Ratings is required'),
  //           status: Yup.array()
  //             .of(Yup.string().required('Status is required'))
  //             .min(1, 'At least one status is required'),
  //         }),
  //       ),
  //     }),
  //   ),
});

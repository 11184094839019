import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { permissionActions as actions } from '.';
import { toast } from 'react-toastify';
import { commonActions } from 'app/common/slices/errorBoundarySlice';
import { USER_ROLES } from 'app/common/services';

export function* getPermission(action) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user`;

  try {
    const userRoles = yield call(request, requestURL);
    yield put(actions.gotPermission(userRoles));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* createRoles(action) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user/role`;
  try {
    const { role, userIds } = action.payload;
    const rolesPermission = yield call(request, requestURL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        role,
        userIds,
      }),
    });
    yield put(actions.rolesCreated(rolesPermission));
    yield put(actions.resetState());
    yield put(actions.getPermission());
    if (role === USER_ROLES.NO_ACCESS) {
      toast.success('User role removed!');
    } else {
      toast.success('User role updated!');
    }
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* permissionSaga() {
  yield takeLatest(actions.getPermission, getPermission);
  yield takeLatest(actions.createRoles, createRoles);
}

import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { userAuthFormSaga } from './saga';

export const initialState = {
  user: {
    name: '',
    newerId: '',
    profilePicURL: '',
    role: '',
    skillRole: '',
  },
  error: null,
  loading: false,
};

const slice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    getUser(state) {
      // state.loading = true;
      state.error = null;
    },
    gotUser(state, action) {
      const user = action.payload;
      state.user.name = user.name;
      state.user.newerId = user.newerId;
      state.user.profilePicURL = user.profilePicURL;
      state.user.role = user.role;
      state.user.skillRole = user.skillRole;
      // state.loading = false;
    },
    // authError(state, action) {
    //   state.error = action.payload;
    //   state.loading = true;
    // },
    getLogoutUser(state) {
      state.loading = true;
      state.error = null;
    },
    gotLogoutUser(state) {
      // const user = action.payload;
      state.user = { ...initialState.user };
      // state.loading = false;
    },
    // authLogoutError(state, action) {
    //   state.error = action.payload;
    //   state.loading = false;
    // },
    removeLoader(state) {
      state.loading = false;
    },
  },
});

export const { actions: userAuthActions } = slice;

export const useUserAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userAuthFormSaga });
  return { actions: slice.actions };
};

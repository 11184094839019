import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { calendarActions as actions } from '.';
import { commonActions } from '../../../common/slices/errorBoundarySlice/index';

export function* getTracks(action) {
  yield delay(500);
  const { view, year, selectedYear } = action.payload;
  const requestURL =
    `${process.env.REACT_APP_API_BASE_URL}/track/timelines` +
    (year ? `?year=${year}` : '');
  try {
    const data = yield call(request, requestURL);
    yield put(actions.gotTracks({ data, view, year, selectedYear }));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.gotTracksError('DATA NOT FOUND'));
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotTracksError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotTracksError('RESPONSE ERROR'));
    // }
  }
}

export function* getTimelineReview(action) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/assessment/timelines?trackId=${action.payload.id}`;
  try {
    const data = yield call(request, requestURL);
    yield put(
      actions.gotReviewData({
        id: action.payload.id,
        assessment: data,
      }),
    );
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.gotReviewDataError('DATA NOT FOUND'));
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotReviewDataError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotReviewDataError('RESPONSE ERROR'));
    // }
  }
}
export function* getFollowUpTracks(action) {
  yield delay(500);
  const { year } = action.payload;
  const requestURL =
    `${process.env.REACT_APP_API_BASE_URL}/track/follow-ups` +
    (year ? `?year=${year}` : '');
  try {
    const data = yield call(request, requestURL);
    yield put(actions.gotFollowUpTracks(data));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* calendarFormSaga() {
  yield takeLatest(actions.getTracks, getTracks);
  yield takeLatest(actions.getReviewData, getTimelineReview);
  yield takeLatest(actions.getFollowUpTracks, getFollowUpTracks);
}

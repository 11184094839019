import React, { useEffect } from 'react';
import { Routes as ReactRoutes, Route, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BuListing from '../pages/Review';
import AccountListing from '../pages/Review/components/AccountListing';
import TrackPage from 'app/pages/Review/components/TrackPage';
import Setup from '../pages/Setup';
import SetupForm from 'app/pages/Setup/components/SetupForm';
import Settings from '../pages/Settings';
import AssessmentHandler from 'app/pages/Review/components/AssesmentListing/AssessmentPage';
import { NotFoundPage } from 'app/common/components/NotFoundPage';
import AssesmentListing from '../pages/Review/components/AssesmentListing';
// import { Dashboard } from '../pages/HomePage/Loadable';
import DashboardPage from 'app/pages/DashboardCalendar';
import Permission from '../pages/Permission';
import SkillsPermission from '../pages/SkillsPermission';
import { ValidatePermission } from 'app/common/services';
import { USER_ROLES } from 'app/common/services/permission/permissionConstant';
import { selectUser } from 'app/common/slices/authslice/selectors';
import Login from 'app/common/components/login';
import Skill from 'app/pages/Skill';

export function Routes() {
  const userDetails = useSelector(selectUser);
  const navigate = useNavigate();
  const appRoutes = ValidatePermission(
    [
      {
        path: '/review',
        component: <BuListing />,
      },
      {
        path: 'review/:id',
        component: <AccountListing />,
      },
      {
        // path: '/review/:buId/:accountId',
        path: '/review/account/:accountId',
        component: <TrackPage />,
      },
      {
        path: 'review/track/:trackId',
        component: <AssesmentListing />,
      },
      {
        // path: 'review/:buName/:projectname/:selectedproject/:assessmentId',
        path: 'review/assessment/:assessmentId',
        component: <AssessmentHandler />,
      },
      {
        path: '/setup',
        component: <Setup />,
        accessTo: USER_ROLES.ADMIN,
      },
      {
        path: '/skills',
        component: <Skill />,
        module: 'skill',
      },
      {
        path: 'setup/create',
        component: <SetupForm />,
        accessTo: USER_ROLES.ADMIN,
      },
      {
        // path: 'setup/update/:name/:categoryId',
        path: 'setup/update/:categoryId',
        component: <SetupForm />,
        accessTo: USER_ROLES.ADMIN,
      },
      {
        path: '/dashboard-calendar',
        component: <DashboardPage />,
      },
      {
        path: '/permission',
        component: <Permission />,
        accessTo: USER_ROLES.SUPER_ADMIN,
      },
      {
        path: '/skill-permission',
        component: <SkillsPermission />,
        accessTo: USER_ROLES.SUPER_ADMIN,
        module: 'skill',
      },
      {
        path: '/settings',
        component: <Settings />,
      },
      {
        path: '/login',
        component: <Login />,
      },
      // {
      //   path: '*',
      //   component: <NotFoundPage />,
      // },
    ],
    userDetails?.user?.role,
    userDetails?.user?.skillRole,
  );
  useEffect(() => {
    const noPermittedRoutes = appRoutes[1].filter(
      route => route.path === window.location.pathname,
    );

    if (noPermittedRoutes?.length || window.location.pathname === '/') {
      navigate('/dashboard-calendar');
    }
  }, [appRoutes, navigate]);

  return (
    <ReactRoutes>
      <Route>
        {appRoutes[0].map(route => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Route>
      {userDetails.user.name && <Route path="*" element={<NotFoundPage />} />}
    </ReactRoutes>
  );
}

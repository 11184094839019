// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-search .react-tags{
    width: 100%;
    border: 0;
    background: none;
    padding-left: 1.1em;
    padding-right: 1.1em;
}
.wrap-search .selected-option{
    height: 100%;
}
.wrap-search .caret {
    margin-right: 1.1em;
}
.wrap-search .selected-text{
    width: 100%;
    text-align: right;
    padding-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Permission/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,SAAS;IACT,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".wrap-search .react-tags{\n    width: 100%;\n    border: 0;\n    background: none;\n    padding-left: 1.1em;\n    padding-right: 1.1em;\n}\n.wrap-search .selected-option{\n    height: 100%;\n}\n.wrap-search .caret {\n    margin-right: 1.1em;\n}\n.wrap-search .selected-text{\n    width: 100%;\n    text-align: right;\n    padding-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import HomePageCards from 'app/common/components/HomePageCards';
import { useCategorySlice } from 'app/pages/Setup/components/SetupForm/categoryslice';
import { selectCategory } from 'app/pages/Setup/categoryslice/selectors';
import Loader from '../../common/components/Loader';
import { LoaderContainer } from './style';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import middleast from 'app/assets/middleast.svg';
import Sign from 'app/assets/plus-sign.svg';
import Button from '../../common/components/Button';

function Setup() {
  const heading = 'Setup Assessment Categories & Parameter';
  const subheading =
    'Create Or Oversee Parameters, Their Corresponding Values, & Additional Inputs.';

  const { actions } = useCategorySlice();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCategory());
    dispatch(actions.resetCategoryCreatedOrUpdatedState());
    dispatch(actions.resetCategoryClonedState());
  }, [actions, dispatch]);
  const categoriesResponse = useSelector(selectCategory);
  const { category: categories, loading } = categoriesResponse;
  const categoriesList = categories.map(category => ({
    id: category._id,
    title: category.name,
    background: '#F1F3FD',
    logo: middleast,
    questions: category?.parameters && category?.parameters.length,
  }));

  return (
    <>
      <Helmet>
        <title>Setup</title>
        <meta name="description" content="Project Health Dashboard" />
      </Helmet>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <HomePageCards
            data={categoriesList}
            navbar={true}
            viewRoute="setup/update"
            heading={heading}
            subheading={subheading}
            buttontitle="View"
            createnewbutton={true}
          />
          <div className="pl-[32px] pb-[32px]">
            <Button
              handleClick={() => {
                dispatch(
                  modalManagerActions.openPopUp({
                    modalType: 'CREATE_PARAMETERS',
                    heading: 'Add New Parameters',
                    modalClassName: 'w-[48%]',
                  }),
                );
              }}
              name="Add New Parameters"
              value="parameter"
              className="add-btn flex justify-center gap-3 rounded-[8px] font-medium text-[16px] text-[#DE1186] tracking-wide border border-solid border-1 border-[#DE1186] py-5 px-9"
              image={Sign}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Setup;

import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { categoryActions as actions } from '.';

export function* getCategory() {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category/list`;
  try {
    const categories = yield call(request, requestURL);
    yield put(actions.gotCategory(categories));
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.categoryError('CATEGORIES NOT FOUND'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.categoryError('RATE LIMIT'));
    } else {
      yield put(actions.categoryError('RESPONSE ERROR'));
    }
  }
}

export function* categoryFormSaga() {
  yield takeLatest(actions.getCategory, getCategory);
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import DateAccordion from 'app/common/components/DateAccordion';
import 'react-datepicker/dist/react-datepicker.css';

const FollowUpDatesPopup = () => {
  const dispatch = useDispatch();
  const [reviewDetails, setReviewDetails] = useState({});
  const [recommendationLinkError, setRecommendationLinkError] = useState('');
  const [endDate, setEndDate] = useState();
  const [followUpDates, setFollowUpDates] = useState([]);
  const { actions: modalManagerActions } = useModalManagerSlice();
  const { actions: reviewActions } = useReviewSlice();
  const categoriesResponse = useSelector(selectReview);
  const { assessment } = categoriesResponse;

  const fields = [
    {
      text: 'Recommendation Link',
      type: 'url',
      value: assessment?.assessmentDetails?.recommendationLink,
    },
    {
      text: 'Learnings',
      type: 'textarea',
      value: assessment?.assessmentDetails?.learnings,
    },
    {
      text: 'Review Notes',
      type: 'textarea',
      value: assessment?.assessmentDetails?.reviewNotes,
    },
  ];

  // const handleAddReview = () => {
  //   const flattenedDetails = {
  //     project: reviewData?.selectedProject.id,
  //     agileOwner: assessmentDetails.AgileOwner.selectedEmail.map(
  //       email => email.value,
  //     ),
  //     tagOwner: assessmentDetails.TagOwner.selectedEmail.map(
  //       email => email.value,
  //     ),
  //     projectOwner: assessmentDetails.ProjectOwner.selectedEmail.map(
  //       email => email.value,
  //     ),
  //     trackName: assessmentDetails.trackName,
  //   };
  // }

  const handleInputChange = (field, value) => {
    if (field === 'RecommendationLink') {
      if (!isValidURL(value)) {
        setRecommendationLinkError(
          'Enter a valid non-empty URL starting with https://',
        );
      } else {
        setRecommendationLinkError('');
      }
    }
    setReviewDetails(prevDetails => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const isValidURL = url => {
    const urlPattern = /^https:\/\/.+/;
    return urlPattern.test(url);
  };

  const handleAddReview = () => {
    let isDatesValid = true;
    for (let i = 0; i < followUpDates?.length; i++) {
      const dateA = followUpDates?.[i]?.selectedDate;
      const dateB = followUpDates?.[i + 1]?.selectedDate;
      if (dateA > dateB) {
        isDatesValid = false;
        toast.error(
          'Next follow up date should always be greater than the previous follow up date',
        );
        return;
      }
    }
    const flattenedDetails = {
      followUps: followUpDates?.map(date => {
        return {
          nextFollowUp: moment(date?.selectedDate).format('YYYY-MM-DD'),
        };
      }),
      endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
      //   reviewStatus: 'COMPLETED',
    };
    if (
      new Date(endDate).getTime() <=
      new Date(assessment?.assessmentDetails?.startDate).getTime()
    ) {
      toast.error('End date should always be greater than Start date');
      return;
    }
    dispatch(
      reviewActions.updateAssessment({
        trackId: assessment?.assessmentDetails?.id,
        data: flattenedDetails,
        msg: 'Upcoming Events have been added',
      }),
    );
    dispatch(modalManagerActions.closePopUp());
  };

  const handleFollowUpdates = data => {
    setFollowUpDates([...followUpDates, data]);
  };

  const handleDateChange = data => {
    setFollowUpDates(data);
  };

  useEffect(() => {
    if (
      !assessment?.assessmentDetails?.followUps ||
      assessment?.assessmentDetails?.followUps?.length === 0
    ) {
      setFollowUpDates([
        {
          id: 1,
          selectedDate: new Date(
            new Date().getTime() + 90 * 24 * 60 * 60 * 1000,
          ),
          minDate: new Date().getTime() + 1 * 24 * 60 * 60 * 1000,
        },
      ]);
    } else {
      const _data = assessment?.assessmentDetails?.followUps?.map(
        (date, index) => {
          return {
            id: index + 1,
            selectedDate: moment(date.nextFollowUp).format('DD-MMMM-YYYY'),
            minDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
          };
        },
      );
      setFollowUpDates(_data);
    }
  }, [assessment.assessmentDetails.followUps]);

  return (
    <div className="flex flex-col w-[500px]">
      <DateAccordion
        setFollowUpDates={setFollowUpDates}
        followUpDates={followUpDates}
        handleFollowUpdates={handleFollowUpdates}
        handleDateChange={handleDateChange}
      />
      <div className="w-full flex justify-end mt-12 mb-7">
        <button
          className="py-4 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] w-4/12"
          onClick={handleAddReview}
        >
          {/* {assessment.assessmentDetails.reviewStatus === 'COMPLETED'
            ? 'Update'
            : */}
          Submit
          {/* } */}
        </button>
      </div>
    </div>
  );
};

export default FollowUpDatesPopup;

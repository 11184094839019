import React from 'react';
import { Link } from 'react-router-dom';
// import './style.css';

function Breadcrumb({ heading, items, classToAdd }) {
  return (
    <div className={`breadcrumb-container ${classToAdd || ''}`}>
      {heading && (
        <div className="flex">
          <h2 className="breadcrumb-heading text-[#151D48] text-[28px] mb-4 font-semibold">
            {heading}
          </h2>
        </div>
      )}
      <div className="breadcrumbs">
        {items?.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span className="breadcrumb-divider"> {'>'} </span>}
            {item.link ? (
              item.state ? (
                <Link
                  to={item.link}
                  state={item.state}
                  className="breadcrumb-link text-[#151D48] text-[16px] font-normal hover:no-underline"
                >
                  {item.text}
                </Link>
              ) : (
                <Link
                  to={item.link}
                  className="breadcrumb-link text-[#151D48] text-[16px] font-normal hover:no-underline"
                >
                  {item.text}
                </Link>
              )
            ) : (
              <span className="breadcrumb-text text-[#151D48] text-[16px] font-normal opacity-50 ">
                {item.text}
              </span>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Breadcrumb;

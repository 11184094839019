import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import HomePageCards from 'app/common/components/HomePageCards';
import { useDispatch, useSelector } from 'react-redux';
import { middleast } from 'app/assets/Common/commonImages';

import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from 'app/pages/Setup/style';

function BuListing(props) {
  const heading = 'Review';
  const subheading = 'Choose Business Unit';

  const { actions } = useReviewSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getReviewCategory());
  }, [actions, dispatch]);

  const reviewsResponse = useSelector(selectReview);
  const { businessUnits, loading } = reviewsResponse;

  const businessUnitsList = businessUnits.flat().map(businessUnit => {
    const id = businessUnit._id || null;
    const name = businessUnit.businessUnitName || 'Unknown';
    const totalNumberOfProjects = businessUnit.totalNumberOfProject || 0;
    const totalNumberOfClients = businessUnit.totalNumberOfClients || 0;

    return {
      id,
      title: name,
      background: '#F1F3FD',
      projects: totalNumberOfProjects,
      clients: totalNumberOfClients,
      logo: middleast,
    };
  });
  return (
    <>
      <Helmet>
        <title>Review</title>
        <meta name="description" content="Project Health Dashboard" />
      </Helmet>

      {loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <>
          <HomePageCards
            data={businessUnitsList}
            navbar={true}
            reviewpagelisting={true}
            viewRoute={`review`}
            heading={heading}
            subheading={subheading}
            buttontitle="View"
          />
        </>
      )}
    </>
  );
}

export default BuListing;

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParamSlice } from './slice';
import Dropdown from 'app/common/components/DropDown';
import { LoaderContainer } from 'app/pages/Setup/style';
import Loader from 'app/common/components/Loader';
import { paramList } from './selectors';
import Button from 'app/common/components/Button';
import { trash } from 'app/assets/Common/commonImages';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services/permission/permissionConstant';

const CreateParameters = () => {
  const { actions } = useParamSlice();
  const dispatch = useDispatch();

  const [value, setValue] = useState();
  const [newParam, setNewParam] = useState();
  const response = useSelector(paramList, shallowEqual || []);
  const { loading, list, dataFetched } = response;

  useEffect(() => {
    if (!dataFetched) {
      dispatch(actions.getParamList());
    }
  }, [actions, dispatch, dataFetched]);

  const categoryTags = [
    'Technical Architecture',
    'Security',
    'Project Governance',
    'Frontend',
  ];
  const handleAdd = () => {
    const formData = [
      {
        name: newParam,
        tag: value,
      },
    ];
    dispatch(actions.addParam({ formData }));
    setNewParam('');
    setValue('');
  };

  const handleDeleteParameter = paramterId => {
    if (paramterId) {
      const userConfirmed = window.confirm('Are you sure you want to delete?');
      if (userConfirmed) {
        dispatch(actions.deleteParam(paramterId));
      } else {
        return;
      }
    }
  };

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <div>
          <div className="action-bar w-full flex justify-between border-b mr-72 pb-6">
            <input
              type="text"
              className="drp-1 font-medium relative px-6 py-4  rounded-md border border-solid border-1 border-[#737791] w-full md:w-full lg:w-7/12"
              value={newParam}
              onChange={e => setNewParam(e.target.value)}
              placeholder="Enter new parameter ( Required )"
            />
            <Dropdown
              // options={response.list?.map(item => item.tag)}
              options={categoryTags}
              onSelect={option => setValue(option)}
              selectedOption={value}
              className="drp-1 relative text-xl px-6 py-4 mx-5 rounded-md border border-solid border-1 border-[#737791] w-full md:w-full lg:w-3/12"
              optionsClassName="custom-options1 !w-full"
              placeholder="Tags ( Optional )"
            />
            <button
              className={`py-2 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] ease-in-out	duration-200	transition ${
                !newParam ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={handleAdd}
            >
              Add
            </button>
          </div>
          <span className="inline-block font-medium text-2xl mt-6">
            Parameters already we have :
          </span>
          <ul className="border param-list h-[30rem] my-4 overflow-auto">
            {list?.map(item => {
              return (
                <li
                  key={item?.id || item?.name}
                  className="flex justify-between items-end p-4 border-b border-[#fff] hover:border-b hover:border-[#ccc] transition"
                >
                  <span>
                    {item.tag ? `${item.name} (${item.tag})` : `${item.name}`}
                  </span>
                  {/* <button
                    className="py-2 px-4 rounded-md font-medium text-xl border border-[#de1186] text-[#de1186] hover:bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] ease-in-out	duration-200	transition"
                    onClick={() => handleDeleteParameter(item.id)}
                  >
                    Delete
                  </button> */}
                  <Permission accessTo={USER_ROLES.ADMIN}>
                    <Button
                      name=""
                      handleClick={() => handleDeleteParameter(item.id)}
                      className="trash w-12 mr-0"
                      image={trash}
                    />
                  </Permission>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default CreateParameters;

import { React, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import { modalManagerActions } from 'app/common/components/Modals/ModalManager/modalSlice';
import {
  deleteIcon,
  addMore,
  iconBlue,
  threeDots,
} from 'app/assets/Common/commonImages';
import Dropdown from 'app/common/components/DropdownNew';
import { impactOptions, findingOptions } from 'app/common/constants/constants';
import Button from 'app/common/components/Button';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services';
import ActionItemTable from './ActionItemTable';
import EditableTextarea from 'app/common/components/EditableTextarea';
import { handleDotsClick } from 'app/common/utils/helperFn';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from 'app/pages/Setup/style';
import './style.css';

function RecommendationTable(props) {
  const dispatch = useDispatch();
  const { actions } = useReviewSlice();
  const userResp = useSelector(selectReview);
  const { loaderObj, actionLoaderObj, editMode } = userResp;
  const {
    assmntRecommendationData,
    addMoreInput,
    deleteInput,
    recommendationIdx,
    addMoreRow,
    trackId,
    deleteRowandTable,
    handleInputChange,
    onDropdownChange,
    editHeading,
    editrecommendationtable,
    setRecommDisabled,
    recommDisabled,
  } = props;

  const [showActionItem, setShowActionItem] = useState(false);
  const [actionDisabled, setActionDisabled] = useState(true);
  const tableDisabled = editMode[assmntRecommendationData?._id]
    ? recommDisabled[assmntRecommendationData?._id] === true &&
      !(editMode[assmntRecommendationData?._id] === true)
    : recommDisabled[assmntRecommendationData?._id] === true;
  const handleSave = recData => {
    if (validateFields(recData)) {
      const recommendationId = recData._id;
      const recommendationData = recData;
      const recommDisabledObj = { ...recommDisabled };
      dispatch(
        actions.getRecommendationData({
          trackId,
          recommendationId,
          recommendationData,
        }),
      );
      recommDisabledObj[recData?._id] = true;
      setRecommDisabled(recommDisabledObj);
    } else {
      toast.error('Please fill out all the fields.');
    }
  };
  const validateFields = data => {
    let isValid = true;
    let newErrors = {};

    data.parameters.forEach((row, rowIndex) => {
      if (!row.parameter || row.parameter.trim() === '') {
        isValid = false;
        newErrors[`parameter-${rowIndex}`] = true;
      }

      row.keyfindings.forEach((finding, findingIndex) => {
        if (!finding.finding || finding.finding.trim() === '') {
          isValid = false;
          newErrors[`keyfindings-${rowIndex}-${findingIndex}`] = true;
        }
      });

      // row.recommendations.forEach((rec, recIndex) => {
      //   if (!rec.recommendation || rec.recommendation.trim() === '') {
      //     isValid = false;
      //     newErrors[`recommendations-${rowIndex}-${recIndex}`] = true;
      //   }
      // });

      // if (!row.remarks || row.remarks.trim() === '') {
      //   isValid = false;
      //   newErrors[`remarks-${rowIndex}`] = true;
      // }
    });
    return isValid;
  };

  const deleteRecomendationTable = reqdata => {
    const id = reqdata._id;
    const name = reqdata.name;
    try {
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'ConfirmPopup',
          modalClassName: `w-[500px] rounded-xl`,
          data: { id, trackId, name },
        }),
      );
    } catch (e) {}
  };

  const editChildState = actionItemData => {
    setActionDisabled(false);
  };

  const deleteData = (type, idToDelete) => {
    dispatch(
      actions.deleteRecommendationData({
        trackId,
        idToDelete,
        type,
        recommTableId: assmntRecommendationData?._id,
        assmntRecommendationData,
      }),
    );
  };

  const handleAddActionItemClick = () => {
    setShowActionItem(true);
  };

  const columns = [
    {
      dataField: 'parameter',
      text: 'Criteria',
      formatExtraData: tableDisabled,
      formatter: (cell, row, rowIndex) => (
        <div className="criteria-container">
          <EditableTextarea
            type="text"
            name="criteria"
            value={cell}
            label="Criteria"
            maxLength={50}
            onInputChange={event => {
              handleInputChange(
                'parameter',
                recommendationIdx,
                row?._id,
                rowIndex,
                event.currentTarget.innerHTML || '',
              );
            }}
            disabled={tableDisabled}
          />
        </div>
      ),
    },
    {
      dataField: 'keyfindings',
      text: 'Key Findings',
      formatExtraData: tableDisabled,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            {cell.length > 0
              ? cell.map((val, idx) => (
                  <div
                    className="keyfindings-container"
                    key={`${rowIndex}-${row?._id}-${idx}`}
                  >
                    {val?.criteria ? (
                      <span
                        className="mytooltip"
                        data-html2canvas-ignore="true"
                      >
                        <img
                          src={iconBlue}
                          className="tooltip-container"
                          alt="iconBlue-btn"
                          data-title="my custom"
                        />
                        <span className="tooltiptext">{val?.criteria}</span>
                      </span>
                    ) : null}

                    <div className="keyfindings-input">
                      <EditableTextarea
                        type="text"
                        name="keyfindings"
                        // key={val?._id}
                        label="Key Findings"
                        value={val?.finding}
                        onInputChange={event => {
                          handleInputChange(
                            'keyfindings',
                            recommendationIdx,
                            row?._id,
                            rowIndex,
                            event.currentTarget.innerHTML || '',
                            idx,
                          );
                        }}
                        disabled={tableDisabled}
                      />
                      {!tableDisabled && (
                        <button
                          onClick={() => {
                            row.keyfindings[idx]._id
                              ? deleteData(
                                  'keyfindings',
                                  row.keyfindings[idx]._id,
                                )
                              : deleteInput(
                                  recommendationIdx,
                                  'keyfindings',
                                  row?._id,
                                  rowIndex,
                                  idx,
                                );
                          }}
                        >
                          <img src={deleteIcon} alt="del-btn" />
                        </button>
                      )}
                    </div>
                    {!tableDisabled ? (
                      <Dropdown
                        options={findingOptions}
                        onSelect={value =>
                          onDropdownChange(
                            'keyfindings',
                            recommendationIdx,
                            row?._id,
                            rowIndex,
                            idx,
                            value,
                          )
                        }
                        selectedOption={val?.status || findingOptions[0]?.value}
                        className={`relative option-dropdown align-middle drp-2  ml-4  h-full`}
                        optionsClassName="custom-options1 border-none p-0"
                        // placeholder="Select"
                        form="keyfindings"
                      />
                    ) : (
                      <Dropdown
                        isDisabled={true}
                        onSelect={() => {}}
                        options={findingOptions}
                        selectedOption={val?.status || findingOptions[0]?.value}
                        className={`relative disabled-dropdown align-middle drp-2  ml-4  h-full`}
                        form="keyfindings"
                      />
                    )}
                  </div>
                ))
              : null}
            {!tableDisabled && (
              <div className="add-key-findings-btn">
                <img
                  src={addMore}
                  alt="addmore-btn"
                  onClick={() => {
                    addMoreInput(
                      recommendationIdx,
                      'keyfindings',
                      row?._id,
                      rowIndex,
                    );
                  }}
                />
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'recommendations',
      text: 'Recommendation',
      formatExtraData: tableDisabled,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            {cell.map((val, idx) => (
              <div
                key={`${val?._id}- ${idx}`}
                className="recommendation-container"
              >
                <div className="recommendation-input">
                  <EditableTextarea
                    // key={val?._id}
                    type="text"
                    name="recommendation"
                    value={val?.recommendation}
                    label="Recommendation"
                    className="recommendation-textarea"
                    onInputChange={event => {
                      handleInputChange(
                        'recommendations',
                        recommendationIdx,
                        row?._id,
                        rowIndex,
                        event.currentTarget.innerHTML || '',
                        idx,
                      );
                    }}
                    required
                    disabled={tableDisabled}
                  />
                  {!tableDisabled && (
                    <button
                      onClick={() => {
                        row.recommendations[idx]._id
                          ? deleteData(
                              'recommendations',
                              row.recommendations[idx]._id,
                            )
                          : deleteInput(
                              recommendationIdx,
                              'recommendations',
                              row?._id,
                              rowIndex,
                              idx,
                            );
                      }}
                    >
                      <img src={deleteIcon} alt="del-btn" />
                    </button>
                  )}
                </div>
                {!tableDisabled ? (
                  <Dropdown
                    options={impactOptions}
                    onSelect={value =>
                      onDropdownChange(
                        'recommendations',
                        recommendationIdx,
                        row?._id,
                        rowIndex,
                        idx,
                        value,
                      )
                    }
                    selectedOption={val?.impact || impactOptions[0]?.value}
                    className={`relative option-dropdown align-middle drp-2  ml-4  h-full`}
                    optionsClassName="custom-options1  border-none p-0"
                    form="recommendations"
                  />
                ) : (
                  <Dropdown
                    isDisabled={true}
                    onSelect={() => {}}
                    options={impactOptions}
                    selectedOption={val?.impact || impactOptions[0]?.value}
                    className={`relative disabled-dropdown align-middle drp-2  ml-4  h-full`}
                    form="recommendations"
                  />
                )}
              </div>
            ))}
            {!tableDisabled && (
              <div className="addmore-recommendations-btn">
                <img
                  src={addMore}
                  alt="addmore-btn"
                  onClick={() =>
                    addMoreInput(
                      recommendationIdx,
                      'recommendations',
                      row?._id,
                      rowIndex,
                    )
                  }
                />
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'remarks',
      text: 'Remarks',
      formatExtraData: tableDisabled,
      formatter: (cell, row, rowIndex) => (
        <div className="add-remarks-container">
          <>
            <EditableTextarea
              type="text"
              name="remarks"
              value={cell}
              label="Remarks"
              className={'w-full add-remarks '}
              onInputChange={event => {
                handleInputChange(
                  'remarks',
                  recommendationIdx,
                  row?._id,
                  rowIndex,
                  event.currentTarget.innerHTML || '',
                );
              }}
              disabled={tableDisabled}
            />
            {!tableDisabled && (
              <div className="pl-[10px]">
                <img
                  src={threeDots}
                  alt="view"
                  className="cursor-pointer h-[18px] mt-2.5"
                  onClick={event => {
                    handleDotsClick(
                      event,
                      assmntRecommendationData?._id + '-' + rowIndex,
                    );
                  }}
                />

                <div
                  id={assmntRecommendationData?._id + '-' + rowIndex}
                  className="options-container three-dots bg-white text-black p-4 hidden inline-grid absolute translate-x-7 -translate-y-5 whitespace-nowrap rounded bg-white px-2 py-1 text-black shadow-xl"
                >
                  <div
                    className="border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
                    onClick={() => {
                      if (row._id) {
                        deleteData('parameter', row?._id);
                      } else {
                        deleteRowandTable(
                          'row',
                          recommendationIdx,
                          row?._id,
                          rowIndex,
                        );
                      }
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      ),
    },
    {
      dataField: 'uniquekey',
      text: '',
      hidden: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <>{`${
            row?._id || row?.parameter
          }-${rowIndex}-${recommendationIdx}`}</>
        );
      },
    },
  ];

  return (
    <div>
      {loaderObj[assmntRecommendationData?._id] === true ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <>
          <div className="recommendation-heading">
            <EditableTextarea
              placeholder="Recommendation Heading"
              type="text"
              name="heading"
              value={assmntRecommendationData?.name}
              maxLength={150}
              onInputChange={event => {
                editHeading(
                  recommendationIdx,
                  event.currentTarget.innerHTML || '',
                );
              }}
              disabled={tableDisabled}
            />
            {!tableDisabled && (
              <button
                onClick={() => {
                  deleteRecomendationTable(assmntRecommendationData);
                }}
              >
                <img src={deleteIcon} alt="del-row-btn" />
              </button>
            )}
          </div>
          <div className={'table-recommendation'}>
            <div className="rounded-2xl custom-card relative p-10 pb-12 shadow-md pt-6 flex-1 block bg-[#f6f8ff]">
              <BootstrapTable
                keyField="uniquekey"
                data={assmntRecommendationData?.parameters}
                columns={columns}
                bordered={false}
              />
              {!tableDisabled && (
                <div className="flex justify-center">
                  <button
                    className="add-recommendation flex"
                    onClick={() => addMoreRow(recommendationIdx)}
                  >
                    <img src={addMore} alt="addmore-btn" />{' '}
                    <p className="ml-5">Add More</p>
                  </button>
                </div>
              )}
              <Permission accessTo={USER_ROLES.ADMIN}>
                <div
                  className="flex justify-end mb-[10px]"
                  data-html2canvas-ignore="true"
                >
                  {tableDisabled ? (
                    <Button
                      handleClick={() => {
                        if (!actionDisabled) {
                          toast.error('Please save the data');
                          return;
                        }
                        editrecommendationtable(assmntRecommendationData?._id);
                      }}
                      name="Edit"
                      className="border bg-[#f6007e] py-4 px-12 font-medium  text-white rounded-xl"
                      id="edit-recommendation-btn"
                    />
                  ) : (
                    <Button
                      handleClick={() => {
                        handleSave(assmntRecommendationData);
                        // editrecommendationtable(assmntRecommendationData?._id);
                      }}
                      name="Save"
                      className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl "
                    />
                  )}
                </div>
              </Permission>
            </div>
            <div className="relative">
              {!showActionItem &&
              assmntRecommendationData?.actionitems?.length === 0 ? (
                <Permission accessTo={USER_ROLES.ADMIN}>
                  <span
                    className="add-action-btn w-[100%]"
                    data-html2canvas-ignore="true"
                  >
                    <Button
                      handleClick={() => {
                        handleAddActionItemClick();
                      }}
                      name="Add Action Item"
                      className="text-[#de1186] ml-[40%] mt-[20px] py-4 px-12 font-medium text-xl rounded-xl mb-8 mr-4 border border-solid border-[#de1186]"
                    />
                  </span>
                </Permission>
              ) : (
                <>
                  {actionLoaderObj[assmntRecommendationData?._id] === true ? (
                    <LoaderContainer>
                      <LoaderComponent />
                    </LoaderContainer>
                  ) : (
                    <ActionItemTable
                      actionItemData={assmntRecommendationData}
                      tableDisabled={tableDisabled}
                      actionDisabled={actionDisabled}
                      setActionDisabled={setActionDisabled}
                      editChildState={editChildState}
                    />
                  )}
                  {/* <ActionItemTable
                    actionItemData={assmntRecommendationData}
                    tableDisabled={tableDisabled}
                    actionDisabled={actionDisabled}
                    setActionDisabled={setActionDisabled}
                    editChildState={editChildState}
                  /> */}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default RecommendationTable;

import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { request } from 'app/common/utils/request';
import { paramActions as actions } from './slice';
import { API_CONSTANTS } from 'app/common/constants/constants';
import { commonActions } from 'app/common/slices/errorBoundarySlice/index';

/**
 * get all parameter list request/response handler
 */
export function* getParameters() {
  yield delay(500);
  yield put(actions.setLoader());
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.PARAMETERS}`;
  try {
    const user = yield call(request, requestURL);
    yield put(actions.gotParamList(user));
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.setCategoryError('API NOT FOUND'));
      toast.error('Api not found!');
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.setCategoryError('RATE LIMIT'));
      toast.error('Rate limit or network error');
    } else {
      yield put(actions.setCategoryError('RESPONSE ERROR'));
      toast.error('Response error');
    }
  }
}

/**
 * Delete parameter by id
 */
export function* deleteParam(action) {
  const paramterId = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.PARAMETER}/${paramterId}`;
  try {
    yield put(actions.setLoader());
    yield call(request, requestURL, { method: 'DELETE' });
    yield put(actions.updateParamList(paramterId));
    toast.success('Parameter Deleted!');
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

/**
 * Create new parameter
 */
export function* addParam(action) {
  const { formData } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.PARAMETERS}`;
  try {
    yield put(actions.setLoader());
    const paramData = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(actions.gotParamList(paramData));
    toast.success('Parameter Created!');
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.setCategoryError('API NOT FOUND'));
      toast.error('Api not found!');
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.setCategoryError('RATE LIMIT'));
      toast.error('Rate limit or network error');
    } else {
      yield put(actions.setCategoryError('RESPONSE ERROR'));
      toast.error('Response error');
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* createParamActions() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getParamList, getParameters);
  yield takeLatest(actions.deleteParam, deleteParam);
  yield takeLatest(actions.addParam, addParam);
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.menu_mobile .mobile_menu_icon .menu_lines .menu_line {
    height: 2px;
    width: 90%;
    display: block;
    background: #2E1C41;
    margin-bottom: 2px;
}

.menu_mobile{
    width: 20px;
    position: absolute;
    left: 2%;
    top: 8%;
    display: none;
}
.mobile_menu_icon{
    height: 30px;
    width: 30px;
    background-color: #fff;
    padding: 7px;
    
}

.your-custom-options-class{
    box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.3)
}

.your-custom-options-class:after {
    content: '';
    border-bottom: 7px solid #ffff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    bottom: 4.7rem;
    right: 1rem;
  }`, "",{"version":3,"sources":["webpack://./src/app/common/components/Header/style.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,UAAU;IACV,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,aAAa;AACjB;AACA;IACI,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,YAAY;;AAEhB;;AAEA;IACI;AACJ;;AAEA;IACI,WAAW;IACX,8BAA8B;IAC9B,kCAAkC;IAClC,mCAAmC;IACnC,kBAAkB;IAClB,cAAc;IACd,WAAW;EACb","sourcesContent":["\n.menu_mobile .mobile_menu_icon .menu_lines .menu_line {\n    height: 2px;\n    width: 90%;\n    display: block;\n    background: #2E1C41;\n    margin-bottom: 2px;\n}\n\n.menu_mobile{\n    width: 20px;\n    position: absolute;\n    left: 2%;\n    top: 8%;\n    display: none;\n}\n.mobile_menu_icon{\n    height: 30px;\n    width: 30px;\n    background-color: #fff;\n    padding: 7px;\n    \n}\n\n.your-custom-options-class{\n    box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.3)\n}\n\n.your-custom-options-class:after {\n    content: '';\n    border-bottom: 7px solid #ffff;\n    border-left: 7px solid transparent;\n    border-right: 7px solid transparent;\n    position: absolute;\n    bottom: 4.7rem;\n    right: 1rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card-layout {
  /* padding: 26px 27px 90px 27px;
  width: 320px;
  position: relative; */
  position: relative;
  padding: 26px;
  border-radius: 16px;
  background: #f1f3fd;
  display: flex;
  flex-direction: column;
  min-height: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/Review/components/AccountListing/style.css"],"names":[],"mappings":";AACA;EACE;;uBAEqB;EACrB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":["\n.card-layout {\n  /* padding: 26px 27px 90px 27px;\n  width: 320px;\n  position: relative; */\n  position: relative;\n  padding: 26px;\n  border-radius: 16px;\n  background: #f1f3fd;\n  display: flex;\n  flex-direction: column;\n  min-height: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const { checkValid } = require('./checkValidToken');
class ResponseError extends Error {
  constructor(response) {
    super(response.statusText);
    this.response = response;
    this.responseBody = null;
  }
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  error.responseBody = await parseJSON(response);
  throw error;
}

async function refreshToken(email) {
  const url = `${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token?email=${email}`;
  return fetch(url, {
    withCredntials: true,
    credentials: 'include',
  });
}

async function request(url, options) {
  const resp = await checkValid();
  if (resp.isExpired && resp.email) await refreshToken(resp.email);

  const fetchResponse = await fetch(url, {
    ...options,
    withCredntials: true,
    credentials: 'include',
  });
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}

module.exports = {
  ResponseError,
  request,
};

import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './table.css';

function Table({
  data,
  columns,
  className = '',
  defaultArray = [],
  needPagination = false,
}) {
  return (
    <>
      <div className={className}>
        <BootstrapTable
          keyField="_id"
          data={data}
          columns={columns}
          bordered={false}
          defaultSorted={defaultArray}
          pagination={needPagination === true ? paginationFactory() : null}
        />
      </div>
    </>
  );
}

export default Table;

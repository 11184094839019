import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { categoryFormSaga } from './saga';

export const initialState = {
  category: [],
  error: null,
  loading: false,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getCategory(state, action) {
      state.loading = true;
      state.error = null;
    },
    gotCategory(state, action) {
      const categories = action.payload;
      state.category = categories;
      state.loading = false;
    },
    categoryError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: categoryActions } = slice;

export const useCategorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: categoryFormSaga });
  return { actions: slice.actions };
};

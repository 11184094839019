import React from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNewButton from 'app/pages/Setup/components/CreateNewButton';
import { arrow, star, ratingIcon } from '../../../assets/Common/commonImages';
import Breadcrumb from '../Breadcrumb';
import CardsNavbar from '../CardsNavbar';
import { isEmpty, slug } from 'app/common/utils/helperFn';
import {
  Card,
  Header,
  DigitBox,
  Rating,
  OtherButton,
  ViewButton,
  Buttontitlespan,
  Questionspan,
} from './style';

export default function HomePageCards(props) {
  const {
    data,
    navbar,
    reviewpagelisting,
    viewRoute,
    reviewrating,
    heading,
    subheading,
    createnewbutton,
    partiallyCompleted,
    ratingHeader,
    buttontitle,
    className = '',
    historyState,
  } = props;

  const navigate = useNavigate();

  const handleCardClick = (cardId, cardData, buttontitle) => {
    if (viewRoute && historyState && cardData) {
      const encodedUrl = `/${slug(viewRoute)}/${slug(
        cardData.title,
      )}/${cardId}`;
      navigate(encodedUrl, {
        state: {
          projectId: cardData?.projectId,
          reviewDate: cardData?.reviewDate || new Date().toISOString(),
          // buttonClicked: buttontitle,
        },
      });
    } else if (viewRoute) {
      const encodedUrl = `/${slug(viewRoute)}/${cardId}`;
      navigate(encodedUrl);
      window.scrollTo(0, 0);
    }
  };

  const breadcrumbItems = [
    { text: 'Setup', link: '/' },
    { text: 'Create New' },
  ];
  const DynamicStyledDiv = buttontitle === 'View' ? ViewButton : OtherButton;
  return (
    <div className={`p-[32px] ${className}`}>
      {!navbar && <Breadcrumb heading="OTT Dev" items={breadcrumbItems} />}
      {!isEmpty(data) && (
        <CardsNavbar
          navbaricons={false}
          heading={heading}
          subheading={subheading}
        />
      )}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8">
        {data?.map((card, index) => (
          <Card
            key={index}
            // className={`bg-[${card?.reviews ? '#FDF4F1' : card?.color}]`}
          >
            <Header>
              {reviewrating ? (
                <div className="px-12 py-4 rounded-3xl shadow-md shadow-slate-300 bg-white flex items-center justify-center">
                  <img
                    src={card.logo}
                    alt="MSky Logo"
                    className="max-w-full max-h-full"
                    style={{ visibility: 'hidden' }}
                  />
                </div>
              ) : (
                <img className="mb-[20px]" src={card.logo} alt="" />
              )}
              <Rating>
                {card?.reviews ? (
                  partiallyCompleted &&
                  card?.reviews && <span>{card?.reviews}</span>
                ) : (
                  <>
                    {ratingHeader && card?.ratingCard && (
                      <>
                        {card?.rating && <span>{card?.rating}</span>}
                        <img src={star} alt="" />
                        <img src={ratingIcon} alt="" />
                      </>
                    )}
                  </>
                )}
              </Rating>
            </Header>
            <span>{card.title}</span>
            {reviewpagelisting && (
              <h1>{card.projects ? `${card.projects} Projects` : null}</h1>
            )}
            {!reviewpagelisting && card?.questions && (
              <Questionspan>
                <span
                  className={`${
                    card?.questions === 1
                      ? 'SingleQuestionSpan'
                      : 'MultipleQuestionsSpan'
                  }`}
                >
                  {card?.questions}{' '}
                  {card?.questions === 1 ? 'Parameter' : 'Parameters'}
                </span>
              </Questionspan>
            )}
            <DigitBox
              onClick={() =>
                historyState
                  ? handleCardClick(card?.id, card, buttontitle)
                  : handleCardClick(card?.id, card)
              }
              // style={{
              //   display: 'flex',
              //   alignItems: 'center',
              //   justifyContent: 'space-between',
              // }}
            >
              <DynamicStyledDiv>
                <Buttontitlespan>{buttontitle}</Buttontitlespan>
                <img src={arrow} alt="Arrow" />
              </DynamicStyledDiv>
            </DigitBox>
          </Card>
        ))}
        {!reviewpagelisting && createnewbutton && <CreateNewButton />}
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

function Input(props) {
  const {
    placeHolder,
    inputType,
    value,
    name,
    className = '',
    onChange,
    disabled = '',
  } = props;

  const inputValue = value !== undefined ? value : '';

  return (
    <>
      <input
        placeholder={placeHolder}
        type={inputType}
        name={name}
        value={inputValue}
        className={className}
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default Input;

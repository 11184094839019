import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardTable from 'app/common/components/Table/table';
import Breadcrumb from 'app/common/components/Breadcrumb';
import { useCalendarSlice } from 'app/pages/DashboardCalendar/calendarslice';
import { selectCalendar } from 'app/pages/DashboardCalendar/calendarslice/selectors';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from 'app/pages/Setup/style';

import { TRACK_STATUS } from 'app/common/constants/constants';

function AssessmentListPopup(props) {
  const dispatch = useDispatch();
  const { actions } = useCalendarSlice();
  const timelineReview = useSelector(selectCalendar);
  const breadcrumbItems = [{ text: 'Reviewed Project History' }];
  const { loading } = timelineReview;

  useEffect(() => {
    dispatch(actions.getReviewData({ id: props.data.trackId }));
  }, [dispatch, actions]);

  const data = Object.values(timelineReview.reviews).flatMap(review => {
    return review.assessment.map((assessment, index) => ({
      id: index + 1,
      categoryId: assessment.category._id,
      category: assessment.category.name,
      startDate: assessment.createdAt,
      endDate:
        assessment?.status === 'COMPLETED' && assessment?.endDate
          ? new Date(assessment?.endDate).toLocaleString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : assessment?.status || '-',
      totalDays: assessment.totalDays || 0,
      status: assessment.status,
    }));
  });

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'category',
      text: 'Categories',
    },
    {
      dataField: 'totalDays',
      text: 'Total Days',
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      formatter: (cell, row, rowIndex) =>
        cell
          ? new Date(cell).toLocaleString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : '-',
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      formatter: (cell, row, rowIndex) => {
        const status = row.status || row.endDate;

        if (status === 'COMPLETED' && row.endDate) {
          return new Date(row.endDate).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          });
        } else if (status === 'IN_PROGRESS') {
          return (
            <div
              className={`rounded-md border w-fit py-1.5 px-4 text-center font-sans text-sm font-normal border-[#eec700] text-[#ac9000] bg-[#F8F8F8]`}
            >
              {TRACK_STATUS[status]}
            </div>
          );
        } else {
          return (
            <div>
              <span
                className={`${
                  TRACK_STATUS[status]
                    ? 'rounded-md border w-fit py-1.5 px-4 text-center font-sans text-sm font-normal border-[#F28300] text-[#F28300] bg-[#F8F8F8]'
                    : ''
                }`}
              >
                {TRACK_STATUS[status] || row.endDate || '-'}
              </span>
            </div>
          );
        }
      },
    },
  ];
  return (
    <>
      {loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="">
          <div className="pb-10">
            <Breadcrumb
              heading={`${props?.data?.projectName} - ${props?.data?.trackName} - Reviews`}
              items={breadcrumbItems}
            />
          </div>
          <DashboardTable
            data={data}
            columns={columns}
            classcategory={'tablereviewpage'}
          />
        </div>
      )}
    </>
  );
}

export default AssessmentListPopup;

import styled from 'styled-components/macro';

export const NavLinkContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

  ${({ active }) =>
    active &&
    `
    background-color: #151d48;
    border-radius: 10px;  
`}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: none;
    cursor: not-allowed;
    opacity: 0.5
  `}
`;

export const PageIcon = styled.div`
  padding: 16px 24px;
`;

export const ActiveName = styled.div`
  ${({ active }) =>
    active &&
    `
    color: white;
    font-weight: bold;
    `}
`;

import React from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
// import { useCalendarSlice } from 'app/pages/DashboardPage/calendarslice';
import { selectCalendar } from 'app/pages/DashboardCalendar/calendarslice/selectors';
import './style.css';

function UpcomingEvents({ currentCalendarDate }) {
  // const dispatch = useDispatch();
  // const { actions } = useCalendarSlice();
  const upcomingEvents = useSelector(selectCalendar);
  // const { view, loading } = upcomingEvents;
  const { followUpEvents } = upcomingEvents;

  // useEffect(() => {
  //   dispatch(
  //     actions.getFollowUpTracks({
  //       year: currentCalendarDate?.year,
  //     }),
  //   );
  // }, [actions, currentCalendarDate, dispatch]);

  //organize follow-up events by date
  const organizeEventsByDate = () => {
    const organizedEvents = {};
    const currentDate = moment().startOf('day');
    followUpEvents.forEach(event => {
      event.followUps.forEach(followup => {
        const nextFollowUpDate = moment(followup.nextFollowUp);
        if (nextFollowUpDate.isSameOrAfter(currentDate)) {
          const date = nextFollowUpDate.format('D MMM');
          if (!organizedEvents[date]) {
            organizedEvents[date] = [];
          }
          organizedEvents[date].push(event);
        }
      });
    });
    return organizedEvents;
  };

  //Sort the organized events by date
  const sortedEventsByDate = () => {
    const organizedEvents = organizeEventsByDate();
    return Object.entries(organizedEvents).sort(
      (a, b) => moment(a[0], 'D MMM') - moment(b[0], 'D MMM'),
    );
  };

  return (
    <>
      <div className="upcoming-events-container">
        {/* <div className="upcomingEventsHeading">
          <p>Upcoming Events</p>
        </div> */}
        {followUpEvents.length === 0 ? (
          <p className="text-2xl font-medium text-[#151D48]">
            No Upcoming Events Available
          </p>
        ) : (
          <ul className="upcoming-events-listing">
            {sortedEventsByDate().map(([date, events]) => (
              <li key={date} className="upcoming-event-box">
                <div className="upcoming-event-date">
                  {/* <p>{date}</p> */}
                  <p className="text-[28px] ">
                    {moment(date, 'D MMM').format('D')}
                  </p>
                  <p className="month">{moment(date, 'D MMM').format('MMM')}</p>
                </div>
                <div className="upcoming-event-discription">
                  {events.map((event, index) => (
                    <p className="p-[5px]" key={index}>
                      Follow-up | {event?.project?.projectName} |{' '}
                      {event?.trackName}
                    </p>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default UpcomingEvents;

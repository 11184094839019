import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { userAuthActions as actions } from '.';
import { API_CONSTANTS } from 'app/common/constants/constants';
import { commonActions } from '../errorBoundarySlice/index';

/**
 * user auth request/response handler
 */
export function* getAuthUser() {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.AUTH_USER}`;
  try {
    const user = yield call(request, requestURL);
    yield put(actions.gotUser(user));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.authError('USER NOT FOUND'));
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.authError('RATE LIMIT'));
    // } else {
    //   yield put(actions.authError('RESPONSE ERROR'));
    // }
  }
}
export function* getLogoutUser() {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/${API_CONSTANTS.DESTROY_AUTH}`;
  try {
    yield call(request, requestURL, { method: 'POST' });
    yield put(actions.removeLoader());
    yield put(actions.gotLogoutUser());
    // window.open(
    //   `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.LOGIN_AUTH}`,
    // );
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    // yield put(actions.removeLoader());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userAuthFormSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getUser, getAuthUser);
  yield takeLatest(actions.getLogoutUser, getLogoutUser);
}
